import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    useToast, TableContainer, Flex
} from '@chakra-ui/react'
import QAIncidence from './table';
import QAIncidencesFilters from './filters';
import ModalQAIncidenceNotes from './notes';


function UseQAIncidences() {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');


    const [userSearch, setUserSearch] = useState(null);
    const [qaSearch, setQaSearch] = useState(null);
    const [clientSearch, setClientSearch] = useState(null);
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [statusSearch, setStatusSearch] = useState('');
    const [reasonSearch, setReasonSearch] = useState('');
    const [resetKey, setResetKey] = useState(0);


    const [QAIncidences, setQAIncidences] = useState([]);
    const [userE, setUserE] = useState([]);
    const [client, setClient] = useState([]);
    const [qaAgent, setQaAgent] = useState([]);
    const [usersClients, setUsersClients] = useState([]);

    const [selectedQA, setSelectedQA] = useState(null);


    const [openNotes, setNotes] = useState(false);


    const clearFilters = () => {
        setClientSearch(null);
        setUserSearch(null);
        setQaSearch(null);
        setFilterDate('');
        setFilterDateEnd('');
        setStatusSearch('');
        setReasonSearch(''); 
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const optionsUsers = userE.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const optionsQA = qaAgent.map(qa => ({
        value: qa.id,
        label: `${qa.name} ${qa.last_name}`,
    }));

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/qaIncidence?page=${currentPage}`
                + `${userSearch ? `&user=${encodeURIComponent(userSearch)}` : ''}`
                + `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}`
                + `${qaSearch ? `&qa=${encodeURIComponent(qaSearch)}` : ''}`
                + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
                + `${statusSearch ? `&status=${statusSearch.trim()}` : ''}`
                + `${reasonSearch ? `&reason=${reasonSearch.trim()}` : ''}`
            );
            const responseData = response.data;
            setQAIncidences(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setUserE(responseData.user);
            setQaAgent(responseData.qa);
            setClient(responseData.client);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataClient();
    }, [currentPage, userSearch, clientSearch, qaSearch, filterDate, filterDateEnd, resetKey, statusSearch, reasonSearch]);


    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'qa':
                setQaSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleNotes = async (qa) => {
        setNotes(true);
        setSelectedQA(qa);
    }

    const handleDelete = async (incidence) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this incidence?',
            text: 'The incidence will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/qaIncidence/delete/${incidence.id}`);
                toast({
                    title: " Incidence deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting the Incidence",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };



    return (
        <TableContainer mt={"2%"}  >
            <Flex
                direction='column'
                w='100%'
            >
                <QAIncidencesFilters
                    clientSearch={clientSearch}
                    usersClients={usersClients}
                    optionsUsers={optionsUsers}
                    userSearch={userSearch}
                    handleSelectChange={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    filterOptions={filterOptions}
                    setFilterDateEnd={setFilterDateEnd}
                    optionClients={optionClients}
                    setFilterDate={setFilterDate}
                    resetKey={resetKey}
                    handleClientChange={handleClientChange}
                    filterDate={filterDate}
                    filterDateEnd={filterDateEnd}
                    clearFilters={clearFilters}
                    statusSearch={statusSearch}
                    setStatusSearch={setStatusSearch}
                    reasonSearch={reasonSearch}
                    setReasonSearch={setReasonSearch}
                    optionsQA={optionsQA}
                    qaSearch={qaSearch}

                />
                <QAIncidence
                    total={total}
                    currentPage={currentPage}
                    QAIncidences={QAIncidences}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    handleNotes={handleNotes}
                    handleDelete={handleDelete}
                    setQAIncidences={setQAIncidences}
                />
            </Flex>

            {openNotes && (
                <ModalQAIncidenceNotes
                    isOpen={openNotes}
                    qaIncidence={selectedQA}
                    onClose={() => setNotes(false)}
                    data={fetchData}
                />
            )}
        </TableContainer >

    );
}
export default UseQAIncidences; 