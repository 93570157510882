import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer, Flex
} from '@chakra-ui/react'
import QATotals from './table';
import QATotalsFilters from './filters';

function UseQATotals() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [isLoadingBar, setIsLoadingBar] = useState(false);

    const [yearSearch, setYearSearch] = useState(null);
    const [clientSearch, setClientSearch] = useState(null);
    const [agentSearch, setAgentSearch] = useState(null);
    const [monthSearch, setMonthSearch] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [order, setOrder] = useState('asc');

    const [qaTotals, setQATotals] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [client, setClient] = useState([]);
    const [agent, setAgents] = useState([]);


    const clearFilters = () => {
        setYearSearch(null);
        setClientSearch(null);
        setAgentSearch(null);
        setMonthSearch('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `/qaTotals?page=${currentPage}`,
                {
                    year: yearSearch || new Date().getFullYear(),
                    month: Number(monthSearch),
                    order: order,
                    client: clientSearch,
                    agent: agentSearch,
                }
            );
            const responseData = response.data;
            setQATotals(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
            setWeeks(responseData.weeks);
            setClient(responseData.client);
            setAgents(responseData.agents);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, yearSearch, monthSearch, order, clientSearch, agentSearch]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectYear = (componentName, selectedOption) => {
        switch (componentName) {
            case 'year':
                setYearSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            case 'agent':
                setAgentSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleDownloadExcel = async () => {
        try {
            // Construir los parámetros de la URL dinámicamente
            const params = {
                year: yearSearch || new Date().getFullYear(),
                month: Number(monthSearch),
                order: order,
                client: clientSearch,
                agent: agentSearch,
            };

            const url = `/qaTotals/export`;

            setIsLoadingBar(true);

            // Realizar la petición utilizando async/await
            const response = await axios.post(url, params, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `QA_totals.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl); // Liberar el objeto URL
            setIsLoadingBar(false);
        } catch (error) {
            console.error('Error al descargar el archivo Excel:', error);
        } finally {
            setIsLoadingBar(false);
        }
    };

    const toggleOrder = () => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const optionsUsers = agent.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };


    return (
        <TableContainer mt={"2%"}  >
            <Flex
                direction='column'
                w='100%'
            >
                <QATotalsFilters
                    setCurrentPage={setCurrentPage}
                    clearFilters={clearFilters}
                    setMonthSearch={setMonthSearch}
                    monthSearch={monthSearch}
                    yearSearch={yearSearch}
                    resetKey={resetKey}
                    customStyles={customStyles}
                    handleSelectYear={handleSelectYear}
                    handleDownloadExcel={handleDownloadExcel}
                    isLoadingBar={isLoadingBar}
                    toggleOrder={toggleOrder}
                    order={order}
                    optionClients={optionClients}
                    clientSearch={clientSearch}
                    optionsUsers={optionsUsers}
                    filterOptions={filterOptions}
                    agentSearch={agentSearch}
                />
                <QATotals
                    total={total}
                    currentPage={currentPage}
                    QATotals={qaTotals}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    Weeks={weeks}
                />
            </Flex>
        </TableContainer >

    );
}
export default UseQATotals; 