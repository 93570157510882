import React, { useState } from 'react';
import { Box, Button, Flex, Text, Heading, Thead, Tr, Th, Td } from '@chakra-ui/react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

const FormatDefault = ({ formats }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Estado para rastrear el índice actual

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : formats.length - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < formats.length - 1 ? prevIndex + 1 : 0));
    };

    if (!formats || formats.length === 0) {
        return (
            <Text color="#1c2147" fontSize="25px" textAlign="center" padding="15px" bg="#f2f2f2">
                No data
            </Text>
        );
    }

    const format = formats[currentIndex]; // Obtenemos el elemento actual del array

    return (
        <Flex direction="column" align="center" p={4}>
            <Flex justifyContent='center' alignItems="center" mb={4} w={'20%'}>
                <Button
                    onClick={goToPrevious}
                    borderRadius={'0px'}
                    bg="#808080" color={"white"}
                    _hover={{ background: "#808080", }}
                    _active={{ background: "#808080", }}
                    mr={'12%'}
                    isDisabled={currentIndex === 0}
                >
                    <BsArrowLeftShort fontSize="20px" />
                </Button>
                <Text display="inline" fontWeight="bold" mr={'11%'}>
                    {`${currentIndex + 1} / ${formats.length}`}
                </Text>
                <Button
                    onClick={goToNext}
                    borderRadius={'0px'}
                    bg="#808080" color={"white"}
                    _hover={{ background: "#808080", }}
                    _active={{ background: "#808080", }}
                    isDisabled={currentIndex === formats.length - 1}
                >
                    <BsArrowRightShort fontSize="20px" />
                </Button>
            </Flex>

            <Flex direction="column" border="1px solid #ccc" p={4} borderRadius="8px" width="100%">

                <Flex display={'flex'} justifyContent={'center'} fontSize={'20px'}>
                    <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>
                        {format.call_date}
                    </span>
                </Flex>

                <Flex justifyContent={'space-between'}>
                    <Flex justifyContent={'end'}>
                        <Text fontWeight="bold" mr={2} fontSize={'18px'}>
                            Duration:
                        </Text>
                        <Text fontSize={'18px'} color={'#808080'}>
                            {format.call_duration}
                        </Text>
                    </Flex>
                    <Flex justifyContent={'end'}>
                        <Text fontWeight="bold" mr={2} fontSize={'18px'}>
                            Account:
                        </Text>
                        <Text fontSize={'18px'} color={'#808080'}>
                            {format.call_account || 'N/A'}
                        </Text>
                    </Flex>
                </Flex>

                <Flex justifyContent={'space-between'} mt={'5%'}>
                    <Flex justifyContent={'end'}>
                        <Text fontWeight="bold" mr={2} fontSize={'18px'}>
                            Pts Possible:
                        </Text>
                        <Text fontSize={'18px'} color={'#808080'}>
                            {format.possible_score}
                        </Text>
                    </Flex>
                    <Flex justifyContent={'end'}>
                        <Text fontWeight="bold" mr={2} fontSize={'18px'}>
                            Pts Earned:
                        </Text>
                        <Text fontSize={'18px'} color={'#808080'}>
                            {format.final_score}
                        </Text>
                    </Flex>

                    <Flex justifyContent={'end'}>
                        <Text fontWeight="bold" mr={2} fontSize={'18px'}>
                            Percent:
                        </Text>
                        <Text fontSize={'18px'} color={'#808080'}>
                            {format.possible_score && format.final_score
                                ? `${((Number(format.final_score) * 100) / Number(format.possible_score)).toFixed(2)}%`
                                : 'N/A'}
                        </Text>
                    </Flex>
                </Flex>


                <Box mt={4}>
                    <Heading as="h2" size="md" mb={4}>
                        Quality
                    </Heading>
                    <Thead bg="white">
                        <Tr>
                            <Th color="#808080" textAlign="center" width="100%">Question</Th>
                            <Th textAlign="center"></Th>
                            <Th textAlign="center"></Th>
                        </Tr>
                    </Thead>
                    {format.questions
                        .filter((item) => item.type === 1)
                        .map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.question_text}</Td>
                                <Td>{item.status}</Td>
                                <Td color={item.status === 'N' ? '#de5b5b' : '#b9ce67'} fontWeight={'bold'} >
                                    {item.score}
                                </Td>
                            </Tr>
                        ))}
                </Box>

                <Box mt={4}>
                    <Heading as="h2" size="md" mb={4}>
                        Mannerisms
                    </Heading>
                    <Thead bg="white">
                        <Tr>
                            <Th color="#808080" textAlign="center" width="100%">Question</Th>
                            <Th textAlign="center"></Th>
                            <Th textAlign="center"></Th>
                        </Tr>
                    </Thead>
                    {format.questions
                        .filter((item) => item.type === 2)
                        .map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.question_text}</Td>
                                <Td>{item.status}</Td>
                                <Td color={item.status === 'N' ? '#de5b5b' : '#b9ce67'} fontWeight={'bold'} >
                                    {item.score}
                                </Td>
                            </Tr>
                        ))}
                </Box>

                <Flex mt={'5%'}>
                    <Text fontSize={'18px'} mr={'2%'}>
                        Comments:
                    </Text>
                    <Text fontSize={'16px'} color={'#808080'} mt={'0.1%'}>
                        {format.call_comment}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default FormatDefault;
