
import React from "react";
import {
    Flex, Menu, MenuButton, MenuList, Text, Button,
} from '@chakra-ui/react';

//Icons & Buttons
import { IoTicketOutline } from "react-icons/io5";
import CustomMenuItem from "../searchBar/CustomMenuItem";

export default function TicketsButtons(props) {

    const { startIncident, userAuth, openFinancialRequest, openQAIncidence } = props;

    return (
        <>
            <Menu>
                <MenuButton

                    justifyContent="space-between"
                    alignItems="center"
                    as={Button}
                    bg={'#fabb1c'}
                    _hover={{
                        background: "#808080",
                        color: "white"
                    }}
                    _active={{
                        background: "#808080",
                        color: "white"
                    }}
                    color='#1c2147'
                    mr={3}
                    borderRadius={'10px'}
                >
                    <Flex flexDirection={"row"}>
                        <IoTicketOutline size={"28px"} />
                        <Text width={"max-content"} ml={2} textAlign={"center"} pt={"5px"}  >Make a ticket</Text>
                    </Flex>
                </MenuButton>


                <MenuList ml={6} mt={4}>
                    <CustomMenuItem
                        textColor="#1c2147"
                        buttonColor="#f2f2f2"
                        spanColor="#ff6f94"
                        onClick={() => startIncident(1)}
                        label="IT"
                    />
                    <CustomMenuItem
                        textColor="#1c2147"
                        buttonColor="#f2f2f2"
                        spanColor="#fabb1c"
                        onClick={() => startIncident(2)}
                        label="HR"
                    />
                    {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#633cbd"
                            onClick={() => startIncident(3)}
                            label="Hiring"
                        />
                    ) : null}
                    <CustomMenuItem
                        textColor="#1c2147"
                        buttonColor="#f2f2f2"
                        spanColor="#fb9539"
                        onClick={() => startIncident(4)}
                        label="OnCall"
                    />
                    {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#a2bd34"
                            onClick={() => startIncident(5)}
                            label="Schedules"
                        />
                    ) : null}
                    <CustomMenuItem
                        textColor="#1c2147"
                        buttonColor="#f2f2f2"
                        spanColor="#6bc2ed"
                        onClick={() => startIncident(6)}
                        label="Finances"
                    />

                    <CustomMenuItem
                        textColor="#1c2147"
                        buttonColor="#f2f2f2"
                        spanColor="#7080fc"
                        onClick={() => startIncident(7)}
                        label="Calls"
                    />
                    {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#fc7466"
                            onClick={() => startIncident(8)}
                            label="Supervisor"
                        />
                    ) : null}
                    {[1, 2, 13].includes(userAuth.type_user_id) ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#424ea8"
                            onClick={openFinancialRequest}
                            label="IFR"
                        />
                    ) : null}
                    {[1, 26, 37].includes(userAuth.type_user_id) ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#ff6f94"
                            onClick={() => startIncident(9)}
                            label="QA Clarification"
                        />
                    ) : null}
                    {[1, 26, 27, 28, 29, 37].includes(userAuth.type_user_id) || [769, 756].includes(userAuth.id) ? (
                        <CustomMenuItem
                            textColor="#1c2147"
                            buttonColor="#f2f2f2"
                            spanColor="#633cbd"
                            onClick={openQAIncidence}
                            label="QA Incidence"
                        />
                    ) : null}
                </MenuList>
            </Menu>
        </>
    );
}
