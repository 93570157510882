import {
  Icon,
  Box, Flex, VStack, Image,
  Button, Text,
  Input, InputGroup, Menu, MenuButton, MenuList, MenuItem, FormErrorMessage, FormControl, Link, Textarea, Avatar, HStack
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Pusher from 'pusher-js';
import { IoPaperPlane, IoDocument } from "react-icons/io5";
import WWWIII from "assets/img/dashboards/Mundo_chat.png";
import logo2 from "assets/img/dashboards/logo2.png"
import { SiAnydesk } from "react-icons/si";
import { MdOutlineSupportAgent, MdKeyboardArrowDown } from "react-icons/md";
import { FaRegImage } from "react-icons/fa6";
import { ImAttachment } from "react-icons/im";
import { BsWhatsapp } from "react-icons/bs";
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import { IoMdDownload, IoIosInformationCircle } from "react-icons/io";
import ImageViewer from "react-simple-image-viewer";
import logo from "assets/img/dashboards/Logo3.png";
import { BsCheck2, BsCheck2All } from "react-icons/bs";

export default function Messages({ chatRoomId, onClose, openModal, transferModal, addReason, UpdateAnydesk, getWhatsAppLink, handleSweetAlert, handleEndToTransfer, handleOpenInfoMessage }) {

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [canFetchMessages, setCanFetchMessages] = useState(true);
  const [canProcessPusherNotifications, setCanProcessPusherNotifications] = useState(true);
  const [joiningRoomId, setJoiningRoomId] = useState(null);
  const [user, setUser] = useState("");
  const fileInputRef = useRef(null);
  const [hoveredMessage, setHoveredMessage] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [initialScrollDone, setInitialScrollDone] = useState(false);

  const fetchData = async () => {
    try {
      const response = axios.get(`/user`).then((response) => {
        const user = response.data.user;
        setUser(user);
      });
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const removeImage = () => {
    setImageFile(null)
    setFormErrors({});
  };
  const removeFile = () => {
    setDocumentFile(null)
    setFormErrors({});
  };


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const pusher = new Pusher("20dade1a513097de43eb", {
        cluster: "mt1",
        encrypted: true,
        //authEndpoint: "/pusher/auth",
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
      const chatChannel = pusher.subscribe("pusher");
      chatChannel.bind("new-message", (data) => {
        if (canProcessPusherNotifications) {
          setMessages((prevMessages) => [...prevMessages, data.message]);
          setCanProcessPusherNotifications(false);
          setTimeout(() => {
            setCanProcessPusherNotifications(true);
          }, 5000);
        }
      });

      const fetchMessages = () => {
        axios
          .get(`/chat/messages/${chatRoomId.id}`)
          .then((response) => {
            setMessages(response.data.messages);
            setTimeout(() => {
              setCanFetchMessages(true);
            }, 5000);
          })
          .catch((error) => {
            console.error(error);
            setTimeout(() => {
              setCanFetchMessages(true);
            }, 10000);
          });
      };


      if (canFetchMessages) {
        fetchMessages();
        setCanFetchMessages(false);
      }
      return () => {
        chatChannel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, [canFetchMessages, canProcessPusherNotifications]);


  const messagesContainerRef = useRef();

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  };

  useEffect(() => {
    if (!initialScrollDone && messages.length > 0 && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      setInitialScrollDone(true);
    }
  }, [messages, initialScrollDone]);

  const sendMessage = () => {
    const formData = new FormData();
    formData.append('message', newMessage);
    formData.append('chatRomId', chatRoomId.chat_room_id);
    formData.append('incident_id', chatRoomId.id);

    // Agrega el archivo de imagen si existe
    if (imageFile) {
      formData.append('image', imageFile?.file || ''); // Asegúrate de que imageFile esté definido
    }

    // Agrega el archivo de documento si existe
    if (documentFile) {
      formData.append('document', documentFile?.file || ''); // Asegúrate de que documentFile esté definido
    }

    axios.post('/chat/messages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        const newMessageData = response.data.message;
        const user = response.data.user;
        setMessages([...messages, { ...newMessageData, user }]);
        setNewMessage('');
        // Elimina los archivos después de enviar el mensaje
        setImageFile(null);
        setDocumentFile(null);
        setFormErrors({});
        setTimeout(() => {
          scrollToBottom();
        }, 0);
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data && error.response.data.error === 'At least one of the fields (message, image, document) must be present.') {
        } else {
          setFormErrors(error.response.data.errors);
        }
      });
  };



  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        setNewMessage(newMessage + '\n');
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const handleLeaveClick = async () => {
    if (chatRoomId !== null) {
      setJoiningRoomId(chatRoomId);

      try {
        await axios.post(`/chat/rooms/${chatRoomId}/leave`);
        onClose();
      } catch (error) {
        console.error('Error al unirse a la sala de chat:', error);
      } finally {
        setJoiningRoomId(null);
      }
    }
  };

  const handleImageChange = (e, imageFile) => {
    const file = e?.target?.files?.[0] || imageFile?.file;

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageFile({
        file,
        previewUrl: imageUrl,
      });
    }
  };

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setDocumentFile({
        file,
        name: file.name,
      });
    }
  };

  const downloadFile = async (message) => {
    try {
      const response = await axios.get(`/documentD/${encodeURIComponent(message.document_image)}`, { responseType: 'blob' });

      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
      const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.document_image;

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', suggestedFileName);
      document.body.appendChild(link);
      link.click();

      // Liberar recursos
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  const downloadImage = async (message) => {
    try {
      const response = await axios.get(`/imagesD/${encodeURIComponent(message.image_path)}`, { responseType: 'blob' });

      const contentDisposition = response.headers['content-disposition'];
      const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
      const suggestedFileName = fileNameMatch ? fileNameMatch[1] : message.image_path;

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', suggestedFileName);
      document.body.appendChild(link);
      link.click();

      // Liberar recursos
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();

        // Simular un evento de cambio en el input de tipo archivo
        const changeEvent = new Event('change', { bubbles: true });
        Object.defineProperty(changeEvent, 'target', { value: { files: [file] } });

        // Llamar a la función handleImageChange con la imagen pegada
        handleImageChange(changeEvent, { file, previewUrl: URL.createObjectURL(file) });
        break;  // Solo procesamos la primera imagen encontrada
      }
    }
  };

  const textareaRef = useRef(null);

  const autoResize = (e) => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };


  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMimeType, setSelectedMimeType] = useState(null);

  const openImageViewer = (imageSrc, mimeType) => {
    setSelectedImage(imageSrc);
    setSelectedMimeType(mimeType);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setSelectedImage(null);
    setSelectedMimeType(null);
  };

  const renderUserImage = (message) => {
    const imageSrc = message.base64Image && message.imageMimeType
      ? `data:${message.imageMimeType};base64,${message.base64Image}`
      : logo;

    return (
      <Image
        src={imageSrc}
        h="160px"
        w="260px"
        mb={'2%'}
        onClick={() => openImageViewer(imageSrc, message.imageMimeType)}
        cursor={"pointer"}
      />
    );
  };

  const downloadImageViewer = () => {
    const extension = selectedMimeType.split('/')[1];
    const link = document.createElement('a');
    link.href = selectedImage;
    link.download = `image.${extension}`;
    link.click();
  };



  return (
    <Box height={"max-content"}>
      <Flex className="flextickenca" >
        <Flex className="flexTitleButtoms">
          <Flex className="flexInfo">
            <Flex maxWidth={'100%'} maxHeight={'90%'}>
              <Text className=" txtmsjname">
                {chatRoomId.first_message}
              </Text>
            </Flex>
            <Flex >
              <Text className="textDate">
                {chatRoomId.hour} {chatRoomId.date}
              </Text>
            </Flex>
            <Flex direction={'column'}>
              <Text className="textAgent">
                Ticket by:  {chatRoomId.operator.split(' ')[0]} {chatRoomId.operatorL.split(' ')[0]}
              </Text>
              {chatRoomId.chat_room_id === 6 ? (
                <Text className="textAgent">
                  {chatRoomId.chat_room_id === 6 ? `ID: ${chatRoomId.mat_dp}` : ''}
                </Text>
              ) : null}
              <Text className="textSupervisor">
                Claimed by:
                {chatRoomId.id_supervisor > 0
                  ? ` ${chatRoomId.supervisor.split(' ')[0]} ${chatRoomId.supervisorL.split(' ')[0]}`
                  : `---`
                }
              </Text>
            </Flex>
            <Flex>


            </Flex>
            <Flex direction={'row'} width={'100%'} mb={'2%'}>
              {user.type_user_id !== 22 && (
                <Button width={"40%"} bg={'#f4bb1c'} color='#1c2147' _hover={{ background: "#808080", }}
                  size="sm" onClick={() => transferModal(chatRoomId)} borderRadius={"5px"} fontSize={'12px'}
                  mr={'2%'}
                >
                  Transfer
                </Button>
              )}

              {user.type_user_id !== 22 && (
                <Button bg={'#1c2147'} color='white' width={"40%"} _hover={{ background: "#808080", }}
                  size="sm" onClick={() => openModal(chatRoomId)} borderRadius={"5px"} fontSize={'12px'} mr={'2%'} >
                  End Incident
                </Button>
              )}

              {chatRoomId.chat_room_id === 1 ? (
                <Button bg={'#1c2147'} color='white' width={"100%"} _hover={{ background: "#808080", }}
                  size="sm" onClick={() => handleEndToTransfer(chatRoomId)} borderRadius={"5px"} fontSize={'65%'} mr={'2%'} >
                  Close ticket, transfer & verification
                </Button>


              ) : null}

              {chatRoomId.chat_room_id === 1 ? (
                chatRoomId.anydesk === null ? (
                  <Button
                    width={"50%"}
                    bg={'#424ea8'}
                    color='white'
                    _hover={{ background: "#808080" }}
                    size="sm"
                    borderRadius={"5px"}
                    onClick={() => UpdateAnydesk(chatRoomId.id_user)}
                    mr={'2%'}
                  >
                    <Flex flexDirection={"row"} mx={1} fontSize={'12px'}>
                      <Box mr={1}><SiAnydesk size={"18px"} /></Box>
                      Add Anydesk
                    </Flex>
                  </Button>
                ) : (
                  <Button
                    width={"40%"}
                    bg={'#424ea8'}
                    color='white'
                    _hover={{ background: "#808080" }}
                    size="sm"
                    mr={'2%'}
                    borderRadius={"5px"}
                    onClick={() => handleSweetAlert(chatRoomId)}
                  >
                    <Flex flexDirection={"row"} mx={1} fontSize={'12px'}>
                      <Box><SiAnydesk size={"15px"} /></Box>
                      Anydesk
                    </Flex>
                  </Button>
                )
              ) : null}

              {chatRoomId.chat_room_id === 1 && chatRoomId.id_setting === 0 ? (
                <Button width={"40%"} bg={'#424ea8'} color='white' _hover={{ background: "#808080", }}
                  size="sm" onClick={() => addReason(chatRoomId)} borderRadius={"5px"} fontSize={'12px'} mr={'2%'}>
                  Add reason
                </Button>
              ) : null}

              {chatRoomId.chat_room_id === 1 ? (
                <Button
                  width={"10%"}
                  bg={'#8aad01'}
                  color='white'
                  _hover={{
                    background: "#b9ce67",
                  }}
                  size="sm"

                  borderRadius={"5px"}
                >
                  <Link
                    textAlign={'left'}
                    color="blue.500"
                    href={getWhatsAppLink(chatRoomId.phone, chatRoomId.location_id)}
                    isExternal
                  >
                    <Box
                      width={8}
                      height={8}
                      borderRadius={"5px"}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <BsWhatsapp color="white" size='18px' />
                    </Box>
                  </Link>

                </Button>


              ) : null}
            </Flex>
          </Flex>
          <Flex className="ticketsixfilters">

            <Flex mt={'1%'} mr={'2%'}>
              <Button bg={'#b3b3b3'} color='white' size="sm" _hover={{ background: "#b3b3b3" }}
                onClick={handleLeaveClick} borderRadius={"5px"} title='Minimize chat'>
                —
              </Button>
            </Flex>

            <Flex className="flexButtons1">


            </Flex>
          </Flex>
        </Flex>
      </Flex>


      {chatRoomId.reason_transfer !== null ? (
        <VStack className="stacktransfer" >
          <Flex mt={'5px'} width={"100%"}   >
            <Text color={'#424ea8'} fontWeight={"bold"} fontSize={'sm'} ml={6} textAlign={'left'}>
              {chatRoomId.userTransfer && chatRoomId.userTransfer ? chatRoomId.userTransfer : "---"} {chatRoomId.userTransferL && chatRoomId.userTransferL ? chatRoomId.userTransferL : "---"}.
            </Text>
          </Flex>
          <Box
            className="reasontransf"
          >
            <Box borderRadius={"15px"} bg={"#f2f2f2"} p={"12px"} ml={4} mt={2} width={"60px"} height={"60px"} boxShadow={"0px 0px 8px 3px #cccccc"} >
              <MdOutlineSupportAgent size={"38px"} />
            </Box>
            <Text
              className="txtreas"
            >
              Reason transfer: {chatRoomId && chatRoomId.reason_transfer ? chatRoomId.reason_transfer : "---"}
            </Text>
          </Box>
        </VStack>
      ) : null}



      <Box
        h='calc(100% - 120px)' height={"max-content"}
        px={{ base: "10px", md: "55px" }}
        position='static'

      >
        <Flex ref={messagesContainerRef} className="chattick" style={{ backgroundImage: `url(${WWWIII})`, backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }}>

          {messages.map((message, index) => (

            <Flex
              direction='column'
              key={message.id}
              w='100%'
              maxW={{ base: "90%", lg: "calc(100% - 80px)" }}
              boxSizing='border-box'
            >
              <VStack borderRadius={"8px"} mt={2} mb={2} spacing={3} align={user.id != message.user_id ? 'start' : 'end'}>
                <Flex direction={'row'}>
                  <Box borderRadius={"15px"} bg={" #edeffc"} p={"12px"} mr={4} mt={2} width={"60px"} height={"60px"} display={user.id != message.user_id ? 'block' : 'none'}>
                    <Image src={logo2} />
                  </Box>
                  <Flex
                    borderRadius={user.id !== message.user_id ? '20px 20px 20px 0' : '20px 20px 0 20px'}
                    height={"max-content"}
                    minWidth={"225px"}
                    flexDirection={"column"}
                    bg={user.id != message.user_id ? ' #edeffc' : '#f2f2f2'}
                    cursor="pointer"
                    _hover={{ ".hoverText": { display: "block" } }}
                  >

                    <Box position={"relative"}>
                      <Menu>
                        <MenuButton
                          size={'xs'}
                          className="hoverText"
                          as={Button}
                          display="none"
                          position="absolute"
                          right={2}
                          top={2}
                          bg="rgba(0, 0, 0, 0.7)"
                          color="white"
                          borderRadius="4px"
                          _active={{ background: "rgba(0, 0, 0, 0.7)", color: 'white' }}
                          _hover={{ background: "rgba(0, 0, 0, 0.7)", color: 'white' }}
                        >
                          <MdKeyboardArrowDown />
                        </MenuButton>
                        <MenuList>
                          <MenuItem isDisabled>
                            <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                          </MenuItem>
                          <MenuItem
                            display={"flex"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                            alignItems="center">
                            <Button
                              borderRadius={"5px"}
                              bg={"#f2f2f2"}
                              color={"#1c2147"}
                              _hover={{ background: "#7080fc", color: 'white' }}
                              onClick={() => handleOpenInfoMessage(message)}
                              width={"100%"}
                              justifyContent="space-between"
                              alignItems="center">
                              <Text flex={1} textAlign="center">
                                Info
                              </Text>
                              <IoIosInformationCircle />
                            </Button>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                    <Flex justifyContent={'left'} mt={'10px'}    >
                      <Text className=" tickchatname ">
                        {message.user_id === 1 ? "Dedicated Portal System" : `${message.users?.name} ${message.users?.last_name}`}
                      </Text>

                    </Flex>
                    <Box
                      px={2}
                      borderRadius='lg'
                      maxWidth={'550px'}
                      minWidth={'120px'}
                      width={'auto'}
                    >
                      {message.message && (
                        <Text
                          textAlign={'justify'}
                          mx={"10px"}
                          color={message.message.startsWith('http://') || message.message.startsWith('https://') ? '#007BFF' : '#1c2147'}
                          fontWeight='bold'
                          fontSize={'15px'}
                          textDecoration={message.message.startsWith('http://') || message.message.startsWith('https://') ? 'underline' : 'none'}
                        >
                          {message.message.startsWith('http://') || message.message.startsWith('https://') ? (
                            <a href={message.message} target="_blank" rel="noopener noreferrer">
                              {message.message}
                            </a>
                          ) : (
                            typeof message.message === 'string' && message.message.split('\n').map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))
                          )}
                        </Text>
                      )}
                      <Flex display={'flex'} justifyContent={'center'}>
                        {message.document_image && (
                          <Button
                            onClick={() => downloadFile(message)}
                            borderRadius={'5px'}
                            size={'lg'}
                            width={'80px'}
                            height={'80px'}
                            mt={'5%'} mb={'5%'}
                            mr={'20px'}
                            onMouseEnter={() => setHoveredMessage(message.id)}  // Al pasar el cursor, establece el mensaje actual
                            onMouseLeave={() => setHoveredMessage(null)}
                            _hover={{ background: "#cccccc" }}
                            bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                          >
                            {hoveredMessage === message.id ? (
                              <DownloadIcon fontSize={'35px'} color="white" />
                            ) : (
                              <IoDocument ml={2} size={'50px'} color="white" />
                            )}
                          </Button>
                        )}
                        {message.image_path && (
                          <>
                            {renderUserImage(message, index)}
                          </>
                        )}
                      </Flex>

                      <HStack
                        mb={2}
                        spacing={2}
                      >
                        <Text textAlign={'right'} width={'100%'} fontWeight={"bold"} color={"#424ea8"} fontSize={'small'} direction={'columm'}>
                          {message.created_at}

                        </Text>
                        {message.message_count ? (
                          <>
                            <Text fontWeight={'bold'} fontSize={'20px'} mb={2} color="#7080fc">
                              <BsCheck2All />
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text fontWeight={'bold'} fontSize={'20px'} mb={2} color="#7080fc">
                              <BsCheck2 />
                            </Text>
                          </>
                        )}
                      </HStack>
                    </Box>

                  </Flex>
                  <Box borderRadius={"15px"} bg={"#f2f2f2"} p={"12px"} ml={4} mt={2} width={"60px"} height={"60px"} display={user.id != message.user_id ? 'none' : 'block'}>
                    <MdOutlineSupportAgent size={"38px"} />
                  </Box>
                </Flex>
              </VStack>

            </Flex>
          ))}
        </Flex>
        {isViewerOpen && selectedImage && (
          <Flex style={{ height: '100%' }}>
            <ImageViewer
              src={[selectedImage]}
              currentIndex={0}
              onClose={closeImageViewer}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)"
              }}
              closeOnClickOutside={true}
            />
            <Button
              onClick={downloadImageViewer}
              style={{
                position: 'absolute',
                top: '50%',
                right: '1.5%',
                transform: 'translateY(-50%)',
                padding: '5px 10px',
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                zIndex: 1001
              }}
            >
              <IoMdDownload size={'40px'} />
            </Button>
          </Flex>
        )}
      </Box>


      <Flex borderRadius={"0px 0px 10px 10px"}
        bg={'transparent'}
        backdropFilter='blur(20px)'

        height={'75px'}
      >
        <InputGroup w={{ base: "90%" }} ml={'15px'} mt={'15px'}>
          <Menu>
            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="white" color={"#b3b3b3"} _hover={{ background: "#808080", color: 'white' }} mr={'20px'} mt={'3px'}>
              <ImAttachment pb={"2px"} size={'25px'} />
            </MenuButton>
            <MenuList>
              <MenuItem
                display={"flex"}
                justifyContent={"center"}
              >
                <Flex justifyContent={'space-evenly'} direction={'row'} display={'flex'} width={'100%'}>
                  <Input type="file" id="image" style={{ display: 'none' }} onChange={(e) => handleImageChange(e, null)} // Usar null si la imagen no proviene de un pegado
                    accept="image/*" ref={fileInputRef} />
                  <Button as="label" htmlFor="image" cursor="pointer" bg={'linear-gradient(180deg, rgba(251, 149, 57, 1.0) 50%, rgba(252, 160, 102, 1.0) 50%)'} color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'50%'} height={'60px'} width={"60px"}>
                    <FaRegImage ml={2} size={'25px'} />
                  </Button>
                  <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, .xlsx, .csv" />
                  <Button as="label" htmlFor="document" cursor="pointer" bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'} color={"white"} _hover={{ background: "#808080", color: 'white' }} borderRadius={'50%'} height={'60px'} width={"60px"}>
                    <IoDocument ml={2} size={'35px'} />
                  </Button>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
          <Textarea
            ref={textareaRef}
            style={{
              width: '100%',
              fontSize: '16px',
              paddingLeft: '40px',
              height: '30px',
              backgroundColor: '#f2f2f2',
              color: '#1c2147',
              fontWeight: '500',
              borderRadius: '10px',
              border: '1px solid #ccc',
              resize: 'none',
              overflow: 'hidden',
              boxSizing: 'border-box',
              ...(window.innerWidth >= 992 && {
                paddingLeft: '65px',
                height: '45px'
              })
            }}
            placeholder="Type a message"
            value={newMessage}
            onChange={e => {
              setNewMessage(e.target.value);
              autoResize(e);
            }}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            rows={1}
          />
        </InputGroup>
        <Button
          borderRadius='50%'
          bg={'#1c2147'}
          w={{ base: "50px", lg: "50px" }}
          h={{ base: "50px", lg: "50px" }}
          mt={'10px'}
          ml={'15px'}
          mr={'20px'}
          variant='no-hover'
          onClick={sendMessage}
        >
          <Icon
            as={IoPaperPlane}
            color='white'
            w={{ base: "18px", lg: "25px" }}
            h={{ base: "18px", lg: "25px" }}
          />
        </Button>
      </Flex>
      <Flex direction={'column'} pl={'15%'}>
        <Flex mb={'2%'} justifyContent={'center'} display={'flex'} textAlign={'center'} px={'18%'}>
          <FormControl isInvalid={!!formErrors.image}>
            {formErrors.image && (
              <FormErrorMessage>The image must be a file of type: jpeg, png, jpg.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!formErrors.document}>
            {formErrors.document && (
              <FormErrorMessage>The document must to be a .pdf, .xlsx, .docx, csv.</FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Flex display={'flex'} justifyContent={'center'}>


          {imageFile && (
            <Flex direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'} position={"relative"} width={'15%'}>
              <img src={imageFile.previewUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
              <Text>
                {imageFile.file.name.length > 20
                  ? `${imageFile.file.name.substring(0, 20)}…`
                  : imageFile.file.name
                }
              </Text>
              <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeImage} _hover={{ background: "transparent" }} position={"absolute"} top={-2} right={-4}><CloseIcon boxSize={"15px"} /></Button>
            </Flex>
          )}
          {documentFile && (
            <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'} ml={'10%'} position={"relative"}>
              <IoDocument ml={2} size={'45px'} color="#cccccc" />
              <Text>
                {documentFile.name.length > 20
                  ? `${documentFile.name.substring(0, 20)}…`
                  : documentFile.name
                }
              </Text>
              <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
            </Flex>
          )}
        </Flex>
      </Flex>


    </Box>

  );
}