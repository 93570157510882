import React from 'react';
import {
    TableContainer, Table, Thead, Tbody, Tr, Th, Td,
    Text, Flex, FormControl,
    FormErrorMessage, Input
} from '@chakra-ui/react'

function TableBills({ formErrors, formData, financeBox, formatCurrency, handleChange }) {

    return (
        <TableContainer w={'70%'}>
            <Table size={'sm'}>
                <Thead bg={"white"} >
                    <Tr>
                        <Th color='#808080' textAlign={'center'}>Denominations</Th>
                        <Th color='#808080' textAlign={'center'}>New</Th>
                        <Th color='#808080' textAlign={'center'}>Current</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>


                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $20:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_20} >
                                    <Input
                                        type="number"
                                        name='ticket_20'
                                        background="white"
                                        value={formData.ticket_20}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_20 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_20 || 0) * 20}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_20 ? formatCurrency(financeBox.total_ticket_20, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $50:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_50} >
                                    <Input
                                        type="number"
                                        name='ticket_50'
                                        background="white"
                                        value={formData.ticket_50}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_50 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_50 || 0) * 50}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_50 ? formatCurrency(financeBox.total_ticket_50, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $100:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_100} >
                                    <Input
                                        type="number"
                                        name='ticket_100'
                                        background="white"
                                        value={formData.ticket_100}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_100 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_100 || 0) * 100}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_100 ? formatCurrency(financeBox.total_ticket_100, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $200:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_200} >
                                    <Input
                                        type="number"
                                        name='ticket_200'
                                        background="white"
                                        value={formData.ticket_200}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_200 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_200 || 0) * 200}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_200 ? formatCurrency(financeBox.total_ticket_200, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $500:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_500} >
                                    <Input
                                        type="number"
                                        name='ticket_500'
                                        background="white"
                                        value={formData.ticket_500}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_500 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_500 || 0) * 500}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_500 ? formatCurrency(financeBox.total_ticket_500, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $1000:
                                </Text>
                                <FormControl isInvalid={!!formErrors.ticket_1000} >
                                    <Input
                                        type="number"
                                        name='ticket_1000'
                                        background="white"
                                        value={formData.ticket_1000}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.ticket_1000 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.ticket_1000 || 0) * 1000}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_ticket_1000 ? formatCurrency(financeBox.total_ticket_1000, 'MXN') : '---'}
                        </Td>
                    </Tr>



                </Tbody>
            </Table>
        </TableContainer>

    );
}
export default TableBills; 