
import React from "react";
import {
    Flex, Menu, MenuButton, MenuList, Text, Button,
} from '@chakra-ui/react';

//Icons & Buttons
import { IoTicketOutline } from "react-icons/io5";
import CustomMenuItem from "../searchBar/CustomMenuItem";

export default function TicketsMGButtons(props) {

    const { startIncident, userAuth, openFinancialRequest, openQAIncidence } = props;

    return (
        <>
            <Menu>
                <MenuButton

                    justifyContent="space-between"
                    alignItems="center"
                    as={Button}
                    bg={'#fabb1c'}
                    _hover={{
                        background: "#808080",
                        color: "white"
                    }}
                    _active={{
                        background: "#808080",
                        color: "white"
                    }}
                    color='#1c2147'
                    mr={3}
                    borderRadius={'10px'}
                >
                    <Flex flexDirection={"row"}>
                        <IoTicketOutline size={"28px"} />
                        <Text width={"max-content"} ml={2} textAlign={"center"} pt={"5px"}  >Make a ticket</Text>
                    </Flex>
                </MenuButton>


                <MenuList w={'125%'} >

                    {/* Submenu1 */}
                    <Menu>
                        <MenuButton
                            as={Button}
                            bg={'#f2f2f2'}
                            color="#1c2147"
                            _hover={{ background: "#ff6f94", color: "white", }}
                            borderRadius={'5px'}
                            ml={'5%'}
                            mr={'5%'}
                        >
                            Operations
                        </MenuButton>
                        <MenuList >
                            <CustomMenuItem
                                textColor="#1c2147"
                                buttonColor="#f2f2f2"
                                spanColor="#ff6f94"
                                onClick={() => startIncident(1)}
                                label="IT"
                            />
                            <CustomMenuItem
                                textColor="#1c2147"
                                buttonColor="#f2f2f2"
                                spanColor="#ff6f94"
                                onClick={() => startIncident(4)}
                                label="On Call"
                            />
                            <CustomMenuItem
                                textColor="#1c2147"
                                buttonColor="#f2f2f2"
                                spanColor="#ff6f94"
                                onClick={() => startIncident(7)}
                                label="Calls"
                            />
                            {[1, 26, 37].includes(userAuth.type_user_id) ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#ff6f94"
                                    onClick={() => startIncident(9)}
                                    label="QA Clarification"
                                />
                            ) : null}
                            {[1, 26, 27, 28, 29, 37].includes(userAuth.type_user_id) ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#ff6f94"
                                    onClick={openQAIncidence}
                                    label="QA Incidence"
                                />
                            ) : null}
                        </MenuList>
                    </Menu>

                    {/* Submenu2 */}
                    <Menu>
                        <MenuButton
                            as={Button}
                            bg={'#f2f2f2'}
                            color="#1c2147"
                            _hover={{ background: "#a2bd34", color: "white", }}
                            borderRadius={'5px'}
                            mr={'5%'}
                        >
                            Solutions for people
                        </MenuButton>
                        <MenuList >
                            <CustomMenuItem
                                textColor="#1c2147"
                                buttonColor="#f2f2f2"
                                spanColor="#a2bd34"
                                onClick={() => startIncident(2)}
                                label="HR"
                            />
                            <CustomMenuItem
                                textColor="#1c2147"
                                buttonColor="#f2f2f2"
                                spanColor="#a2bd34"
                                onClick={() => startIncident(6)}
                                label="Finances"
                            />
                            {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#a2bd34"
                                    onClick={() => startIncident(5)}
                                    label="Schedules"
                                />
                            ) : null}
                        </MenuList>
                    </Menu>
                    {/* Submenu3 */}
                    <Menu>
                        <MenuButton
                            as={Button}
                            bg={'#f2f2f2'}
                            color="#1c2147"
                            _hover={{ background: "#633cbd", color: "white", }}
                            borderRadius={'5px'}
                            mr={'5%'}
                        >
                            Resources
                        </MenuButton>
                        <MenuList >
                            {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#633cbd"
                                    onClick={() => startIncident(3)}
                                    label="Hiring"
                                />
                            ) : null}
                            {userAuth.type_user_id !== 22 && userAuth.type_user_id !== 29 && userAuth.type_user_id !== 35 && userAuth.type_user_id !== 36 && userAuth.type_user_id !== 43 && userAuth.type_user_id !== 44 ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#633cbd"
                                    onClick={() => startIncident(8)}
                                    label="Supervisor"
                                />
                            ) : null}
                            {[1, 2, 3, 4, 7, 9, 10, 13, 26, 37, 46].includes(userAuth.type_user_id) || [971].includes(userAuth.id) ? (
                                <CustomMenuItem
                                    textColor="#1c2147"
                                    buttonColor="#f2f2f2"
                                    spanColor="#633cbd"
                                    onClick={openFinancialRequest}
                                    label="IFR"
                                />
                            ) : null}
                        </MenuList>
                    </Menu>
                </MenuList>
            </Menu>
        </>
    );
}
