import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseAuditsSupervisor from "./components/itemsAudits/functions";

export default function ReportAuditsOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseAuditsSupervisor/>
      </Card>
    </Flex>
  );
} 