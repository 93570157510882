import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import {
    useToast
} from '@chakra-ui/react'
import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup, Text, Flex, Box, Badge,
    Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { SettingsIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FaNoteSticky } from "react-icons/fa6";
import { MdEditDocument } from 'react-icons/md';
import { DeleteIcon, RepeatIcon } from '@chakra-ui/icons'
import { FaCheckCircle } from "react-icons/fa";
import { useUserData } from 'interceptors/userAuth';

function QAIncidence({ total, currentPage, QAIncidences, setCurrentPage, totalPages, handleNotes, setQAIncidences, handleDelete }) {


    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const { user, fetchData } = useUserData();

    const handleClose = async (item) => {
        const msg = item.status === 1 ? 'Close' : 'Activate';
        const confirmDelete = await Swal.fire({
            title: `Do you want to ${msg} This Incident?`,
            text: `The Incident will be ${msg}...`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2C5282',
            cancelButtonColor: '#E53E3E',
            confirmButtonText: `Yes, ${msg} it!`
        }).then((result) => result.isConfirmed);

        if (confirmDelete) {
            setLoading(true);
            try {
                // Realiza la petición a la API
                const response = await axios.post(`/qaIncidence/close/${item.id}`);

                setQAIncidences(prevIncidences =>
                    prevIncidences.map(incidence =>
                        incidence.id === item.id
                            ? { ...incidence, status: item.status === 1 ? 2 : 1 }
                            : incidence
                    )
                );

                toast({
                    title: "Status Updated Successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error Updating the Status",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };



    const getColorStatus = (option) => {
        const colorMap = {
            1: '#fabb1c',
            2: '#8a6dce',
            3: '#7080fc',
            4: '#a2bd34',
            5: '#fc7466',
            6: '#fc7466'
        };
        return colorMap[option] || '#8a6dce';
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <Table size={'sm'} mt={"2%"}>
                <Thead bg={"white"}>
                    <Tr>
                        <Th textAlign={'center'}>Agent</Th>
                        <Th textAlign={'center'}>Team</Th>
                        <Th textAlign={'center'}>QA Agent</Th>
                        <Th textAlign={'center'}>Date</Th>
                        <Th textAlign={'center'}>Reason</Th>
                        <Th textAlign={'center'}>Status</Th>
                        <Th textAlign={'center'}>Follow up date</Th>
                        <Th textAlign={'center'}>Notes</Th>
                        <Th textAlign={'center'}>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {QAIncidences && QAIncidences.length > 0 ? (
                        QAIncidences.map((item, index) => (
                            <Tr key={item.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.users.name.split(' ')[0]} {item.users.last_name.split(' ')[0]}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge color={"white"} bg={'#424ea8'} borderRadius={'5px'} width={'100px'} fontSize={'10px'} whiteSpace={'pre-wrap'}>
                                        {item.client.name}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.supervisor.name.split(' ')[0]} {item.supervisor.last_name.split(' ')[0]}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.date_formatted}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(item.reason)}
                                        borderRadius={'5px'}
                                        width={'120px'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            switch (item.reason) {
                                                case 1:
                                                    return "Day Of";
                                                case 2:
                                                    return "Call Out";
                                                case 3:
                                                    return "Medical Leave";
                                                case 4:
                                                    return "Did Not Join";
                                                case 5:
                                                    return "Other";
                                                case 6:
                                                    return "scored Zero";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge
                                        color='white'
                                        bg={getColorStatus(item.status)}
                                        borderRadius={'5px'}
                                        width={'120px'}
                                        fontSize={'10px'}
                                        whiteSpace={'pre-wrap'}
                                    >
                                        {(() => {
                                            switch (item.status) {
                                                case 1:
                                                    return "In review";
                                                case 2:
                                                    return "Closed";
                                                default:
                                                    return "---";
                                            }
                                        })()}
                                    </Badge>
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.follow_up_date_formatted}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Flex justifyContent={'center'} cursor={'pointer'} onClick={() => handleNotes(item)}>

                                        {(item.countNotes !== 0) ? (
                                            <>
                                                <Box>
                                                    <FaNoteSticky />
                                                </Box>
                                                <Text ml={'5%'}>
                                                    {item.countNotes}
                                                </Text>
                                            </>
                                        ) :
                                            <Box>
                                                <FaNoteSticky />
                                            </Box>
                                        }

                                    </Flex>
                                </Td>
                                <Td textAlign={'center'}>
                                    <Menu>
                                        <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                            <SettingsIcon pb={"2px"} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem isDisabled>
                                                <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                                            </MenuItem>
                                            <MenuItem>
                                                <Button
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    bg="#f2f2f2"
                                                    _hover={{ background: "#ff9fb8", color: 'white' }}
                                                    color="#1c2147"
                                                    width="100%"
                                                    borderRadius={'5px'}
                                                    onClick={() => handleNotes(item)}
                                                >
                                                    <Text
                                                        flex={1}
                                                        textAlign="center"
                                                    >
                                                        Notes
                                                    </Text>
                                                    <FaNoteSticky ml={2} />
                                                </Button>
                                            </MenuItem>
                                            <MenuItem>
                                                <Button
                                                    borderRadius={"5px"}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    color={"#1c2147"}
                                                    _hover={{ background: item.status === 1 ? '#de5b5b' : '#8aad01', color: 'white' }}
                                                    bg={"#f2f2f2"}
                                                    width="100%"
                                                    onClick={() => handleClose(item)}
                                                    isLoading={loading}
                                                >
                                                    {item.status === 1 ? (
                                                        <>
                                                            <Text flex={1} textAlign={'center'}>Close</Text><FaCheckCircle ml={2} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Text flex={1} textAlign={'center'}>Activate</Text><RepeatIcon ml={2} />
                                                        </>
                                                    )}
                                                </Button>
                                            </MenuItem>
                                            {[1, 26, 37].includes(user.type_user_id) ? (
                                                <MenuItem>
                                                    <Button
                                                        borderRadius={"5px"}
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        color={"#1c2147"}
                                                        _hover={{ background: '#de5b5b', color: 'white' }}
                                                        bg={"#f2f2f2"}
                                                        width="100%"
                                                        onClick={() => handleDelete(item)}
                                                        isLoading={loading}
                                                    >
                                                        <Text flex={1} textAlign={'center'}>Delete</Text><DeleteIcon ml={2} />
                                                    </Button>
                                                </MenuItem>
                                            ) : null}

                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'9'} >
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2}>
                                    <MdEditDocument color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No incidences
                                </Text>


                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <MdEditDocument color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                                    {total === 1 ? 'Incidence' : 'Incidences'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>

    );
}
export default QAIncidence; 