import React from "react";
import {
    Button, Flex, Modal, ModalBody, ModalOverlay, Heading, ModalCloseButton,
    ModalContent, Table, Tbody
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import FormatDefault from './formatsType/formatDefault';
import FormatEndicott from './formatsType/formatEndicott';
import FormatAnswerPro from './formatsType/formatAnswerPro';

function FormatsModal({ isOpen, onClose, formats, client }) {

    const renderFormatComponent = () => {
        if (Number(client) === 38) {
            return <FormatAnswerPro formats={formats} />;
        } else if (Number(client) === 19) {
            return <FormatEndicott formats={formats} />;
        } else {
            return <FormatDefault formats={formats} />;
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent mt={"2%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalCloseButton onClick={onClose} mt={'1%'} />
                <ModalBody>
                    <Heading style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'5%'} mb={'2%'} fontWeight={'bold'} fontSize={'35px'}>
                        Feedback
                    </Heading>
                    <Flex ml={10} mr={10} direction={'column'}>
                        <Flex>
                            <Table size={'sm'}>
                                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                                    {renderFormatComponent()}
                                </Tbody>
                            </Table>
                        </Flex>

                        <Flex display={'flex'} justifyContent={'center'} mt={'2%'} mb={'5%'}>
                            <Button class="buttonCancel" onClick={() => {
                                onClose();
                            }}>
                                Close <CloseIcon ml={2} />
                            </Button>
                        </Flex>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default FormatsModal;
