import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
    TableContainer, Flex, useToast
} from '@chakra-ui/react'
import FiltersQualification from './filters';
import Qualification from './table';
import UpdateQuestionsCall from './update';


function UseQualification({ }) {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [qualificationData, setQualificationData] = useState([]);
    const [user, setUser] = useState([]);
    const [flashing, setFlashing] = useState(false);
    const [selectQA, setSelectQA] = useState(null);
    const [resetKey, setResetKey] = useState(0);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [userTypeSearch, setUserTypeSearch] = useState('');
    const [modalUpdate, setModalUpdate] = useState(false);
    const [selectData, setSelectData] = useState(null);
    const [selectDate, setSelectDate] = useState(null);
    const [clientS, setClientS] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [usersClients, setUsersClients] = useState([]);

    const clearFilters = () => {
        setUserTypeSearch(null);
        setClientSearch(null);
        setStartDateFilter('');
        setEndDateFilter('');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const optionsUsers = user.map(user => ({
        value: user.id,
        label: `${user.name} ${user.last_name}`,
    }));

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginRight: '3px',
            marginLeft: '10px',
            height: '40px',
            width: '250px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            setQualificationData([]);

            const response = await axios.get(`/indexCalls?page=${currentPage}` +
                `${userTypeSearch ? `&operator=${encodeURIComponent(userTypeSearch)}` : ''}` +
                `${clientSearch ? `&client=${encodeURIComponent(clientSearch)}` : ''}` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`
            );
            const responseData = response.data;
            setQualificationData(responseData.data);
            setTotal(responseData.total)
            setTotalPages(responseData.last_page);
            setUser(responseData.users);
            setClientS(responseData.clients);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, userTypeSearch, startDateFilter, endDateFilter, flashing, resetKey, clientSearch]);

    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserTypeSearch(selectedOption.value);
                break;
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const getRowBackground = (call) => {
        if (selectQA && selectQA.id === call.id) {
            if (flashing) {
                return '#fcd677';
            }
            return 'white';
        } else {
            return 'white';
        }
    };

    const handleUpdate = async (qa, data, date) => {
        setModalUpdate(true);
        setSelectQA(qa);
        setSelectData(data);
        setSelectDate(date);
    }

    const handleClientChange = (selectedOption) => {
        setClientSearch(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };


    const exportCalls = (call) => {
        // Configurar los parámetros de consulta correctamente
        const params = {
            id_user: call.id_user,
            date: call.date,
            client: call.id_client
        };

        axios.get('/exportCalls/', {
            params, // Añade los parámetros de consulta
            responseType: 'blob' // Especifica el tipo de respuesta
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'QA.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };

    const handleDelete = async (call) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this call?',
            text: 'The call will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/qaCalls/delete/${call.id}`);
                toast({
                    title: " Call deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting the Call",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };



    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    direction='column'
                    w='100%'

                    p={3}
                    borderRadius="16px"
                >
                </Flex>
                <FiltersQualification
                    optionsUsers={optionsUsers}
                    userTypeSearch={userTypeSearch}
                    handleSelectChangeF={handleSelectChange}
                    setCurrentPage={setCurrentPage}
                    customStyles={customStyles}
                    resetKey={resetKey}
                    filterOptions={filterOptions}
                    startDateFilter={startDateFilter}
                    setStartDateFilter={setStartDateFilter}
                    endDateFilter={endDateFilter}
                    setEndDateFilter={setEndDateFilter}
                    clearFilters={clearFilters}
                    clientSearch={clientSearch}
                    usersClients={usersClients}
                    optionClients={optionClients}
                    handleClientChange={handleClientChange}
                />
                <br />
                <Qualification
                    setCurrentPage={setCurrentPage}
                    qualificationData={qualificationData}
                    getRowBackground={getRowBackground}
                    total={total}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleUpdate={handleUpdate}
                    exportCalls={exportCalls}
                    handleDelete={handleDelete}
                />
                {modalUpdate && (
                    <UpdateQuestionsCall
                        isOpen={modalUpdate}
                        onClose={() => setModalUpdate(false)}
                        call={selectQA}
                        dataName={selectData}
                        date={selectDate}
                        data={fetchData}
                    />
                )}
            </Flex >
        </TableContainer >
    );
}
export default UseQualification; 