import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import IncidentList from '../table';

function UseIncidentList() {
    const [incident, setIncident] = useState([]);
    const [selectIncident, setSelectIncident] = useState(null);
    const [client, setClient] = useState([]);
    const [agent, setAgent] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [statusFilter, setStatusFilter] = useState(1);
    const [clientFilter, setClientFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [openShow, setOpenShow] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [openTranscript, setOpenTranscript] = useState(false);
    const [agentFilter, setAgentFilter] = useState('');
    const [supervisorFilter, setSupervisorFilter] = useState('');
    const [userAuth, setUserAuth] = useState([]);
    const [usersClients, setUsersClients] = useState([]);
    const [ticketFilter, setTicketFilter] = useState('');
    const [ticket, setTicket] = useState([]);
    const [idFilter, setIdFilter] = useState('');

    const clearFilters = () => {
        setStatusFilter(1);
        setClientFilter(null);
        setAgentFilter(null);
        setSupervisorFilter(null);
        setStartDateFilter('');
        setTicketFilter('');
        setIdFilter('');
        setEndDateFilter('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const fetchDataAuth = async () => {
        try {
            const response = await axios.get("/user");
            const user = parseInt(response.data.user.type_user_id, 10);
            setUserAuth(user);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    };

    const optionClients = client.map(client => ({
        value: client.id,
        label: client.name,
    }));

    const optionAgents = agent.map(agent => ({
        value: agent.id,
        label: `${agent.name} ${agent.last_name}`,
    }));

    const optionSupervisors = supervisor.map(supervisor => ({
        value: supervisor.id,
        label: `${supervisor.name} ${supervisor.last_name}`,
    }));

    const optionTickets = ticket.map(ticket => ({
        value: ticket.id,
        label: ticket.name,
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '135px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async () => {
        try {
            const dateStartParam = startDateFilter ? `&date_start=${startDateFilter.toISOString()}` : '';
            const dateEndParam = endDateFilter ? `&date_end=${endDateFilter.toISOString()}` : '';
            const response = await axios.get(
                `/incidents?page=${currentPage}&
                ${statusFilter ? `&status=${statusFilter}` : ''}
                ${clientFilter ? `&client=${clientFilter}` : ''}
                ${agentFilter ? `&operator=${agentFilter}` : ''}
                ${supervisorFilter ? `&supervisor=${supervisorFilter}` : ''}
                ${ticketFilter ? `&ticket=${ticketFilter}` : ''}
                ${idFilter ? `&id=${idFilter}` : ''}
                ${dateStartParam}${dateEndParam}`
            );
            const responseData = response.data;
            setIncident(responseData.data);
            setClient(responseData.clients);
            setAgent(responseData.agents);
            setSupervisor(responseData.supervisor);
            setTicket(responseData.tickets);
            setTotalPages(responseData.pagination.last_page);
            setTotal(responseData.pagination.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleClientChange = (selectedOption) => {
        setClientFilter(selectedOption.value);

        if (selectedOption) {
            axios
                .get(`/userClient?id_client=${selectedOption.value}`)
                .then((response) => {
                    const userOptions = response.data.data.map((user) => ({
                        label: user.user ? `${user.user.name} ${user.user.last_name}` : '',
                        value: user.id_user,
                    }));
                    setUsersClients(userOptions);
                });
        } else {
            setUsersClients([]);
        }
    };

    const exportTickets = () => {
        const dateStartParam = startDateFilter ? `&date_start=${startDateFilter.toISOString()}` : '';
        const dateEndParam = endDateFilter ? `&date_end=${endDateFilter.toISOString()}` : '';
        axios.get(
            `/incidentsP?
            ${statusFilter ? `&status=${statusFilter}` : ''}&
            ${clientFilter ? `&client=${clientFilter}` : ''}
            ${agentFilter ? `&operator=${agentFilter}` : ''}
            ${supervisorFilter ? `&supervisor=${supervisorFilter}` : ''}
            ${ticketFilter ? `&ticket=${ticketFilter}` : ''}
            ${idFilter ? `&id=${idFilter}` : ''} 
            ${dateStartParam}${dateEndParam}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Tickets.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, statusFilter, clientFilter, startDateFilter, endDateFilter, resetKey, agentFilter, supervisorFilter, ticketFilter, idFilter]);


    useEffect(() => {
        fetchDataAuth();
    }, []);

    const handleSelectChange = (componentName, selectedOption) => {
        switch (componentName) {
            case 'client':
                setClientFilter(selectedOption.value);
                break;
            case 'agent':
                setAgentFilter(selectedOption.value);
                break;
            case 'supervisor':
                setSupervisorFilter(selectedOption.value);
                break;
            case 'ticket':
                setTicketFilter(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleInfo = async (incident) => {
        try {
            const response = await axios.get(`/incidents/show/${incident.id}`);
            const { incident: detail, supervisor, setting, clients, chat, operatorType, supervisorType, operator, closeUser, closeUserType } = response.data;
            setSelectIncident({ ...incident, setting, clients, chat, operatorType, supervisorType, operator, supervisor, closeUser, closeUserType });
            setOpenShow(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    const closeInfoModal = () => {
        setOpenShow(false);
    };

    const closeTranscriptModal = () => {
        setOpenTranscript(false);
    };

    const getColorSchemeForPriority = (priority) => {
        const colorMap = {
            1: '#ff6f94',
            2: '#fabb1c',
            3: '#633cbd',
            4: '#fb9539',
            5: '#a2bd34',
            6: '#6bc2ed',
            7: '#7080fc',
            8: '#fc7466',
            9: '#424ea8',
        };
        return colorMap[priority] || '#ff6f94';
    };

    const handleTranscript = async (incident) => {
        try {
            const response = await axios.get(`/incidents/show/${incident.id}`);
            const { incident: detail, supervisor, setting, clients, chat, operatorType, supervisorType, operator, closeUser, closeUserType } = response.data;
            setSelectIncident({ ...incident, setting, clients, chat, operatorType, supervisorType, operator, supervisor, closeUser, closeUserType });
            setOpenTranscript(true);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    return (
        <IncidentList
            userAuth={userAuth}
            idFilter={idFilter}
            setIdFilter={setIdFilter}
            clientFilter={clientFilter}
            usersClients={usersClients}
            optionAgents={optionAgents}
            agentFilter={agentFilter}
            handleSelectChange={handleSelectChange}
            setCurrentPage={setCurrentPage}
            customStyles={customStyles}
            resetKey={resetKey}
            optionSupervisors={optionSupervisors}
            supervisorFilter={supervisorFilter}
            optionClients={optionClients}
            handleClientChange={handleClientChange}
            optionTickets={optionTickets}
            ticketFilter={ticketFilter}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            clearFilters={clearFilters}
            incident={incident}
            getColorSchemeForPriority={getColorSchemeForPriority}
            loading={loading}
            handleInfo={handleInfo}
            handleTranscript={handleTranscript}
            total={total}
            currentPage={currentPage}
            totalPages={totalPages}
            openShow={openShow}
            selectIncident={selectIncident}
            openTranscript={openTranscript}
            fetchData={fetchData}
            closeInfoModal={closeInfoModal}
            closeTranscriptModal={closeTranscriptModal}
            exportTickets={exportTickets}
        />
    );
}
export default UseIncidentList;
