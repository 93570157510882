import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { FaRegCalendar } from "react-icons/fa6";
import { MdDashboard } from "react-icons/md";
import { IoTicketSharp } from "react-icons/io5";
import { TbReportMoney } from "react-icons/tb";

import DashboardsDefault from 'views/admin/dashboards/default';
import IncidentsOverview from 'views/admin/main/incidents';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import Default from 'views/admin/main/others/messages';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';

import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import FinancialUserRequestOverview from 'views/admin/main/financialRequests/indexUser';


const routesHiringManager = [
    // --- Dashboards ---
    {
        name: 'Home',
        layout: '/admin',
        path: '/home',
        icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
        component: <DashboardsDefault />,
    },
    //{
    //name: 'Profile',
    //path: '/profile',
    //icon: <Icon as={ImProfile} width="30px" height="30px" color="inherit" />,
    //layout: '/admin',
    //component: <OverviewProfile />,
    //},
    {
        name: 'Dashboard',
        layout: '/admin',
        path: '/dashboards',
        icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
        component: <DailyOverview />,
    },
    {
        name: 'Schedules',
        icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
        path: '/schedules',
        collapse: true,
        items: [
            {
                name: 'Scheduler',
                layout: '/admin',
                path: '/schedules',
                exact: false,
                component: <UsersSchedulesOverview />,
            },
            {
                name: 'My schedule',
                path: '/my-schedule',
                layout: '/admin',
                component: <MyScheduleOverview />,
            },
        ],
    },
    {
        name: 'IFR',
        layout: '/admin',
        path: '/ifr',
        icon: <Icon as={TbReportMoney} width="30px" height="30px" color="inherit" />,
        component: <FinancialUserRequestOverview />,
    },
    {
        name: 'Tickets',
        icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
        path: '/Ticket',
        collapse: true,
        items: [
            {
                name: 'Requests',
                layout: '/admin',
                path: '/ticket/chat',
                exact: false,
                component: <Default />,
            },
            {
                name: 'Completed',
                layout: '/admin',
                path: '/ticket/dashboard',
                exact: false,
                component: <IncidentsOverview />,
            },
            {
                name: 'Ongoing',
                layout: '/admin',
                path: '/ticketU/chat',
                exact: false,
                component: <DefaultUser />,
            },
            {
                name: 'My Completed',
                layout: '/admin',
                path: '/ticketU/finish',
                exact: false,
                component: <IncidentsAgentOverview />,
            },

        ],
    },

];

export default routesHiringManager;
