import Select2 from 'react-select'
import {
    Button, Input,
    Flex, Box, Select
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";


function CalendarFilters({ setCurrentPage, setFilterDateEnd, setFilterDate, filterDate, filterDateEnd, clearFilters, setLocationSearch, locationSearch }) {

    return (

        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            p={2}
            borderRadius="16px"
        >
            
            <Flex flexDirection={"column"} bg="gray.200" p={2} borderRadius={"16px"} >
                <Flex align="center"    >
                   
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={6} mr={4}
                    >
                        <Input
                            width={'200px'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDate}
                            onChange={(e) => {
                                setFilterDate(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={2} mr={1}
                    >
                        <Input
                            width={'200px'}
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDateEnd}
                            onChange={(e) => {
                                setFilterDateEnd(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>

                    <Select
                        borderRadius={"5px"}
                        borderColor={'#808080'}
                        color={'#808080'}
                        _focusVisible={'none'}
                        _hover={{
                            borderColor: "#808080",
                        }}
                        width={'200px'}
                        ml={4}
                        value={locationSearch}
                        onChange={(e) => {
                            setLocationSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                    >
                        <option value="">Select Location</option>
                        <option value="1">MX</option>
                        <option value="2">BZ</option>
                        <option value="3">RD</option>
                        <option value="4">COL</option>
                        <option value="4">GTM</option>
                    </Select>

                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Box>
                </Flex>

            </Flex>

        </Flex>
    );
}
export default CalendarFilters; 