import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Text,
    Box, Flex,
    Grid, Badge,
    Textarea,
    Select,
    useToast,
} from '@chakra-ui/react';
import Card from "components/card/Card.js";
import { CloseIcon, DeleteIcon, AddIcon, RepeatClockIcon } from '@chakra-ui/icons'
import Swal from 'sweetalert2'

const CreateQuestion = ({ isOpen, onClose, client, data }) => {

    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        password: '',
    });
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [selectedCoverage, setSelectedCoverage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [errorMsg, setErrorMsg] = useState("");
    const [question, setQuestion] = useState(null);
    const [questionHistory, setQuestionHistory] = useState([]);
    const clearForm = () => {
        setFormData({
        
            question: '',
            score: '',
            type: ''
        });
        setFormErrors({});
    };

    const clearFilters = () => {
        setFormData({
            question: '',
            score: '',
            type: ''
        });
        setSelectedClient([]);
        setSelectedOperator([]);
        setSelectedCoverage([]);
        setResetKey(resetKey + 1);
        setErrorMsg('');
        setQuestion(null);
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };



    useEffect(() => {
        // fetchData();
    }, [resetKey]);

    const handleSubmit = async () => {

        const formSubmitData = {
            id_client:client,
            question: formData.question,
            score: formData.score,
            type: formData.type,
        };

        try {
            const response = await axios.post('qaFormats/create', formSubmitData);
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                const createdQuestion = response.data.detail;

                setQuestionHistory((prevHistory) => [...prevHistory, createdQuestion]);

                setQuestion(createdQuestion);

                toast({
                    title: 'Question Added',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearFilters();
                data();
            }
        } catch (error) {
            toast({
                title: "Error Creating a question",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating Question:', error);
        }
    };

    const getColorSchemeForTypeQuestion = (type) => {
        const colorMap = {
            1: '#7080fc',
            2: '#fcd677',
            3: '#8a6dce',
            4: '#dd67ae',
            5: '#fca066',
            7: '#fcd677',
            8: '#dd67ae',
            9: '#fca066'
        };
        return colorMap[type] || '#7080fc';
    };

     const deleteQuestion = async (qa_formats) => {
         const confirmDelete = await Swal.fire({
             title: 'Do you want to delete this question?',
             text: "The question will be permanently removed",
             icon: 'warning',
             showCancelButton: true,
             confirmButtonColor: '#b9ce67',
             cancelButtonColor: '#fc7466',
             confirmButtonText: "Delete",
             cancelButtonText: "Cancel",
             closeOnConfirm: true,
             closeOnCancel: false
         }).then((result) => {
             return result.isConfirmed
         });

         if (confirmDelete) {
             setLoading(true);
             try {
                 const response = await axios.delete(`/qaFormats/delete/${qa_formats.id}`);
                 toast({
                     title: "Question Delete it!",
                     status: "success",
                     position: 'top',
                     duration: 3000,
                     isClosable: true,
                 });
                 data();
                 setQuestionHistory((prevQuestionHistory) =>
                     prevQuestionHistory.filter((item) => item.id !== qa_formats.id)
                 );
             } catch (error) {
                 console.error(error);

             } finally {
                 setLoading(false);
             }
         }
     };


    return (
        <Modal size="xxl" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} height={"max-content"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'2%'} fontWeight={'bold'} fontSize={'250%'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Add Questions
                        </Text>
                    </Flex>


                </ModalHeader>
                <ModalBody>
                    <Grid
                        display={'flex'}
                        column={3}
                        gap={{ base: "20px", xl: "50px" }}
                        mt={5}
                    >
                        <Card
                            display={'block'}
                            width="100%"
                            overflow="auto"
                        >

                            <Flex display={'flex'} mt={'2%'}>
                                <Text fontSize={'180%'} fontWeight={'bold'} color={'#1c2147'}>
                                    Question
                                </Text>
                            </Flex>
                            <Flex display='flex' justifyContent="center" mt={'2%'}>
                                <Textarea bg={'white'} mr={4} placeholder='Add Question' value={formData.question} onChange={handleChange} name='question' />
                            </Flex>


                            <Flex display={'flex'} mt={'2%'} >
                                <Text fontSize={'180%'} fontWeight={'bold'} color={'#1c2147'}>
                                    Score
                                </Text>
                                <Text fontSize={'180%'} fontWeight={'bold'} color={'#1c2147'} marginLeft={'30%'}>
                                    Section
                                </Text>
                            </Flex>
                            <Flex display='flex' justifyContent="left" mt={'2%'}>
                                <Input
                                    type='number'
                                    bg={'#f2f2f2'}
                                    width={'18%'}
                                    color={'#808080'}
                                    textAlign={'center'}
                                    value={formData.score}
                                    onChange={handleChange}
                                    name='score'
                                    placeholder='0' />

                                <Select
                                marginLeft={'20%'}
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    width={'30%'}
                                    name='type'
                                    value={formData.type}
                                    onChange={handleChange}
                                    color={'#808080'}
                                    background={"#f2f2f2"}
                                    textAlign={"center"}
                                    errorBorderColor='#fc7466'
                                    placeholder='Select Section'>
                                    <option value='1'>Quality </option>
                                    <option value='2'>Mannerisms</option>
                                    <option value="3">Caller Experience</option>
                                    <option value='4'>Soft Skills</option>
                                    <option value='5'>Opening</option>
                                    <option value='6'>Call Handling</option>
                                    <option value='7'>Documentation</option>
                                    <option value='8'>Closing</option>
                                    <option value='9'>Customer Service</option>
                                    <option value='10'>Critical Thinking</option>
                                    <option value='11'>Call Flow</option>
                                </Select>
                            </Flex>

                            <Flex display='flex' justifyContent="left" mt={'2%'}>


                            </Flex>


                            <Flex justifyContent="center" mt={'5%'}>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#b9ce67"}
                                    color={"white"}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={handleSubmit}
                                    mr={'2%'}
                                >
                                    Add
                                    <AddIcon ml={2} />
                                </Button>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#fcd677"}
                                    color={"white"}
                                    _hover={{ background: "#808080", color: 'white' }}
                                    onClick={clearFilters}
                                >
                                    Reset
                                    <RepeatClockIcon ml={2} />
                                </Button>
                            </Flex>
                        </Card>

                        <Card maxWidth='auto'>
                            <Flex display={'flex'} justifyContent={'center'} mt={'40px'}>
                                <Text color={'#808080'} fontWeight={'bold'} fontSize={'25px'}>
                                    Quality Assurance Monitoring Form
                                </Text>

                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'} mt={'40px'}>
                                <Box >
                                    <Flex>
                                        <Flex flexDirection="column">
                                            {questionHistory.map((qa_formats, index) => (
                                                <Flex key={index} direction={'row'}>
                                                    <Box display={'flex'} width={'600px'} mt={'30px'} bg={'#f2f2f2'} height={'50px'} justifyContent={'space-evenly'} ml={'50px'} mr={'50px'} borderRadius={'5px'}>
                                                        <Flex alignItems="center">
                                                            <Text fontWeight={'bold'} color={'#1c2147'} fontSize={'15px'} ml={'10px'} width={'150px'}>
                                                                {qa_formats.question}
                                                            </Text>
                                                        </Flex>
                                                        <Flex alignItems="center" >
                                                            <Text fontSize={'14px'} fontWeight={'bold'} color={getColorSchemeForTypeQuestion(qa_formats.type)}>
                                                                {qa_formats.score}
                                                            </Text>
                                                        </Flex>
                                                        <Flex alignItems="center" >
                                                            <Badge color={"white"} bg={getColorSchemeForTypeQuestion(qa_formats.type)} fontSize='10px' borderRadius={"5px"} width={'auto'} textAlign={'center'} mr={'10px'}>
                                                                {(() => {
                                                                    switch (qa_formats.type) {
                                                                        case 1:
                                                                            return "Call Flow section";
                                                                        case 2:
                                                                            return "Mannerisms during the call section";
                                                                        case 3:
                                                                            return "Caller Experience";
                                                                        case 4:
                                                                            return "Soft Skills";
                                                                        case 5:
                                                                            return "Opening"
                                                                        case 6:
                                                                            return "Call Handling"
                                                                        case 7:
                                                                            return "Documentation"
                                                                        case 8:
                                                                            return "Closing"
                                                                        default:
                                                                            return "--";
                                                                    }
                                                                })()}
                                                            </Badge>
                                                        </Flex>
                                                        
                                                        
                                                        <Flex alignItems="center">
                                                            <Button bg={'#fc7466'} color={'white'} _hover={{ background: "#de5b5b", color: 'white' }} borderRadius={'5px'} size={'xs'} onClick={() => deleteQuestion(qa_formats)}>
                                                                <DeleteIcon />
                                                            </Button> 
                                                        </Flex>
                                                    </Box>
                                                </Flex>
                                            ))}
                                        </Flex> 
                                    </Flex>
                                </Box>
                            </Flex>
                        </Card>
                    </Grid>
                </ModalBody>
                <ModalFooter justifyContent="center" >

                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateQuestion;
