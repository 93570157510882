import React, { useState, useEffect } from 'react';
import {
    TableContainer,
} from '@chakra-ui/react'
import axios from 'axios';
import FiltersFinancesBox from '../filters';
import FinanceBoxMX from '../table';


function UseFinanceBoxBZ() {

    const [reportsD, setReportsD] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBar, setIsLoadingBar] = useState(false);


    const clearFilters = () => {
        setStartDateFilter('');
        setEndDateFilter('');
        setCurrentPage(1);
    }

    const fetchData = async () => {

        try {

            setIsLoading(false);
            setReportsD([]);

            const response = await axios.get(`/cashFund/report/BZ?page=${currentPage}` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`
            );
            const responseData = response.data;
            setReportsD(responseData.data.data);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
            setIsLoading(true);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, startDateFilter, endDateFilter]);


    const handleDownloadExcel = async () => {
        try {
            // Construir los parámetros de la URL dinámicamente
            const params = new URLSearchParams();

            if (startDateFilter) {
                params.append('startDate', startDateFilter);
            }
            if (endDateFilter) {
                params.append('endDate', endDateFilter);
            }

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const url = `/download/cashFund/report/BZ?${params.toString()}`;

            setIsLoadingBar(true);

            // Realizar la petición utilizando async/await
            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `FinancialReportBZ${startDate}_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl); // Liberar el objeto URL
            setIsLoadingBar(false);
        } catch (error) {
            console.error('Error al descargar el archivo Excel:', error);
        } finally {
            setIsLoadingBar(false);
        }
    };

    return (
        <TableContainer>
            <FiltersFinancesBox
                handleDownloadExcel={handleDownloadExcel}
                setCurrentPage={setCurrentPage}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                clearFilters={clearFilters}
                isLoadingBar={isLoadingBar}
            />
            <FinanceBoxMX
                setCurrentPage={setCurrentPage}
                reportsD={reportsD}
                isLoading={isLoading}
                total={total}
                currentPage={currentPage}
                totalPages={totalPages}
            />
        </TableContainer>
    );
}
export default UseFinanceBoxBZ; 