import React, { useState, useEffect } from "react";
import {
    Box, Flex, Button, Text, Heading, TableContainer, Table, Tr, Th, Td, Tbody, Thead, Input, Textarea
} from '@chakra-ui/react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

function FormatEndicott({ getRowBackground, format, number, updateFormat, onNext, onPrev, currentIndex, duration, setDuration, totalScore, setTotalScore, comment, setComment, possibleScore, setPossibleScore, account, setAccount }) {

    // Calcular el total de todas las tablas cuando se modifica los inputs
    const calculateTotalScore = (questions) => {
        return questions
            .filter((q) => q.selectedOption !== "X" && q.selectedOption !== "N")
            .reduce((total, q) => total + (parseFloat(q.editableScore || 0) || 0), 0);
    };

    // Recalcular el total cuando cambie `format`
    useEffect(() => {
        setTotalScore(calculateTotalScore(format || []));
    }, [format]);

    // Recalcular al montar el componente
    useEffect(() => {
        setTotalScore(calculateTotalScore(format || []));
    }, []); // Este efecto se ejecuta una vez al montar

    const calculatePosibleScore = (questions) => {
        return questions
            .filter(item => item.selectedOption !== "X") // Excluir elementos con opción "X"
            .reduce((acc, item) => acc + (item.score || 0), 0);
    };

    // Recalcular el total cuando cambie `format`
    useEffect(() => {
        setPossibleScore(calculatePosibleScore(format || []));
    }, [format]);

    // Recalcular al montar el componente
    useEffect(() => {
        setPossibleScore(calculatePosibleScore(format || []));
    }, []); // Este efecto se ejecuta una vez al montar


    // Función para actualizar el valor del puntaje editable
    const handleInputChange = (id, value) => {
        const updatedFormat = format.map((item) =>
            item.id === id ? { ...item, editableScore: value } : item
        );
        updateFormat(updatedFormat); // Actualizar solo esta llamada
    };

    // Función para manejar el cambio en las opciones seleccionadas
    const handleSelectChange = (id, value) => {
        const updatedFormat = format.map((item) =>
            item.id === id
                ? {
                    ...item,
                    selectedOption: value,
                    editableScore: value === "Y" ? item.score : value === "N" ? "0" : value === "X" ? "N/A" : item.score,
                }
                : item
        );
        updateFormat(updatedFormat); // Actualizar solo esta llamada
    };

    useEffect(() => {
        // Inicializar editableScore y selectedOption solo una vez al cargar el componente
        if (format && format.length > 0) {
            const initializedFormat = format.map((item) => ({
                ...item,
                editableScore: item.editableScore ?? item.score ?? 0, // Valor predeterminado para editableScore
                selectedOption: item.selectedOption ?? "Y", // Valor predeterminado para selectedOption
            }));
            updateFormat(initializedFormat);
        }
        // Este efecto se ejecutará solo al montar el componente
    }, []);

    const handleDurationChange = (e) => {
        const value = e.target.value;
        setDuration(value);
    };

    const handleCommentChange = (e) => {
        const value = e.target.value;
        setComment(value);
    };

    const handleAccountChange = (e) => {
        const value = e.target.value;
        setAccount(value);
    };

    return (
        <Flex direction="column" pl={'2%'} mb={'2%'}>
            <Flex direction="column" alignItems={'end'}>
                <Flex mb="1%" alignItems="center" justifyContent={'center'} w={'40%'}>
                    <Text fontSize="lg" mr="4">Duration:</Text>
                    <Input
                        type="text"
                        value={duration}
                        onChange={handleDurationChange}
                        width="55%"
                        placeholder="Duration"
                    />
                </Flex>

                <Flex mb="1%" alignItems="center" justifyContent={'center'} w={'40%'}>
                    <Text fontSize="lg" mr="4">Account:</Text>
                    <Input
                        type="text"
                        value={account}
                        onChange={handleAccountChange}
                        width="100%"
                        placeholder="Account"
                    />
                </Flex>

                <Flex justifyContent='center' alignItems="center" mb={4} w={'20%'}>
                    <Button
                        onClick={onPrev}
                        borderRadius={'0px'}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                        mr={'12%'}
                    >
                        <BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text fontSize="lg" fontWeight="bold" color="#1c2147" mr={'10%'}>
                        {currentIndex + 1} / {number}
                    </Text>
                    <Button onClick={onNext}
                        borderRadius={'0px'}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    >
                        <BsArrowRightShort fontSize="20px" />
                    </Button>
                </Flex>
            </Flex>

            {/* Mostrar solo el elemento actual basado en Array.from */}
            {Array.from({ length: number }, (_, i) => (
                i === currentIndex && ( // Renderizar solo el índice actual
                    <Box key={i}>
                        <TableContainer>
                            <Table size={'sm'}>
                                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"} >
                                    {format && format.length > 0 ? (
                                        <>
                                            <Heading as="h2" size="lg" mb={2} textAlign="center">
                                                Call {i + 1}
                                            </Heading>

                                            {/* Sección para las preguntas de tipo 3 (Caller Experience) */}
                                            <Box>
                                                <Heading as="h2" size="md" mb={4}>Caller Experience</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 3).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />

                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            {/* Sección para Soft Skills */}
                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Soft Skills</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 4).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            {/* Sección para Technical Skill */}
                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Opening</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 5).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Call Handling</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 6).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Documentation</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 7).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td textAlign={'left'} whiteSpace="pre-wrap" wordBreak="break-word" fontSize={'110%'}>
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                            <Box mt={8}>
                                                <Heading as="h2" size="md" mb={4}>Closing</Heading>
                                                <Thead bg={"white"}>
                                                    <Tr>
                                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                                        <Th textAlign={'center'}>Select Option</Th>
                                                        <Th textAlign={'center'}>Edit Score</Th>
                                                    </Tr>
                                                </Thead>
                                                {format.filter(item => item.type === 8).map((item) => (
                                                    <Tr key={item.id} bg={getRowBackground(item)}>
                                                        <Td
                                                            textAlign={'left'}
                                                            whiteSpace="pre-wrap"
                                                            wordBreak="break-word"
                                                            fontSize={'110%'}
                                                        >
                                                            {item.question}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <select
                                                                value={item.selectedOption || "Y"} // Valor predeterminado "Y"
                                                                onChange={(e) => handleSelectChange(item.id, e.target.value)}
                                                            >
                                                                <option value="Y">Y</option>
                                                                <option value="X">X</option>
                                                                <option value="N">N</option>
                                                            </select>
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            <input
                                                                type="text"
                                                                value={
                                                                    item.selectedOption === "X"
                                                                        ? "N/A"
                                                                        : item.editableScore !== undefined && item.editableScore !== null
                                                                            ? item.editableScore
                                                                            : item.score || ""
                                                                }
                                                                onChange={(e) => {
                                                                    if (item.selectedOption !== "X") { // Permitir cambios solo si no es "X"
                                                                        const newValue = e.target.value;
                                                                        if (newValue === "" || (parseInt(newValue, 10) >= 0 && parseInt(newValue, 10) <= item.score)) {
                                                                            handleInputChange(item.id, newValue);
                                                                        }
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (item.selectedOption !== "X" && e.target.value === "") {
                                                                        handleInputChange(item.id, "0");
                                                                    }
                                                                }}
                                                                style={{
                                                                    width: "60px",
                                                                    textAlign: "center",
                                                                    backgroundColor:
                                                                        item.selectedOption === "Y" || item.selectedOption === "X" || item.selectedOption === item.editableScore
                                                                            ? "#d4f8d4"
                                                                            : item.selectedOption === "N"
                                                                                ? "#f8d4d4"
                                                                                : "#d4f8d4", // Fondo blanco por defecto
                                                                }}
                                                                disabled={item.selectedOption === "X" || item.selectedOption === "N"} // Deshabilitar si es "X" o "N"
                                                            />
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Box>

                                        </>
                                    ) : (
                                        <Tr>
                                            <Th colSpan={'9'}>
                                                <Text
                                                    color='#1c2147'
                                                    fontSize={"25px"}
                                                    textAlign={"center"}
                                                    paddingTop={"15px"}
                                                    paddingBottom={"15px"}
                                                    bg={"#f2f2f2"}
                                                    textTransform={'none'}
                                                    fontFamily={'DM Sans'}
                                                    letterSpacing="-1px"
                                                >
                                                    No data
                                                </Text>
                                            </Th>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            ))}

            <Flex mt={'2%'}>
                <Textarea
                    w={'100%'}
                    borderColor={"#b3b3b3"}
                    placeholder="Comments:"
                    background="white"
                    errorBorderColor='#fc7466'
                    value={comment}
                    onChange={handleCommentChange}
                >
                </Textarea>
                <Flex direction={'column'} textAlign={'right'} w={'20%'}>

                    <Text fontSize={'18px'} fontWeight="bold" color="#1c2147" mr={'2.5%'}>
                        Pts. Earned: {totalScore}
                    </Text>

                    <Text fontSize={'18px'} fontWeight="bold" color="#1c2147" mr={'2%'}>
                        Pts. possible: {possibleScore}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default FormatEndicott;
