import { Text, Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import TimelineItem from 'components/dataDisplay/TimelineItem';

export default function Events(props) {
    const { ...rest } = props;
    const [events, setEvents] = useState([]);
    const [date, setDate] = useState('');


    const currentEvents = async () => {
        try {
            const response = await axios.get('/calendar/eventsP')
            setEvents(response.data.events);
            setDate(response.data.date);
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error);
        }
    }

    useEffect(() => {
        currentEvents();
    }, []);

    const dayMappings = {
        1: 'Mon',
        2: 'Tues',
        3: 'Wed',
        4: 'Thur',
        5: 'Fri',
        6: 'Sat',
        7: 'Sun'
    };

    return (
        <Flex direction={'column'} w={'70%'} overflowY={'auto'} height={'30%'} mt={'5%'} ml={'5%'}>
            {events.length > 0 ? (
                <Flex display={'flex'} direction={'column'} mt={1}>
                    <Text fontSize="30px" fontWeight="700" color={'#1c2147'}>
                        Events
                    </Text>
                    <Text fontWeight={"bold"} borderRadius={"10px"} color={"#b3b3b3"}>
                        View the events.
                    </Text>
                </Flex>
            ) : (
                null
            )}
            {events.length > 0 ? (
                <>
                    {
                        events.map((event) => (
                            <Box key={event.id} width='max-content' mb={'20px'}>
                                <TimelineItem
                                    className='timelineminibox'
                                    boxColor={event.start === date ? '#424ea8' : 'white'}
                                    miniCardColor={'#f2f2f2'}
                                    textColor={event.start === date ? 'white' : '#1c2147'}
                                    textSecondaryColor={event.start === date ? 'white' : '#808080'}
                                    title={event.title}
                                    day={event.day}
                                    weekday={dayMappings[event.dayN]}
                                    note={event.description ? event.description : ''}
                                    date={event.dateF}
                                    colorDate={event.start === date ? '#b3b3b3' : '#1c2147'}
                                />
                            </Box>
                        ))
                    }
                </>
            ) : (
                null
            )}
        </Flex>

    );
}
