import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td,
    Badge, Text, Flex, Box, Heading,
} from '@chakra-ui/react'
import QAButtons from '../items5_1/Buttons';

function QAsTableEndicott({ QA, getRowBackground, handleUpdate, deleteQuestion }) {

    return (
        <Flex>
            <Table size={'sm'}>

                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                    {QA && QA.length > 0 ? (
                        <>
                            <Box>
                                <Heading as="h2" size="md" mb={4}>Caller Experience</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 3).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                            <Box mt={'1%'}>
                                <Heading as="h2" size="md" mb={4}>Soft Skills</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 4).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                             <Box mt={'1%'}>
                                <Heading as="h2" size="md" mb={4}>Opening</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 5).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                             <Box mt={'1%'}>
                                <Heading as="h2" size="md" mb={4}>Call Handling</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 6).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                             <Box mt={'1%'}>
                                <Heading as="h2" size="md" mb={4}>Documentation</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 7).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>
                            <Box mt={8}>
                                <Heading as="h2" size="md" mb={4}>Closing</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'}>Question</Th>
                                        <Th textAlign={'center'}>Maximum Points Possible</Th>
                                        <Th textAlign={'center'}>Status</Th>
                                        <Th textAlign={'center'}>Actions</Th>
                                    </Tr>
                                </Thead>
                                {QA.filter(item => item.type === 8).map(QA => (
                                    <Tr key={QA.id} bg={getRowBackground(QA)}>
                                        <Td textAlign={'left'} width={'80%'} whiteSpace={'pre-wrap'}>
                                            {QA.question}
                                        </Td>
                                        <Td textAlign={'center'}>{QA.score}</Td>
                                        <Td textAlign={'center'}>
                                            <Badge borderRadius={"5px"} color={"white"} bg={QA.status === 1 ? '#8a6dce' : '#ff9fb8'} width={'75px'}>
                                                {QA.status === 1 ? 'Active' : 'Inactive'}
                                            </Badge>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <QAButtons
                                                handleUpdate={handleUpdate}
                                                QA={QA}
                                                deleteQuestion={deleteQuestion}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                            <Box mt={4} textAlign={'center'}>
                                <Text fontSize={'16px'} fontWeight={'bold'} color={"#1c2147"}>
                                    Pts possible: {QA.reduce((acc, item) => acc + (item.score || 0), 0)}
                                </Text>
                            </Box>
                        </>
                    ) : (
                        <Tr>
                            <Th colSpan={'9'}>
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>

            </Table>
        </Flex >
    );
}
export default QAsTableEndicott;
