import React, { useState, useEffect } from 'react';
import {
    TableContainer,
} from '@chakra-ui/react'
import axios from 'axios';
import FiltersFinancialReport from '../filters';
import FinancialReportMX from './table';
import ModalFinanceBox from '../financeBox';
import ModalWeeklyCash from '../weeklycash';


function UseFinancialMX() {

    const [reportsD, setReportsD] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [categories, setCategories] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [categorySearch, setCategorySearch] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBar, setIsLoadingBar] = useState(false);
    const [financeBox, setFinanceBox] = useState([]);
    const [modalFinanceBox, setModalFinanceBox] = useState(false);
    const [modalWeeklyCash, setModalWeeklyCash] = useState(false);

    const optionCategories = categories.map(categories => ({
        value: categories.id,
        label: `${categories.name}`,
    }));

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const clearFilters = () => {
        setCategorySearch(null);
        setStartDateFilter('');
        setEndDateFilter('');
        setResetKey(resetKey + 1);
        setCurrentPage(1);
    }

    const fetchData = async () => {

        try {

            setIsLoading(false);
            setReportsD([]);

            const response = await axios.get(`/financial/report/MX?page=${currentPage}` +
                `${categorySearch ? `&category=${encodeURIComponent(categorySearch)}` : ''}` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`
            );
            const responseData = response.data;
            setReportsD(responseData.data.data);
            setCategories(responseData.categories);
            setFinanceBox(responseData.financeBox?.[0] || { id: '---', total_mx: 0, comment: '', date: '---' });
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
            setIsLoading(true);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, categorySearch, startDateFilter, endDateFilter, resetKey]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '20px',
            fontSize: '16px',
            height: '40px',
            width: '220px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'category':
                setCategorySearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleDownloadExcel = async () => {
        try {
            // Construir los parámetros de la URL dinámicamente
            const params = new URLSearchParams();

            if (categorySearch) {
                params.append('category', categorySearch);
            }
            if (startDateFilter) {
                params.append('startDate', startDateFilter);
            }
            if (endDateFilter) {
                params.append('endDate', endDateFilter);
            }

            const startDate = startDateFilter || '';
            const endDate = endDateFilter || '';

            const url = `/download/financial/report/MX?${params.toString()}`;

            setIsLoadingBar(true);

            // Realizar la petición utilizando async/await
            const response = await axios.get(url, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `FinancialReportMX${startDate}_${endDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl); // Liberar el objeto URL
            setIsLoadingBar(false);
        } catch (error) {
            console.error('Error al descargar el archivo Excel:', error);
        } finally {
            setIsLoadingBar(false);
        }
    };

    const handleFinanceBox = () => {
        setModalFinanceBox(true);
    }

    const handleWeeklyCash = () => {
        setModalWeeklyCash(true);
    }

    return (
        <TableContainer>
            <FiltersFinancialReport
                handleDownloadExcel={handleDownloadExcel}
                optionCategories={optionCategories}
                categorySearch={categorySearch}
                handleSelectChangeF={handleSelectChangeF}
                setCurrentPage={setCurrentPage}
                customStyles={customStyles}
                resetKey={resetKey}
                filterOptions={filterOptions}
                startDateFilter={startDateFilter}
                setStartDateFilter={setStartDateFilter}
                endDateFilter={endDateFilter}
                setEndDateFilter={setEndDateFilter}
                clearFilters={clearFilters}
                isLoadingBar={isLoadingBar}
            />
            <FinancialReportMX
                setCurrentPage={setCurrentPage}
                reportsD={reportsD}
                isLoading={isLoading}
                total={total}
                currentPage={currentPage}
                totalPages={totalPages}
                financeBox={financeBox}
                handleFinanceBox={handleFinanceBox}
                handleWeeklyCash={handleWeeklyCash}
            />
            {modalFinanceBox && (
                <ModalFinanceBox
                    isOpen={modalFinanceBox}
                    onClose={() => setModalFinanceBox(false)}
                    location={1}
                    data={fetchData}
                    financeBox={financeBox}
                />
            )}
            {modalWeeklyCash && (
                <ModalWeeklyCash
                    isOpen={modalWeeklyCash}
                    onClose={() => setModalWeeklyCash(false)}
                    location={1}
                    data={fetchData}
                    financeBox={financeBox}
                />
            )}
        </TableContainer>
    );
}
export default UseFinancialMX; 