import axios from 'axios';
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {
    Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
    Box, Flex,
    Text, Input
} from '@chakra-ui/react';
import UpdateDefault from '../itemsUpdate/updateDefault';
import UpdateEndicott from '../itemsUpdate/updateEndicott';
import UpdateAnswerPro from '../itemsUpdate/updateAnswerPro';

function UpdateQuestionsCall({ isOpen, onClose, call, data, dataName, date }) {

    const [questions, setQuestions] = useState([]);
    const [possibleScore, setPossibleScore] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [comment, setComment] = useState(call.comment || '');
    const [account, setAccount] = useState(call.account || '');
    const [error_comment, setErrorComment] = useState(call.error_comment || '');
    const [error1, setError1] = useState(call.error_1 || '');
    const [error2, setError2] = useState(call.error_2 || '');
    const [error3, setError3] = useState(call.error_3 || '');

    const fetchData = async () => {
        try {
            const response = await axios.get(`/getQuestions/${call.id}`);
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleStatusChange = (e, id) => {
        const newStatus = e.target.value;
        const updatedQuestions = questions.map((item) => {
            if (item.id === id) {
                let newScore = item.score;

                if (newStatus === 'X') {
                    // Almacena el valor original si no existe
                    if (!item.originalScore) {
                        item.originalScore = item.score;
                    }
                    newScore = 'N/A'; // Cambiar a "N/A"
                } else if (newStatus === 'N') {
                    // Almacena el valor original si no existe
                    if (!item.originalScore) {
                        item.originalScore = item.score;
                    }
                    newScore = '0'; // Cambiar a "0"
                } else if (newStatus === 'Y') {
                    // Restaurar el valor original del score
                    newScore = item.originalScore || item.score;
                }

                return { ...item, status: newStatus, score: newScore };
            }
            return item;
        });

        setQuestions(updatedQuestions);
    };


    const handleScoreChange = (e, id) => {
        const newScore = e.target.value;
        const updatedQuestions = questions.map((item) => {
            if (item.id === id) {
                if (item.status === 'N' && newScore !== '0') {
                    return item; // No permite valores distintos de "0" si el status es "N"
                }
                if (item.status === 'X') {
                    return item; // No permite cambios si el status es "N/A"
                }
                return { ...item, score: newScore }; // Actualiza el score normalmente
            }
            return item;
        });
        setQuestions(updatedQuestions);
    };

    const calculateScores = () => {
        let possibleScore = 0; // Para la suma de `question.question.score`
        let totalScore = 0;    // Para la suma de `question.score`

        questions.forEach((item) => {
            // Si el status es "X", no se cuenta en ninguna suma
            if (item.status === 'X') {
                return;
            }

            // Agregar al score posible solo si no es "X"
            possibleScore += item.question.score || 0;

            // Agregar al score total solo si el status no es "N/A"
            if (item.status !== 'X') {
                totalScore += parseFloat(item.score) || 0;
            }
        });

        return { possibleScore, totalScore };
    };

    useEffect(() => {
        const { possibleScore, totalScore } = calculateScores();
        setPossibleScore(possibleScore);
        setTotalScore(totalScore);
    }, [questions]);


    const saveChanges = async () => {
        try {
            const response = await axios.post(`/updateQuestions/${call.id}`, {
                pts_possible: possibleScore,
                total_score: totalScore,
                comment: comment,
                account: account,
                error_comment: error_comment,
                error_1: error1,
                error_2: error2,
                error_3: error3,
                data: questions,
            });
            data();
            onClose();
            Swal.fire('Success', 'Changes saved successfully!', 'success');
        } catch (error) {
            console.error('Error saving changes:', error);
            Swal.fire('Error', 'Failed to save changes. Please try again.', 'error');
        }
    };

    const handleCommentChange = (e) => {
        const value = e.target.value;
        setComment(value);
    };

    const handleErrorCommentChange = (e) => {
        const value = e.target.value;
        setErrorComment(value);
    };

    const handleError1Change = (e) => {
        const value = e.target.value;
        setError1(value);
    };

    const handleError2Change = (e) => {
        const value = e.target.value;
        setError2(value);
    };

    const handleError3Change = (e) => {
        const value = e.target.value;
        setError3(value);
    };

    const handleAccountChange = (e) => {
        const value = e.target.value;
        setAccount(value);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'default'} isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent borderRadius={'20px'} width={"45%"}>

                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"} mt={'5%'}>
                    <Flex direction={'column'}>
                        <Text fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'center'}>
                            Edit Questions
                        </Text>

                        <Flex color={'#7080fc'} fontSize={'24px'} justifyContent={'center'} marginBottom={'5%'}>
                            <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>

                                <Text mb='5%'>
                                    {dataName.name.split(' ')[0]} {dataName.last_name.split(' ')[0]}
                                </Text>
                            </span>
                            <span style={{ marginRight: '8px', color: '#808080', fontStyle: 'italic' }}>

                                <Text mb='5%'>
                                    {date.date}
                                </Text>
                            </span>
                        </Flex>

                        {call.id_client === 19 ? (
                            <>
                                <Flex direction={'column'}>
                                    <Flex justifyContent={'end'}>
                                        <Flex mb="1%" alignItems="center" justifyContent={'center'} w={'40%'}>
                                            <Text fontSize="lg" mr="4">Account:</Text>
                                            <Input
                                                type="text"
                                                value={account}
                                                onChange={handleAccountChange}
                                                width="100%"
                                                placeholder="Account"
                                            />
                                        </Flex>
                                    </Flex>

                                    <UpdateEndicott
                                        saveChanges={saveChanges}
                                        onClose={onClose}
                                        totalScore={totalScore}
                                        possibleScore={possibleScore}
                                        handleScoreChange={handleScoreChange}
                                        handleStatusChange={handleStatusChange}
                                        handleCommentChange={handleCommentChange}
                                        handleAccountChange={handleAccountChange}
                                        comment={comment}
                                        account={account}
                                        questions={questions}
                                    />
                                </Flex>

                            </>

                        ) : call.id_client === 38 ? (
                            <>
                                <Flex direction={'column'}>
                                    <Flex justifyContent={'end'}>
                                        <Flex mb="1%" alignItems="center" justifyContent={'center'} w={'40%'}>
                                            <Text fontSize="lg" mr="4">Account:</Text>
                                            <Input
                                                type="text"
                                                value={account}
                                                onChange={handleAccountChange}
                                                width="100%"
                                                placeholder="Account"
                                            />
                                        </Flex>
                                    </Flex>

                                    <UpdateAnswerPro
                                        saveChanges={saveChanges}
                                        onClose={onClose}
                                        totalScore={totalScore}
                                        possibleScore={possibleScore}
                                        handleScoreChange={handleScoreChange}
                                        handleStatusChange={handleStatusChange}
                                        handleCommentChange={handleCommentChange}
                                        handleErrorCommentChange={handleErrorCommentChange}
                                        handleError1Change={handleError1Change}
                                        handleError2Change={handleError2Change}
                                        handleError3Change={handleError3Change}
                                        handleAccountChange={handleAccountChange}
                                        comment={comment}
                                        account={account}
                                        error_comment={error_comment}
                                        error1={error1}
                                        error2={error2}
                                        error3={error3}
                                        questions={questions}
                                    />
                                </Flex>

                            </>

                        ) : (
                            <>
                                <Flex direction={'column'}>
                                    <Flex justifyContent={'end'}>
                                        <Flex mb="1%" alignItems="center" justifyContent={'center'} w={'40%'}>
                                            <Text fontSize="lg" mr="4">Account:</Text>
                                            <Input
                                                type="text"
                                                value={account}
                                                onChange={handleAccountChange}
                                                width="100%"
                                                placeholder="Account"
                                            />
                                        </Flex>
                                    </Flex>
                                    <UpdateDefault
                                        saveChanges={saveChanges}
                                        onClose={onClose}
                                        totalScore={totalScore}
                                        possibleScore={possibleScore}
                                        handleScoreChange={handleScoreChange}
                                        handleStatusChange={handleStatusChange}
                                        handleCommentChange={handleCommentChange}
                                        handleAccountChange={handleAccountChange}
                                        comment={comment}
                                        account={account}
                                        questions={questions}
                                    />
                                </Flex>
                            </>

                        )}

                    </Flex>

                </ModalBody >

            </ModalContent >
        </Modal >
    );
}




export default UpdateQuestionsCall;
