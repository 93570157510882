import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
    Box, Flex, Text, Button, Input, InputAddon, useToast, InputGroup
} from '@chakra-ui/react';
import FormatEndicott from './itemsQA/formatEndicott';
import FormatDefault from './itemsQA/formatDefault';
import FormatAnswerPro from './itemsQA/formatAnswerPro';

const ModalQA = ({ isOpen, onClose, schedule }) => {
    const toast = useToast();
    const [format, setFormat] = useState([]);
    const [number, setNumber] = useState(''); // Estado para el número de formatos
    const [inputDisabled, setInputDisabled] = useState(false); // Estado para deshabilitar el input
    const [selectQA, setSelectQA] = useState(null);
    const [callFormats, setCallFormats] = useState([]); // Array para almacenar los formatos de cada llamada
    const isEndicott = [19].includes(schedule.id_client); // Condición para usar FormatEndicott
    const isAnswerPro = [38].includes(schedule.id_client); // Condición para usar FormatEndicott
    const [currentCallIndex, setCurrentCallIndex] = useState(0); // Índice de la llamada visible actualmente
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); // Estado para manejar errores de validación
    const [formatsGenerated, setFormatsGenerated] = useState(false);
    const [isCreationActive, setIsCreationActive] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const clientId = schedule.id_client;
            const response = await axios.get(`/indexQAFormatsClientScore/${clientId}`);
            const responseData = response.data;
            setFormat(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleNextCall = () => {
        setCurrentCallIndex((prevIndex) => (prevIndex + 1) % callFormats.length);
    };

    const handlePrevCall = () => {
        setCurrentCallIndex((prevIndex) => (prevIndex - 1 + callFormats.length) % callFormats.length);
    };

    const getRowBackground = (QA) => {
        if (selectQA && selectQA.id === QA.id) {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    const generateCallFormats = (validNumber) => {
        setCallFormats((prevCallFormats) => {
            const currentLength = prevCallFormats.length;

            if (validNumber > currentLength) {
                // Agregar nuevos elementos si el número es mayor
                const additionalCallFormats = Array.from(
                    { length: validNumber - currentLength },
                    () => ({
                        duration: "",
                        totalScore: 0,
                        possibleScore: 0,
                        comment: "",
                        account: "",
                        error_comment: "",
                        error_1: "",
                        error_2: "",
                        error_3: "",
                        questions: format.map((f) => ({ ...f })),
                    })
                );
                return [...prevCallFormats, ...additionalCallFormats];
            } else if (validNumber < currentLength) {
                // Cortar el array si el número es menor
                return prevCallFormats.slice(0, validNumber);
            }

            // Si el número es igual, no hacer nada
            return prevCallFormats;
        });
    };


    const handleIncrement = () => {
        const currentNumber = parseInt(number, 10) || 0;
        if (currentNumber < 12) {
            const newValue = currentNumber + 1;
            setNumber(newValue.toString());
            generateCallFormats(newValue);
            if (newValue !== 1) {
                setInputDisabled(true);
            } else {
                setInputDisabled(false);
            }
        }
    };

    const handleDecrement = () => {
        const currentNumber = parseInt(number, 10) || 0;
        if (currentNumber > 1) {
            const newValue = currentNumber - 1;
            setNumber(newValue.toString());
            generateCallFormats(newValue);
            if (newValue !== 1) {
                setInputDisabled(true);
            } else {
                setInputDisabled(false);
            }
        }
    };

    const handleNumberChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setNumber(""); // Permitir que el input esté vacío temporalmente
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 12) {
                setNumber(parsedValue); // Solo actualizar el número
            }
        }
    };

    const updateDuration = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                duration: value, // Actualiza solo la duración
            };
            return updatedCallFormats;
        });
    };

    const updateAccount = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                account: value,
            };
            return updatedCallFormats;
        });
    };

    const updateComment = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                comment: value,
            };
            return updatedCallFormats;
        });
    };

    const updateErrorComment = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                error_comment: value,
            };
            return updatedCallFormats;
        });
    };

    const updateError1 = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                error_1: value,
            };
            return updatedCallFormats;
        });
    };

    const updateError2 = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                error_2: value,
            };
            return updatedCallFormats;
        });
    };

    const updateError3 = (index, value) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                error_3: value,
            };
            return updatedCallFormats;
        });
    };

    const updateCallFormat = (index, updatedQuestions) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                questions: updatedQuestions, // Actualiza solo las preguntas
            };
            return updatedCallFormats;
        });
    };

    const createFormats = () => {
        if (!number || number < 1 || number > 12) {
            toast({
                title: "Invalid input",
                description: "Please enter a number between 1 and 12.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        generateCallFormats(parseInt(number, 10));
        setFormatsGenerated(true);
        setIsCreationActive(true); // Activar los botones después de crear los formatos

        toast({
            title: 'Formats Generated',
            description: `Successfully generated ${number} call formats.`,
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleSubmit = async () => {
        const formSubmitData = {
            number: callFormats.length,
            operator: schedule.id_operator,
            client: schedule.id_client,
            date: schedule.date,
            data: callFormats.map((call) => ({
                duration: call.duration, // Incluye duración
                account: call.account,
                final_score: call.totalScore, // Incluye puntaje total
                possible_score: call.possibleScore, // Incluye puntaje
                comment: call.comment,
                error_comment: call.error_comment,
                error_1: call.error_1,
                error_2: call.error_2,
                error_3: call.error_3,
                questions: call.questions.map((q) => ({
                    id: q.id,
                    editableScore: q.editableScore || 0,
                    selectedOption: q.selectedOption || "Y",
                })),
            })),
        };

        try {
            const response = await axios.post('/qaCalls', formSubmitData);
            toast({
                title: 'QA Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            toast({
                title: "Error Creating QA",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating QA:', error);
        }
    };


    const calculateTotalScore = (questions) => {
        return questions
            .filter((q) => q.selectedOption !== "X" && q.selectedOption !== "N")
            .reduce((total, q) => total + (parseFloat(q.editableScore || 0) || 0), 0);
    };

    const updateTotalScore = (index) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            const updatedTotalScore = calculateTotalScore(updatedCallFormats[index].questions);
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                totalScore: updatedTotalScore, // Actualiza solo el totalScore
            };
            return updatedCallFormats;
        });
    };

    const calculatePosibleScore = (questions) => {
        return questions
            .filter(item => item.selectedOption !== "X") // Excluir elementos con opción "X"
            .reduce((acc, item) => acc + (item.score || 0), 0);
    };

    const updatePossibleScore = (index) => {
        setCallFormats((prev) => {
            const updatedCallFormats = [...prev];
            const updatedPossibleScore = calculatePosibleScore(updatedCallFormats[index].questions);
            updatedCallFormats[index] = {
                ...updatedCallFormats[index],
                possibleScore: updatedPossibleScore, // Actualiza solo el totalScore
            };
            return updatedCallFormats;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={'Default'}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent borderRadius={'5px'} width={"50%"}>
                <ModalCloseButton bg={"#cccccc"} _hover={{ background: '#fc7466' }} />
                <ModalBody display={"flex"} flexDirection={"row"}>
                    <Flex width={"100%"}>
                        <Box
                            bg={"white"}
                            overflowY={'auto'}
                            overflowX={'auto'}
                            width={'100%'}
                        >
                            <Flex fontSize={"16px"} color={'#b3b3b3'} pl={'2%'} mb={'2%'} direction={'column'}>
                                <Text mt={'40px'} fontSize={"50px"} color={'#1c2147'} fontWeight={"bold"} textAlign={'center'}>
                                    QA Call Log Form
                                </Text>
                                <Flex color={'#7080fc'} fontSize={'24px'} justifyContent={'center'}>
                                    <span style={{ marginRight: '8px', color: '#7080fc', fontWeight: 'bold' }}>
                                        {schedule.operator} {schedule.operatorL}
                                    </span>
                                    <span style={{ marginRight: '8px', color: '#808080', fontStyle: 'italic' }}>
                                        {schedule.date}
                                    </span>
                                </Flex>
                            </Flex>

                            {/* Input para el número de formatos */}
                            <Flex pl="2%" mb="2%" alignItems="center" justifyContent={'center'}>
                                <Button
                                    mr="4"
                                    bg={'#fc7466'}
                                    color='white'
                                    borderRadius={'5px'}
                                    _hover={{ background: "#808080", color: 'white' }}
                                    onClick={handleDecrement}
                                    isDisabled={!isCreationActive || number === "" || parseInt(number, 10) <= 1} // Agregar condición para isCreationActive
                                >
                                    -1
                                </Button>
                                <InputGroup attached w={'20%'}>
                                    <InputAddon>Calls:</InputAddon>
                                    <Input
                                        type="number"
                                        value={number}
                                        onChange={handleNumberChange}
                                        min={1}
                                        max={12}
                                        width="70%"
                                        disabled={inputDisabled} // Deshabilitar el input si es necesario
                                        placeholder="1-12"
                                    />
                                </InputGroup>
                                <Button
                                    ml="4"
                                    bg={'#a2bd34'}
                                    color='white'
                                    _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'5px'}
                                    onClick={handleIncrement}
                                    isDisabled={!isCreationActive || number === "" || parseInt(number, 10) >= 12} // Agregar condición para isCreationActive
                                >
                                    +1
                                </Button>

                            </Flex>

                            {/* Mostrar mensaje de error si existe */}
                            {error && (
                                <Text color="red.500" ml="2%" mb="2%">
                                    {error}
                                </Text>
                            )}

                            {/* Mostrar solo el formato actual */}
                            {number && parseInt(number, 10) > 0 && callFormats.length > 0 ? (
                                isEndicott ? (
                                    <>
                                        <Flex direction={'column'} display={'flex'}>
                                            <FormatEndicott
                                                duration={callFormats[currentCallIndex]?.duration || ""}
                                                setDuration={(value) => updateDuration(currentCallIndex, value)} // Actualizar duración
                                                key={currentCallIndex}
                                                getRowBackground={getRowBackground}
                                                format={callFormats[currentCallIndex]?.questions || []} // Formato específico
                                                number={parseInt(number, 10)}
                                                onNext={handleNextCall} // Botón siguiente
                                                onPrev={handlePrevCall} // Botón anterior
                                                currentIndex={currentCallIndex} // Índice actual
                                                updateFormat={(updatedQuestions) => updateCallFormat(currentCallIndex, updatedQuestions)} // Actualizar la llamada actual
                                                totalScore={callFormats[currentCallIndex]?.totalScore || 0}
                                                setTotalScore={() => updateTotalScore(currentCallIndex)}
                                                comment={callFormats[currentCallIndex]?.comment || ""}
                                                setComment={(value) => updateComment(currentCallIndex, value)}
                                                possibleScore={callFormats[currentCallIndex]?.possibleScore || 0}
                                                setPossibleScore={() => updatePossibleScore(currentCallIndex)}
                                                account={callFormats[currentCallIndex]?.account || ""}
                                                setAccount={(value) => updateAccount(currentCallIndex, value)}
                                            />
                                            <Flex justifyContent={'right'} mt={'1%'}>
                                                {currentCallIndex === callFormats.length - 1 && (
                                                    <Button
                                                        onClick={handleSubmit}
                                                        w={'10%'}
                                                        mr={'2%'}
                                                        borderRadius={"5px"}
                                                        alignItems="center"
                                                        bg={'#3e4a9e'}
                                                        color='white'
                                                        _hover={{ background: "#808080", color: 'white' }}
                                                    >
                                                        Complete
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </>
                                ) : isAnswerPro ? (
                                    <>
                                        <Flex direction={'column'} display={'flex'}>
                                            <FormatAnswerPro
                                                duration={callFormats[currentCallIndex]?.duration || ""}
                                                setDuration={(value) => updateDuration(currentCallIndex, value)} // Actualizar duración
                                                key={currentCallIndex}
                                                getRowBackground={getRowBackground}
                                                format={callFormats[currentCallIndex]?.questions || []} // Formato específico
                                                number={parseInt(number, 10)}
                                                onNext={handleNextCall} // Botón siguiente
                                                onPrev={handlePrevCall} // Botón anterior
                                                currentIndex={currentCallIndex} // Índice actual
                                                updateFormat={(updatedQuestions) => updateCallFormat(currentCallIndex, updatedQuestions)} // Actualizar la llamada actual
                                                totalScore={callFormats[currentCallIndex]?.totalScore || 0}
                                                setTotalScore={() => updateTotalScore(currentCallIndex)}
                                                comment={callFormats[currentCallIndex]?.comment || ""}
                                                setComment={(value) => updateComment(currentCallIndex, value)}
                                                error_comment={callFormats[currentCallIndex]?.error_comment || ""}
                                                setErrorComment={(value) => updateErrorComment(currentCallIndex, value)}
                                                error_1={callFormats[currentCallIndex]?.error_1 || ""}
                                                setError_1={(value) => updateError1(currentCallIndex, value)}
                                                error_2={callFormats[currentCallIndex]?.error_2 || ""}
                                                setError_2={(value) => updateError2(currentCallIndex, value)}
                                                error_3={callFormats[currentCallIndex]?.error_3 || ""}
                                                setError_3={(value) => updateError3(currentCallIndex, value)}
                                                possibleScore={callFormats[currentCallIndex]?.possibleScore || 0}
                                                setPossibleScore={() => updatePossibleScore(currentCallIndex)}
                                                account={callFormats[currentCallIndex]?.account || ""}
                                                setAccount={(value) => updateAccount(currentCallIndex, value)}
                                            />
                                            <Flex justifyContent={'right'} mt={'1%'}>
                                                {currentCallIndex === callFormats.length - 1 && (
                                                    <Button
                                                        onClick={handleSubmit}
                                                        w={'10%'}
                                                        mr={'2%'}
                                                        borderRadius={"5px"}
                                                        alignItems="center"
                                                        bg={'#3e4a9e'}
                                                        color='white'
                                                        _hover={{ background: "#808080", color: 'white' }}
                                                    >
                                                        Complete
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </>
                                ) : (
                                    <>
                                        <Flex direction={'column'} display={'flex'}>
                                            <FormatDefault
                                                duration={callFormats[currentCallIndex]?.duration || ""}
                                                setDuration={(value) => updateDuration(currentCallIndex, value)} // Actualizar duración
                                                key={currentCallIndex}
                                                getRowBackground={getRowBackground}
                                                format={callFormats[currentCallIndex]?.questions || []}  // Formato específico
                                                number={parseInt(number, 10)}
                                                updateFormat={(updatedQuestions) => updateCallFormat(currentCallIndex, updatedQuestions)}  // Actualizar la llamada actual
                                                onNext={handleNextCall} // Botón siguiente
                                                onPrev={handlePrevCall} // Botón anterior
                                                currentIndex={currentCallIndex} // Índice actual
                                                totalScore={callFormats[currentCallIndex]?.totalScore || 0} // Total específico
                                                setTotalScore={() => updateTotalScore(currentCallIndex)} // Recalcula el total
                                                comment={callFormats[currentCallIndex]?.comment || ""}
                                                setComment={(value) => updateComment(currentCallIndex, value)}
                                                possibleScore={callFormats[currentCallIndex]?.possibleScore || 0}
                                                setPossibleScore={() => updatePossibleScore(currentCallIndex)}
                                                account={callFormats[currentCallIndex]?.account || ""}
                                                setAccount={(value) => updateAccount(currentCallIndex, value)}
                                            />
                                            <Flex justifyContent={'right'} mt={'1%'}>
                                                {currentCallIndex === callFormats.length - 1 && (
                                                    <Button
                                                        onClick={handleSubmit}
                                                        w={'10%'}
                                                        mr={'2%'}
                                                        borderRadius={"5px"}
                                                        alignItems="center"
                                                        bg={'#3e4a9e'}
                                                        color='white'
                                                        _hover={{ background: "#808080", color: 'white' }}
                                                    >
                                                        Complete
                                                    </Button>
                                                )}
                                            </Flex>
                                        </Flex>
                                    </>
                                )
                            ) : (
                                <Text textAlign="center" fontSize="lg" color="gray.500" mt={4} mb={'2%'}>
                                    Please enter a valid calling number to display. Between 1-12.
                                </Text>
                            )}
                        </Box>
                    </Flex>
                </ModalBody>
                {/* Botón de envío */}
                <Flex alignSelf={'center'} mb="4%">
                    {!formatsGenerated && (
                        <Button
                            ml="4"
                            bg={'#7080fc'}
                            color='white'
                            borderRadius={'5px'}
                            _hover={{ background: "#808080", color: 'white' }}
                            onClick={createFormats}
                        >
                            Create
                        </Button>
                    )}
                </Flex>
            </ModalContent >
        </Modal >
    );
}

export default ModalQA;
