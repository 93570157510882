import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Textarea, Button, Input,
    Select, Flex,
    FormControl, FormErrorMessage,
    useToast
}
    from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";

function FinancialRequest({ isOpen, onClose, userAuth }) {

    const [errorMsg, setErrorMsg] = useState("");
    const toast = useToast();
    const [documentFile, setDocumentFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [formData, setFormData] = useState({
        amount_bz: '',
        amount_mx: '',
        status: 1,
        reason: '',
        payment_method: '',
    });

    const handleSubmit = async () => {


        const formDataSend = new FormData();
        formDataSend.append('amount_bz', formData.amount_bz);
        formDataSend.append('amount_mx', formData.amount_mx);
        formDataSend.append('status', formData.status);
        formDataSend.append('reason', formData.reason);
        formDataSend.append('payment_method', formData.payment_method);


        // Agrega el archivo de documento si existe
        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {

            const response = await axios.post('/financialRequest/send', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                toast({
                    title: 'Ticket finances send successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Creating a Ticket",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating schedule:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };


    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'}>
                    <ModalHeader textAlign="center" color={'#808080'} mt={'50px'} fontSize={'40px'}>
                        Internal Funding Request
                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Flex display='flex' justifyContent="center" ml={'10%'}>
                                <FormControl isInvalid={!!formErrors.reason} mr={'5%'}>
                                    <Text mb={'5%'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Reason:
                                    </Text>
                                    <Textarea
                                        bg={'white'}
                                        mr={4}
                                        width={'100%'}
                                        placeholder='Describe the reason for your request'
                                        value={formData.reason}
                                        onChange={handleChange}
                                        name='reason'
                                    />
                                    {formErrors.reason && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.reason}</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.payment_method} >
                                    <Text mb={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                        Payment method:
                                    </Text>
                                    <Select
                                        w="75%"
                                        fontSize={"md"}
                                        borderRadius={"10px"}
                                        name="payment_method"
                                        textAlign={"center"}
                                        background="white"
                                        value={formData.payment_method}
                                        onChange={handleChange}
                                        h="44px"
                                        maxh="44px"
                                        errorBorderColor="#fc7466"
                                    >
                                        <option value="">Select Method</option>
                                        <option value="1">Effective</option>
                                        <option value="2">Transfer</option>
                                        <option value="3">Credit card</option>
                                    </Select>
                                    {formErrors.payment_method && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.payment_method}</FormErrorMessage>
                                    )}
                                </FormControl>

                            </Flex>

                            <Flex display='flex' justifyContent="center" mt={'5%'}>

                                <FormControl
                                    isInvalid={
                                        !!formErrors.amount_mx || !!formErrors.amount_bz
                                    }
                                    textAlign={'center'}
                                    w={'40%'}
                                >
                                    <Text mt={'5px'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Total cost:
                                    </Text>
                                    {[2, 3].includes(userAuth.location_id) ? (
                                        <>
                                            <Input
                                                type="number"
                                                bg={'#f2f2f2'}
                                                width={'250px'}
                                                color={'#808080'}
                                                textAlign={'center'}
                                                value={formData.amount_bz}
                                                onChange={handleChange}
                                                name="amount_bz"
                                                mr={'5%'}
                                            />
                                            {formErrors.amount_bz && (
                                                <FormErrorMessage justifyContent={'center'}>
                                                    {formErrors.amount_bz}
                                                </FormErrorMessage>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Input
                                                type="number"
                                                bg={'#f2f2f2'}
                                                width={'250px'}
                                                color={'#808080'}
                                                textAlign={'center'}
                                                value={formData.amount_mx}
                                                onChange={handleChange}
                                                name="amount_mx"
                                                mr={'5%'}
                                            />
                                            {formErrors.amount_mx && (
                                                <FormErrorMessage justifyContent={'center'}>
                                                    {formErrors.amount_mx}
                                                </FormErrorMessage>
                                            )}
                                        </>
                                    )}
                                </FormControl>




                                <Text mt={'5px'} color={'#1c2147'} fontWeight={'bold'} ml={'5%'} mr={'5%'}>
                                    Attachment:
                                </Text>
                                <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                    accept="application/pdf" />
                                <Button as="label" htmlFor="document" cursor="pointer"
                                    bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                    color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'50%'} height={'50px'}
                                    width={"50px"}
                                >
                                    <ImAttachment ml={2} size={'35px'} />
                                </Button>
                            </Flex>

                            <Flex display={'flex'} justifyContent={'right'}>
                                {documentFile && (
                                    <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                        mr={'5%'} position={"relative"}
                                        mt={'2%'}
                                    >
                                        <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                        <Text>
                                            {documentFile.name.length > 20
                                                ? `${documentFile.name.substring(0, 20)}…`
                                                : documentFile.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#b9ce67"}
                                    color={"white"}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={handleSubmit}
                                    mr={'30px'}
                                >
                                    <Text mr={'5px'}>
                                        Send
                                    </Text>
                                    <IoMdSend />
                                </Button>

                                <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={onClose}>
                                    <CloseIcon mr={2} />  Cancel
                                </Button>
                            </Flex>
                        </Flex>



                    </ModalBody>
                </ModalContent>
            </Modal >
        </Flex>
    );
}

export default FinancialRequest;
