import Select2 from 'react-select'
import {
    Button, Progress,
    Flex, Box, Select, Text
} from '@chakra-ui/react'
import { AiOutlineClear } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { TbArrowsUpDown } from "react-icons/tb";


function QATotalsFilters({ setCurrentPage, clearFilters, setMonthSearch, monthSearch, yearSearch, resetKey, customStyles, handleSelectYear, isLoadingBar, handleDownloadExcel, toggleOrder, order, optionClients, clientSearch, optionsUsers, filterOptions, agentSearch }) {

    const years = [];
    for (let i = 2025; i <= 2100; i++) {
        years.push(i);
    }

    const optionsYears = years.map(years => ({
        value: years,
        label: years,
    }));

    return (

        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            p={2}
            borderRadius="16px"
        >
            <Flex
                direction='row' justify={"right"}
                w='100%'
                p={2}
                borderRadius="16px"
            >
                <Flex align="center" mr={'2%'}>
                    <Button class="buttonCreate" onClick={handleDownloadExcel}>
                        <Text mr={2}>
                            Export
                        </Text>
                        <RiFileExcel2Fill />
                    </Button>
                </Flex>
            </Flex>
            <Flex flexDirection={"column"} bg="gray.200" p={2} borderRadius={"16px"} >
                <Flex align="center"    >
                    <Select
                        borderRadius={"5px"}
                        borderColor={'#808080'}
                        color={'#808080'}
                        _focusVisible={'none'}
                        _hover={{
                            borderColor: "#808080",
                        }}
                        width={'200px'}
                        ml={4}
                        value={monthSearch}
                        onChange={(e) => {
                            setMonthSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                    >
                        <option value="">Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </Select>

                    <Select2
                        options={optionsYears}
                        value={optionsYears.find(option => option.value === yearSearch)}
                        onChange={(selectedOption) => {
                            handleSelectYear('year', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Year"
                        key={`yearSelect-${resetKey}`}
                    />

                    <Select2
                        options={optionClients}
                        value={optionClients.find(option => option.value === clientSearch)}
                        onChange={(selectedOption) => {
                            handleSelectYear('client', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        placeholder="Select client"
                        key={`clientSelect-${resetKey}`}
                    />

                    <Select2
                        options={optionsUsers}
                        value={optionsUsers.find(option => option.value === agentSearch)}
                        onChange={(selectedOption) => {
                            handleSelectYear('agent', selectedOption);
                            setCurrentPage(1);
                        }}
                        styles={customStyles}
                        isSearchable
                        filterOption={filterOptions}
                        placeholder="Select agent"
                        key={`agentSelect-${resetKey}`}
                    />

                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Box>

                    <Box>
                        <Button onClick={toggleOrder} color={"white"} width={"max-content"} bg={'#7a86e6'} ml={8} borderRadius={'5px'} _hover={{ background: "#808080", color: 'white' }}>
                            <Box mr={'10px'}>
                                <TbArrowsUpDown />
                            </Box>
                            {order === 'desc' ? 'Descending Order' : 'Ascending Order'}
                        </Button>
                    </Box>
                </Flex>

            </Flex>

            {!isLoadingBar ? (
                null
            ) : (
                <Flex width={'100%'} direction={'column'} mb={'2%'} alignItems={'center'} mt={'1%'}>
                    <Text fontWeight={'bold'} color={'#1c2147'}>
                        Generating file, please wait...
                    </Text>
                    <Progress size='xs' isIndeterminate width={'400px'} mb={'1%'} />
                </Flex>
            )}


        </Flex>
    );
}
export default QATotalsFilters; 