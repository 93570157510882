import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select2 from 'react-select'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box, Flex, SimpleGrid, FormControl, FormErrorMessage, useToast, Select, Text, Textarea, VStack
} from '@chakra-ui/react';
import { CloseIcon, AddIcon, DownloadIcon } from '@chakra-ui/icons'
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";

const FinancialRequestUpdate = ({ isOpen, onClose, optionCategories, filterOptions, data, financialRequest, financeBox }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [selectedCategory, setSelectCategory] = useState(financialRequest?.id_category || '');
    const [resetKey, setResetKey] = useState(0);
    const [documentFile, setDocumentFile] = useState(null);
    const [attachment, setAttachment] = useState([]);
    const [hoveredMessage, setHoveredMessage] = useState(null);

    const [formData, setFormData] = useState({
        id_category: financialRequest?.id_category || '',
        id_location: financialRequest?.id_location?.toString() || '',
        description: '',
        amount_mx: financialRequest?.amount_mx || '',
        amount_bz: financialRequest?.amount_bz || '',
        amount_us: financialRequest?.amount_us || '',
        payment_method: financialRequest?.payment_method?.toString() || '',
        status: '',
    });

    const clearForm = () => {
        setFormData({
            id_category: '',
            id_location: '',
            description: '',
            amount_mx: '',
            amount_bz: '',
            amount_us: '',
            payment_method: '',
            status: ''
        });
        setFormErrors({});
        setResetKey(resetKey + 1);
        setSelectCategory([]);
    };

    const handleSubmit = async () => {

        let totalAmount = 0;
        if (formData.payment_method === '1') {
            totalAmount = calculateTotal();
        }

        const formDataSend = new FormData();
        formDataSend.append('amount_bz', formData.amount_bz);
        formDataSend.append('amount_mx', formData.amount_mx);
        formDataSend.append('status', formData.status);
        formDataSend.append('id_location', formData.id_location);
        formDataSend.append('id_category', selectedCategory);
        formDataSend.append('description', formData.description);
        formDataSend.append('amount_us', formData.amount_us);
        formDataSend.append('payment_method', formData.payment_method);
        formDataSend.append('amount', totalAmount);


        // Agrega el archivo de documento si existe
        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {
            const response = await axios.post(`/financialRequest/update/${financialRequest.id}`, formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast({
                title: 'Financial Request Created Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a Financial Request",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating vacation:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginTop: '2%',
            height: '40px',
            width: '75%',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const fetchData = async (schedules) => {
        try {
            const response = await axios.get(`/showAttachments/financial/${financialRequest.id}`);
            setAttachment(response.data);
        } catch (error) {
            console.error('Error fetching Notes:', error);
        }
    };

    const downloadFile = async (note) => {
        try {
            const response = await axios.get(`/documents/financial/${encodeURIComponent(note.path)}`, { responseType: 'blob' });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : note.path;

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName);
            document.body.appendChild(link);
            link.click();

            // Liberar recursos
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCategory = (selectedOption) => {
        setSelectCategory(selectedOption.value);
    };

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            id_category: selectedCategory,
        }));
    }, [selectedCategory]);

    const formatCurrency = (value, currency) => {
        if (value === null || value === undefined) {
            return '----';
        }
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency
        }).format(value);
    };

    const calculateTotal = () => {
        const total =
            (financeBox.amount) - (formData.amount_mx || 0) - (formData.amount_bz || 0);

        return total.toFixed(2); // Redondea a 2 decimales
    };

    return (
        <Modal size='6xl' isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Review Financial Request
                        </Text>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <Flex>
                                <FormControl isInvalid={!!formErrors.id_category}>Category
                                    <Select2
                                        options={optionCategories}
                                        styles={customStyles}
                                        value={optionCategories.find(option => option.value === selectedCategory)}
                                        onChange={handleCategory}
                                        key={`categorySelect-${resetKey}`}
                                        isSearchable
                                        filterOption={filterOptions}
                                    />
                                    {formErrors.id_category && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isInvalid={!!formErrors.id_location}>
                                    Location
                                    <Select
                                        w="75%"
                                        fontSize={"md"}
                                        borderRadius={"10px"}
                                        name="id_location"
                                        textAlign={"center"}
                                        background="white"
                                        value={formData.id_location}
                                        onChange={handleChange}
                                        h="44px"
                                        maxh="44px"
                                        errorBorderColor="#fc7466"
                                    >
                                        <option value="">Select Location</option>
                                        <option value="1">MX</option>
                                        <option value="2">BZ</option>
                                    </Select>
                                    {formErrors.id_location && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>


                            </Flex>


                            <FormControl
                                mt={4}
                                isInvalid={
                                    !!formErrors.amount_mx || !!formErrors.amount_bz
                                }
                            >
                                Amount {formData.id_location === "1" ? '$MX' : '$BZ'} & $US
                                {formData.id_location === "1" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_mx}
                                            onChange={handleChange}
                                            name="amount_mx"
                                            placeholder="Enter MX amount"
                                        />
                                        {formErrors.amount_mx && (
                                            <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                        )}
                                        <FormControl isInvalid={!!formErrors.amount_us}>
                                            <Input
                                                ml={'17.5%'}
                                                mt={'5%'}
                                                type="number"
                                                background="white"
                                                width="58%"
                                                color="#808080"
                                                textAlign="center"
                                                value={formData.amount_us}
                                                onChange={handleChange}
                                                name="amount_us"
                                                placeholder="Enter US amount"
                                            />
                                            {formErrors.amount_us && (
                                                <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>

                                    </>
                                )}
                                {formData.id_location === "2" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_bz}
                                            onChange={handleChange}
                                            name="amount_bz"
                                            placeholder="Enter BZ amount"
                                        />
                                        {formErrors.amount_bz && (
                                            <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                        )}
                                        <FormControl isInvalid={!!formErrors.amount_us}>
                                            <Input
                                                ml={'17.5%'}
                                                mt={'5%'}
                                                type="number"
                                                background="white"
                                                width="58%"
                                                color="#808080"
                                                textAlign="center"
                                                value={formData.amount_us}
                                                onChange={handleChange}
                                                name="amount_us"
                                                placeholder="Enter US amount"
                                            />
                                            {formErrors.amount_us && (
                                                <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                            )}
                                        </FormControl>
                                    </>
                                )}
                            </FormControl>

                            <FormControl isInvalid={!!formErrors.payment_method}>
                                Payment Method
                                <Select
                                    mt={'2%'}
                                    w="88%"
                                    fontSize={"md"}
                                    borderRadius={"10px"}
                                    name="payment_method"
                                    textAlign={"center"}
                                    background="white"
                                    value={formData.payment_method}
                                    onChange={handleChange}
                                    h="44px"
                                    maxh="44px"
                                    errorBorderColor="#fc7466"
                                >
                                    <option value="">Select Method</option>
                                    <option value="1">Effective</option>
                                    <option value="2">Transfer</option>
                                    <option value="3">Credit card</option>
                                </Select>
                                {formErrors.payment_method && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                            {formData.payment_method === "1" && formData.id_location === '1' && (
                                <>
                                    <Flex direction={'column'}>
                                        {financeBox ? (
                                            <>
                                                <Flex display={'flex'} justifyContent={'space-around'}>
                                                    <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                                                        Current Amount: {formatCurrency(financeBox?.amount, 'MXN')}
                                                    </Text>

                                                    <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'}>
                                                        Total Amount: {formatCurrency(calculateTotal(), 'MXN')}
                                                    </Text>
                                                </Flex>
                                            </>
                                        ) : (
                                            <Text fontWeight={'bold'} color={'#FF0000'} fontSize={'18px'} textAlign={'center'}>
                                                No cash fund assigned for this week.
                                            </Text>
                                        )}
                                    </Flex>

                                </>
                            )}

                            {formData.payment_method === "1" && formData.id_location === '2' && (
                                <>
                                    <Flex direction={'column'}>
                                        {financeBox ? (
                                            <>
                                                <Flex display={'flex'} justifyContent={'space-around'}>
                                                    <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                                                        Current Amount: {formatCurrency(financeBox?.amount, 'BZD')}
                                                    </Text>

                                                    <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'}>
                                                        Total Amount: {formatCurrency(calculateTotal(), 'BZD')}
                                                    </Text>
                                                </Flex>
                                            </>
                                        ) : (
                                            <Text fontWeight={'bold'} color={'#FF0000'} fontSize={'18px'} textAlign={'center'}>
                                                No cash fund assigned for this week.
                                            </Text>
                                        )}
                                    </Flex>

                                </>
                            )}

                            <FormControl mt={4}>
                                Attachment
                                <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                    accept="image/*,application/pdf" />
                                <Button as="label" htmlFor="document" cursor="pointer"
                                    bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                    color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'5%'}
                                    width={"75%"}
                                    ml='5%'
                                >
                                    <ImAttachment ml={2} size={'25px'} />
                                </Button>
                            </FormControl>

                            <Flex display={'flex'} justifyContent={'left'}>
                                {documentFile && (
                                    <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                        mr={'5%'} position={"relative"}
                                        mt={'2%'}
                                    >
                                        <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                        <Text>
                                            {documentFile.name.length > 20
                                                ? `${documentFile.name.substring(0, 20)}…`
                                                : documentFile.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex>
                                <FormControl isInvalid={!!formErrors.description} mr={'5%'}>
                                    Additional Note
                                    <Box>
                                        <Textarea
                                            bg={'white'}
                                            mt={'2%'}
                                            width={'100%'}
                                            placeholder='Additional Note'
                                            value={formData.description}
                                            onChange={handleChange}
                                            name='description'
                                        />
                                    </Box>
                                    {formErrors.description && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.status}>
                                    Approved or Denied
                                    <Select
                                        w="75%"
                                        fontSize={"md"}
                                        borderRadius={"10px"}
                                        name="status"
                                        textAlign={"center"}
                                        background="white"
                                        value={formData.status}
                                        onChange={handleChange}
                                        h="44px"
                                        maxh="44px"
                                        errorBorderColor="#fc7466"
                                        mt='2%'
                                    >
                                        <option value="">Select</option>
                                        <option value="2">Approved</option>
                                        <option value="3">Denied</option>
                                    </Select>
                                    {formErrors.status && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                            </Flex>

                        </SimpleGrid>


                    </Box>

                    <Flex>
                        <Flex direction={'column'} maxHeight={"300px"} overflowY={"auto"} w={'30%'} mt={'3%'}>
                            <Flex p={1}
                                direction={'column'}
                            >
                                <Text ml={'1%'} color={'#808080'} fontWeight={'bold'}>
                                    Reason
                                </Text>

                                <Text ml={'1%'} fontSize={'18px'} mt={'2%'}>
                                    {financialRequest.reason ? financialRequest.reason : '---'}
                                </Text>
                            </Flex>
                        </Flex>

                        <Flex direction={'column'} maxHeight={"300px"} overflowY={"auto"} w={'80%'} mt={'3%'}>
                            {attachment && attachment.length > 0 ? (
                                attachment.map((attachment, index) => (
                                    <Flex p={1}
                                        key={attachment.id}
                                        boxSizing='border-box'
                                        direction={'column'}
                                    >
                                        <Text ml={'1%'} color={'#808080'} fontWeight={'bold'}>
                                            Attachments
                                        </Text>
                                        <VStack spacing={3}>
                                            <Flex flexDirection={"column"} width={"100%"}  >
                                                <Flex display={'flex'}>
                                                    {attachment.path && (
                                                        <Button
                                                            onClick={() => downloadFile(attachment)}
                                                            borderRadius={'5px'}
                                                            size={'lg'}
                                                            width={'80px'}
                                                            height={'80px'}
                                                            mr={'5%'}
                                                            ml={'1%'}
                                                            onMouseEnter={() => setHoveredMessage(attachment.id)}
                                                            onMouseLeave={() => setHoveredMessage(null)}
                                                            _hover={{ background: "#cccccc" }}
                                                            bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                                        >
                                                            {hoveredMessage === attachment.id ? (
                                                                <DownloadIcon fontSize={'35px'} color="white" />
                                                            ) : (
                                                                <IoDocument ml={2} size={'50px'} color="white" />
                                                            )}
                                                        </Button>
                                                    )}
                                                </Flex>
                                                <Text ml={"1%"} textAlign={'left'} mb={2} fontWeight={"bold"} color={"#424ea8"} fontSize={'small'}>
                                                    {attachment.created}
                                                </Text>


                                            </Flex>


                                        </VStack>
                                        <span style={{ borderTop: '4px', borderColor: '#808080', width: '100%' }}></span>
                                    </Flex>
                                ))
                            ) : (
                                <Box>
                                    <Text bg={"#f2f2f2"} fontSize={"24px"} fontWeight={"bold"} textAlign={"center"}>
                                        No attachments
                                    </Text>
                                </Box>
                            )}
                        </Flex>

                    </Flex>

                </ModalBody>
                <ModalFooter justifyContent="center" mt={"5%"} mb={'5%'}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Approved <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default FinancialRequestUpdate;
