import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseQualification from "./components/itemsQualification/functions";

export default function QualificationOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseQualification/>
      </Card>
    </Flex>
  );
} 