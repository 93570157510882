import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Textarea, Button, Input, Flex, FormControl, FormErrorMessage, useToast
}
    from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import Select2 from 'react-select';
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";

function QAIncidence({ isOpen, onClose, userAuth }) {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [userCoverage, setUserCoverage] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [selectedReason, setSelectedReason] = useState([]);
    const [documentFile, setDocumentFile] = useState(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [formData, setFormData] = useState({
        follow_up_date: '',
        comment: '',
    });

    const handleSubmit = async () => {

        const selectOperator = selectedOperator.value;
        const selectClient = selectedClient.value;
        const selectReaon = selectedReason.value;

        const formDataSend = new FormData();
        formDataSend.append('id_client', selectClient);
        formDataSend.append('id_user', selectOperator);
        formDataSend.append('follow_up_date', formData.follow_up_date);
        formDataSend.append('reason', selectReaon);
        formDataSend.append('comment', formData.comment);

        // Agrega el archivo de documento si existe
        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {

            const response = await axios.post('/qaIncidence/create', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                toast({
                    title: 'The incidence has been send successfully',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Creating a Incidence",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating schedule:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const fetchDataClient = async () => {
        try {
            const response = axios.get("/userClient").then((response) => {
                const clientOptions = response.data.client.map((client) => ({
                    label: client.name,
                    value: client.id,
                }));
                const coverageOptions = response.data.user.map((user) => ({
                    label: `${user.name} ${user.last_name}`,
                    value: user.id,
                }));
                setClients(clientOptions);
                setUserCoverage(coverageOptions);
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataClient();
    }, []);

    const handleClientChange = (selectedOption) => {
        setSelectedClient(selectedOption);
    };


    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '2px solid transparent',
            borderRadius: '8px',
            backgroundColor: '#f2f2f2',
            textAlign: 'center',
            width: '350px',
            marginRight: '30px',
            '&:hover': {
                border: '2px solid transparent',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#f2f2f2',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const reasons = [
        { value: 1, label: 'Day Off' },
        { value: 2, label: 'Call Out' },
        { value: 3, label: 'Medical Leave' },
        { value: 4, label: 'Did not Join' },
        { value: 5, label: 'Other - Add Notes' },
        { value: 6, label: 'Scored Zero' },
    ];

    const handleReasonChange = (selectedOption) => {
        setSelectedReason(selectedOption);
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'}>
                    <ModalHeader textAlign="center" color={'#808080'} mt={'50px'} fontSize={'40px'}>
                        QA Incidence
                    </ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody>
                        <Flex direction={'column'}>
                            <Flex display='flex' justifyContent="center" ml={'10%'}>

                                <FormControl isInvalid={!!formErrors.id_client} >
                                    <Text mb={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                        Team:
                                    </Text>
                                    <Select2
                                        placeholder="Team"
                                        options={clients}
                                        value={selectedClient}
                                        onChange={handleClientChange}
                                        styles={customStyles}
                                        key={`clientSelect-${resetKey}`}
                                    />
                                    {formErrors.id_client && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.id_client}</FormErrorMessage>
                                    )}
                                </FormControl>


                                <FormControl isInvalid={!!formErrors.id_user} mr={'5%'}>
                                    <Text mb={'5%'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Agent's name:
                                    </Text>
                                    <Select2
                                        placeholder="Agent's Name"
                                        options={userCoverage}
                                        styles={customStyles}
                                        value={selectedOperator}
                                        onChange={(selectedOption) => setSelectedOperator(selectedOption)}
                                        key={`userSelect-${resetKey}`}
                                        isSearchable
                                        filterOption={filterOptions}
                                    />

                                    {formErrors.id_user && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.id_user}</FormErrorMessage>
                                    )}
                                </FormControl>


                            </Flex>

                            <Flex display='flex' justifyContent="center" mt={'5%'} ml={'8%'}>

                                <FormControl isInvalid={!!formErrors.reason} >
                                    <Text mb={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                        Reason:
                                    </Text>
                                    <Select2
                                        placeholder="Select reason"
                                        options={reasons}
                                        value={selectedReason}
                                        onChange={handleReasonChange}
                                        styles={customStyles}
                                        key={`reasonSelect-${resetKey}`}
                                    />
                                    {formErrors.reason && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.reason}</FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl isInvalid={!!formErrors.follow_up_date} mr={'5%'}>
                                    <Text mb={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                        Follow up date:
                                    </Text>
                                    <Input
                                        type='date'
                                        bg={'#f2f2f2'}
                                        width={'96%'}
                                        color={'#808080'}
                                        textAlign={'center'}
                                        value={formData.follow_up_date}
                                        onChange={handleChange}
                                        name='follow_up_date'
                                    />
                                    {formErrors.follow_up_date && (
                                        <FormErrorMessage justifyContent={'center'}>{formErrors.follow_up_date}</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>

                            <Flex display='flex' justifyContent="center" mt={'5%'} ml={'8%'}>

                                <FormControl mr={'5%'}>
                                    <Text mb={'5%'} mr={'20px'} color={'#1c2147'} fontWeight={'bold'}>
                                        Note:
                                    </Text>
                                    <Textarea
                                        bg={'white'}
                                        mr={4}
                                        width={'100%'}
                                        placeholder='Add a note if is necessary.'
                                        value={formData.comment}
                                        onChange={handleChange}
                                        name='comment'
                                    />
                                </FormControl>
                            </Flex>

                            <Flex display='none' mt={'5%'} ml={'3%'}>
                                <Text mt={'5px'} color={'#1c2147'} fontWeight={'bold'} ml={'5%'} mr={'5%'}>
                                    Attachment:
                                </Text>
                                <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                    accept="image/*,application/pdf" />
                                <Button as="label" htmlFor="document" cursor="pointer"
                                    bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                    color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'50%'} height={'50px'}
                                    width={"50px"}
                                >
                                    <ImAttachment ml={2} size={'35px'} />
                                </Button>
                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'}>
                                {documentFile && (
                                    <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                        mr={'5%'} position={"relative"}
                                        mt={'2%'}
                                    >
                                        <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                        <Text>
                                            {documentFile.name.length > 20
                                                ? `${documentFile.name.substring(0, 20)}…`
                                                : documentFile.name
                                            }
                                        </Text>
                                        <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex display={'flex'} justifyContent={'center'} mt={'80px'} mb={'40px'}>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#b9ce67"}
                                    color={"white"}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={handleSubmit}
                                    mr={'30px'}
                                >
                                    <Text mr={'5px'}>
                                        Send
                                    </Text>
                                    <IoMdSend />
                                </Button>

                                <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={onClose}>
                                    <CloseIcon mr={2} />  Cancel
                                </Button>
                            </Flex>
                        </Flex>



                    </ModalBody>
                </ModalContent>
            </Modal >
        </Flex>
    );
}

export default QAIncidence;
