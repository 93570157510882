import React, { useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function EventCalendar(props) {
  const { calendarData, initialDate } = props;
  const calendarRef = useRef(null);

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarApi) {
      calendarApi.setOption("customButtons", {
        prev: {
          text: "Prev",
          click: function () {
            calendarApi.prev();
          },
        },
        next: {
          text: "Next",
          click: function () {
            calendarApi.next();
          },
        },
        today: {
          text: "Today",
          click: function () {
            calendarApi.today();
          },
        },
      });
    }
  }, []);

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={calendarData}
      initialDate={initialDate}
      editable={false}
      slotWidth={10}
      customIcons={false}
      headerToolbar={{
        left: "prev today next",
        center: "title",
        right: "",
      }}
    />
  );
}

export default EventCalendar;
