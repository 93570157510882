import Select2 from 'react-select'
import {
    Button, Flex, Box, Text
} from '@chakra-ui/react'
import { AiOutlineClear } from "react-icons/ai";


function FinancialUserRequestFilters({ filterDate, setFilterDate, filterDateEnd, setFilterDateEnd, clearFilters }) {

    return (

        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            p={2}
            borderRadius="16px"
        >
            <Flex flexDirection={"column"} bg="gray.200" p={2} borderRadius={"16px"} >
                <Flex align="center"    >
                    
                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'26px'} />
                        </Button>
                    </Box>
                </Flex>

            </Flex>

        </Flex>
    );
}
export default FinancialUserRequestFilters; 