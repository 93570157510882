
import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Menu, MenuButton, MenuItem, MenuList,
    Button, ButtonGroup,
    Flex, Box, Badge,
    Text
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, SettingsIcon, RepeatIcon, EditIcon } from '@chakra-ui/icons'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import SettingsCreate from './create';
import UpdateCalendar from './update';
import Filters from './search';
import { IoCalendarNumber } from "react-icons/io5";

function CalendarList({ openModalCreate, setFilterDateEnd, setFilterDate,filterDate,filterDateEnd,resetKey,locationSearch,setLocationSearch, clearFilters, calendar, setCurrentPage, getRowBackground, handleUpdate, handleDelete, total, loading, currentPage, totalPages, modalCreateIsOpen, closeModalCreate, fetchData, ModalUpdateIsOpen, closeModalUpdate, selectCalendar, setFlashing }) {

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'>
                <Flex
                    direction='column'
                    w='100%'
                    p={3}
                    borderRadius="16px"
                >
                    <Flex align="center" justifyContent="flex-end">
                        <Button class="buttonCreate" onClick={openModalCreate}>
                            Add Event <AddIcon ml={2} />
                        </Button>
                    </Flex>
                </Flex>
                <Filters
                                    setCurrentPage={setCurrentPage}
                                    setFilterDateEnd={setFilterDateEnd}
                                    setFilterDate={setFilterDate}
                                    resetKey={resetKey}
                                    filterDate={filterDate}
                                    filterDateEnd={filterDateEnd}
                                    clearFilters={clearFilters}
                                    locationSearch={locationSearch}
                                    setLocationSearch={setLocationSearch}
                                    calendar={calendar}
                                />
                <br />
                <Table size={'sm'}>
                    <Thead bg={"white"}>
                        <Tr>
                            <Th color='#808080' textAlign={'center'}>ID</Th>
                            <Th color='#808080' textAlign={'center'}>Title</Th>
                            <Th color='#808080' textAlign={'center'}>Description</Th>
                            <Th color='#808080' textAlign={'center'}>start Date</Th>
                            <Th color='#808080' textAlign={'center'}>End Date</Th>
                            <Th color='#808080' textAlign={'center'}>Location</Th>
                            {/* <Th color='#808080' textAlign={'center'}>Type</Th> */}
                            <Th textAlign={'center'}>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                        {calendar && calendar.length > 0 ? (
                            calendar.map(calendar => (
                                <Tr key={calendar.id} bg={getRowBackground(calendar)}>
                                    <Td textAlign={'center'}>{calendar.id}</Td>
                                    <Td textAlign={'center'}>
                                        {calendar.title}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {calendar.description}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {calendar.startformat}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {calendar.endformat}
                                    </Td>
                                    <Td textAlign={'center'}>
                                        {(() => {
                                            switch (calendar.id_location) {
                                                case 1:
                                                    return "MX";
                                                case 2:
                                                    return "BZ";
                                                case 3:
                                                    return "RD";
                                                case 4:
                                                    return "COL";
                                                case 5:
                                                    return "GTM";
                                                default:
                                                    return "MX";
                                            }
                                        })()}
                                    </Td>
                                    {/* <Td textAlign={'center'}>
                                        {calendar.type}
                                    </Td> */}
                                    <Td textAlign={'center'}>
                                        <Menu>
                                            <MenuButton borderRadius={"5px"} as={Button} _active={{ background: "#808080", color: 'white' }} bg="#1c2147" color={"white"} _hover={{ background: "#808080", color: 'white' }}>
                                                <SettingsIcon pb={"2px"} />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem isDisabled>
                                                    <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions</div>
                                                </MenuItem>
                                                <MenuItem
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                    alignItems="center">
                                                    <Button
                                                        borderRadius={"5px"}
                                                        bg={"#f2f2f2"}
                                                        color={"#1c2147"}
                                                        _hover={{ background: "#7080fc", color: 'white' }}
                                                        onClick={() => handleUpdate(calendar)}
                                                        width={"100%"}
                                                        justifyContent="space-between"
                                                        alignItems="center">
                                                        <Text flex={1} textAlign="center">
                                                            Edit Event
                                                        </Text>
                                                        <EditIcon ml={2} verticalAlign="middle" />
                                                    </Button>
                                                </MenuItem>
                                                <MenuItem
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                    flexDirection={"column"}
                                                    alignItems="center">
                                                    <Button
                                                        borderRadius={"5px"}
                                                        bg={"#f2f2f2"}
                                                        color={"#1c2147"}
                                                        _hover={{ background: "#fc7466", color: 'white' }}
                                                        onClick={() => handleDelete(calendar)}
                                                        width={"100%"}
                                                        justifyContent="space-between"
                                                        alignItems="center">
                                                        <Text flex={1} textAlign="center">
                                                            Delete Event
                                                        </Text>
                                                        <DeleteIcon ml={2} verticalAlign="middle" />
                                                    </Button>
                                                </MenuItem>

                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2} ml={4}>
                                    <IoCalendarNumber color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No Events
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'150px'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <IoCalendarNumber color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    {total === 1 ? 'Event' : 'Events'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"34%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
            <SettingsCreate isOpen={modalCreateIsOpen} onClose={closeModalCreate} onSettingCreated={fetchData} />
            {ModalUpdateIsOpen && (
                <UpdateCalendar isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} calendar={selectCalendar} onCalendarUpdated={fetchData} setFlashing={setFlashing} />)}
        </TableContainer>
    );
}
export default CalendarList; 