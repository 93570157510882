import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, MdEditDocument } from 'react-icons/md';
import { IoTicketSharp } from "react-icons/io5";
import { FaRegCalendar, FaUsers } from "react-icons/fa6";
import { BsFillPersonFill } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";

import DashboardsDefault from 'views/admin/dashboards/default';
import DefaultUser from 'views/admin/main/others/messages/indexUser';
import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';
import DailyOverview from 'views/admin/main/schedules/dailyschedule';

import IncidentsOverview from 'views/admin/main/incidents';
import MyScheduleOverview from 'views/admin/main/schedules/myschedule';
import MedicalNotesOverview from 'views/admin/main/Medical_Notes';
// import IncidentsAgentOverview from 'views/admin/main/incidents/indexAgent';

import QAOverview from 'views/admin/main/QA';
import QualificationOverview from 'views/admin/main/QA/indexQA';
import IndexClarification from 'views/admin/main/QA/indexClarification';
import UsersSchedulesOverview from 'views/admin/main/schedules/weeklyschedule';
import UsersOverview from 'views/admin/main/users/newUser/items/userDashboard';
import IncidenceOverview from 'views/admin/main/QA/indexIncidence';
import TotalsOverview from 'views/admin/main/QA/indexTotals';
import FinancialUserRequestOverview from 'views/admin/main/financialRequests/indexUser';

const routesQAManager = [
    // --- Dashboards ---
    {
        name: 'Home',
        layout: '/admin',
        path: '/home',
        icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
        component: <DashboardsDefault />,
    },
    {
        name: 'Dashboard',
        layout: '/admin',
        path: '/dashboards',
        icon: <Icon as={MdDashboard} width="30px" height="30px" color="inherit" />,
        component: <DailyOverview />,
    },
    {
        name: 'Schedules',
        icon: <Icon as={FaRegCalendar} width="30px" height="30px" color="inherit" />,
        path: '/schedules',
        collapse: true,
        items: [
            {
                name: 'Scheduler',
                layout: '/admin',
                path: '/schedules',
                exact: false,
                component: <UsersSchedulesOverview />,
            },

            {
                name: 'My schedule',
                path: '/my-schedule',
                layout: '/admin',
                component: <MyScheduleOverview />,
            },
        ],
    },
    {
        name: 'IFR',
        layout: '/admin',
        path: '/ifr',
        icon: <Icon as={TbReportMoney} width="30px" height="30px" color="inherit" />,
        component: <FinancialUserRequestOverview />,
    },
    {
        name: 'Tickets',
        icon: <Icon as={IoTicketSharp} width="30px" height="30px" color="inherit" />,
        path: '/Ticket',
        collapse: true,
        items: [

            {
                name: 'Completed',
                layout: '/admin',
                path: '/ticket/dashboard',
                exact: false,
                component: <IncidentsOverview />,
            },
            {
                name: 'Ongoing',
                layout: '/admin',
                path: '/ticketU/chat',
                exact: false,
                component: <DefaultUser />,
            },
            {
                name: 'My Completed',
                layout: '/admin',
                path: '/ticketU/finish',
                exact: false,
                component: <IncidentsAgentOverview />,
            },

        ],
    },
    {
        name: 'QA',
        path: '/qa',
        icon: <Icon as={MdEditDocument} width="30px" height="30px" color="inherit" />,
        collapse: true,
        items: [
            {
                name: 'Formats',
                path: '/qaformat',
                component: <QAOverview />,
                layout: '/admin',
            },
            {
                name: 'Scores',
                path: '/qualification',
                component: <QualificationOverview />,
                layout: '/admin',
            },
            {
                name: 'Clarifications',
                layout: '/admin',
                path: '/clarification',
                component: <IndexClarification />,
            },
            {
                name: 'Incidences',
                layout: '/admin',
                path: '/incidences',
                component: <IncidenceOverview />,
            },
            {
                name: 'Averages',
                layout: '/admin',
                path: '/totals',
                component: <TotalsOverview />,
            },
        ],
    },
    {
        name: 'Human Resources',
        icon: <Icon as={FaUsers} width="30px" height="30px" color="inherit" />,
        path: '/hr',
        collapse: true,
        items: [

            {
                name: 'Medical Notes',
                path: '/MedicalNotes',
                layout: '/admin',
                component: <MedicalNotesOverview />,
            },
        ],
    },
    {
        name: 'Users',
        layout: '/admin',
        icon: <Icon as={BsFillPersonFill} width="30px" height="30px" color="inherit" />,
        path: '/users',
        exact: false,
        component: <UsersOverview />,
    },

];

export default routesQAManager;
