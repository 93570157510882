import React from 'react';
import {
    TableContainer, Table, Thead, Tbody, Tr, Th, Td,
    Text, Flex, Box,
    FormErrorMessage, Input,
    Button,
    Textarea, Select, useToast, FormControl, VStack,
    Heading
} from '@chakra-ui/react'

function UpdateEndicott({ saveChanges, onClose, totalScore, possibleScore, handleScoreChange, handleStatusChange, questions, comment, handleCommentChange }) {

    return (
        <TableContainer>
            <Table size={'sm'}>
                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                    {questions && questions.length > 0 ? (
                        <>
                            {/* Sección para las preguntas de tipo 1 (Quality) */}
                            <Box>
                                <Heading as="h2" size="md" mb={'4'}>Caller Experience</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions.filter(item => item.question.type === 3).map((item, index) => (
                                    <Tr key={item.id} bg={index % 2 === 0 ? 'white' : '#f2f2f2'}>
                                        <Td
                                            textAlign={'left'}
                                            whiteSpace="pre-wrap"
                                            wordBreak="break-word"
                                            fontSize={'110%'}
                                        >
                                            {item.question.question}
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <Select

                                                value={item.status || ''} // Aquí enlazas el valor actual
                                                onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                            >
                                                <option value="Y">Y</option>
                                                <option value="X">X</option>
                                                <option value="N">N</option>
                                            </Select>
                                        </Td>
                                        <Td textAlign={'center'}>
                                            <input
                                                type="text"
                                                value={item.score || ''} // Aquí enlazas el valor actual
                                                onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                style={{
                                                    width: "60px",
                                                    backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                        item.status === 'N' ? '#f8d4d4' :
                                                            'white',
                                                    color: '#808080', // Asegura que el texto sea visible
                                                    textAlign: 'center'
                                                }}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                            </Box>

                            {/* Sección para las preguntas de tipo 2 (Mannerisms) */}
                            <Box mt={8}>
                                <Heading as="h2" size="md" bg={'white'}>Soft Skills</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions
                                    .filter((item) => item.question.type === 4).map((item, index) => (
                                        <Tr key={item.id} bg={index % 2 === 0 ? '#f2f2f2' : 'white'}>
                                            <Td
                                                textAlign={'left'}
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                                fontSize={'110%'}
                                            >
                                                {item.question.question}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Select
                                                    value={item.status || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                                >
                                                    <option value="Y">Y</option>
                                                    <option value="X">X</option>
                                                    <option value="N">N</option>
                                                </Select>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <input
                                                    type="text"
                                                    value={item.score || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                    disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                    style={{
                                                        width: "60px",
                                                        backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                            item.status === 'N' ? '#f8d4d4' :
                                                                'white',
                                                        color: '#808080', // Asegura que el texto sea visible
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="md" bg={'white'}>Opening</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions
                                    .filter((item) => item.question.type === 5).map((item, index) => (
                                        <Tr key={item.id} bg={index % 2 === 0 ? '#f2f2f2' : 'white'}>
                                            <Td
                                                textAlign={'left'}
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                                fontSize={'110%'}
                                            >
                                                {item.question.question}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Select
                                                    value={item.status || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                                >
                                                    <option value="Y">Y</option>
                                                    <option value="X">X</option>
                                                    <option value="N">N</option>
                                                </Select>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <input
                                                    type="text"
                                                    value={item.score || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                    disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                    style={{
                                                        width: "60px",
                                                        backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                            item.status === 'N' ? '#f8d4d4' :
                                                                'white',
                                                        color: '#808080', // Asegura que el texto sea visible
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="md" bg={'white'}>Call Handling</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions
                                    .filter((item) => item.question.type === 5).map((item, index) => (
                                        <Tr key={item.id} bg={index % 2 === 0 ? '#f2f2f2' : 'white'}>
                                            <Td
                                                textAlign={'left'}
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                                fontSize={'110%'}
                                            >
                                                {item.question.question}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Select
                                                    value={item.status || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                                >
                                                    <option value="Y">Y</option>
                                                    <option value="X">X</option>
                                                    <option value="N">N</option>
                                                </Select>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <input
                                                    type="text"
                                                    value={item.score || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                    disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                    style={{
                                                        width: "60px",
                                                        backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                            item.status === 'N' ? '#f8d4d4' :
                                                                'white',
                                                        color: '#808080', // Asegura que el texto sea visible
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="md" bg={'white'}>Documentation</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions
                                    .filter((item) => item.question.type === 6).map((item, index) => (
                                        <Tr key={item.id} bg={index % 2 === 0 ? '#f2f2f2' : 'white'}>
                                            <Td
                                                textAlign={'left'}
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                                fontSize={'110%'}
                                            >
                                                {item.question.question}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Select
                                                    value={item.status || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                                >
                                                    <option value="Y">Y</option>
                                                    <option value="X">X</option>
                                                    <option value="N">N</option>
                                                </Select>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <input
                                                    type="text"
                                                    value={item.score || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                    disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                    style={{
                                                        width: "60px",
                                                        backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                            item.status === 'N' ? '#f8d4d4' :
                                                                'white',
                                                        color: '#808080', // Asegura que el texto sea visible
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </Box>

                            <Box mt={8}>
                                <Heading as="h2" size="md" bg={'white'}>Closing</Heading>
                                <Thead bg={"white"}>
                                    <Tr>
                                        <Th color='#808080' textAlign={'center'} width={'100%'}>Question</Th>
                                        <Th textAlign={'center'} >Select Option</Th>
                                        <Th textAlign={'center'}>Edit Score</Th>
                                    </Tr>
                                </Thead>
                                {questions
                                    .filter((item) => item.question.type === 7).map((item, index) => (
                                        <Tr key={item.id} bg={index % 2 === 0 ? '#f2f2f2' : 'white'}>
                                            <Td
                                                textAlign={'left'}
                                                whiteSpace="pre-wrap"
                                                wordBreak="break-word"
                                                fontSize={'110%'}
                                            >
                                                {item.question.question}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Select
                                                    value={item.status || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleStatusChange(e, item.id)} // Función para manejar cambios
                                                >
                                                    <option value="Y">Y</option>
                                                    <option value="X">X</option>
                                                    <option value="N">N</option>
                                                </Select>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <input
                                                    type="text"
                                                    value={item.score || ''} // Aquí enlazas el valor actual
                                                    onChange={(e) => handleScoreChange(e, item.id)} // Función para manejar cambios
                                                    disabled={item.status === 'N/A'} // Deshabilita el input si es "N/A"
                                                    style={{
                                                        width: "60px",
                                                        backgroundColor: item.status === 'Y' || item.status === 'X' ? '#d4f8d4' :
                                                            item.status === 'N' ? '#f8d4d4' :
                                                                'white',
                                                        color: '#808080', // Asegura que el texto sea visible
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </Box>
                        </>
                    ) : (
                        <Tr>
                            <Th colSpan={'9'}>
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                    <Flex mt={'2%'}>
                        <Textarea
                            w={'100%'}
                            borderColor={"#b3b3b3"}
                            placeholder="Comments:"
                            background="white"
                            errorBorderColor='#fc7466'
                            value={comment}
                            onChange={handleCommentChange}
                        >
                        </Textarea>
                        <Flex direction={'column'} textAlign={'right'} w={'20%'}>
                            <Box>
                                <Text fontSize="lg" fontWeight="bold">
                                    Possible Score: {possibleScore}
                                </Text>
                                <Text fontSize="lg" fontWeight="bold">
                                    Total Score: {totalScore}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>

                    <Flex display={'flex'} justifyContent={'center'} mt={'4%'} mb={'3%'}>
                        <Button borderRadius={"5px"}
                            bg={"#b9ce67"}
                            color={"white"}
                            _hover={{ background: "#8aad01", color: 'white' }}
                            onClick={saveChanges}
                            mr={'30px'}>
                            Edit

                        </Button>

                        <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} mr={3} borderRadius='10px' fontWeight='bold' onClick={onClose}>
                            Cancel
                        </Button>
                    </Flex>
                </Tbody>
            </Table>
        </TableContainer>

    );
}
export default UpdateEndicott; 