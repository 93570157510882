import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select2 from 'react-select'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box, Flex, SimpleGrid, FormControl, FormErrorMessage, useToast, Select, Text, Textarea
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'
import { ImAttachment } from "react-icons/im";
import { IoDocument } from "react-icons/io5";

const FinancialRequestCreate = ({ isOpen, onClose, optionCategories, filterOptions, data }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [selectedCategory, setSelectCategory] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [documentFile, setDocumentFile] = useState(null);
    const [financeBox, setFinanceBox] = useState([]);
    const [formData, setFormData] = useState({
        id_category: '',
        id_location: '1',
        reason: '',
        amount_mx: '',
        amount_bz: '',
        amount_us: '',
        payment_method: '',
        status: 2,
    });

    const clearForm = () => {
        setFormData({
            id_category: '',
            id_location: '',
            reason: '',
            amount_mx: '',
            amount_bz: '',
            amount_us: '',
            payment_method: '',
            status: 2
        });
        setFormErrors({});
        setResetKey(resetKey + 1);
        setSelectCategory([]);
    };

    const fetchFinanceBox = async () => {
        try {
            const response = await axios.get(
                `/financeBox/${formData.id_location}`
            );
            const responseData = response.data;
            setFinanceBox(responseData.financeBox);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        }
    }

    const handleSubmit = async () => {

        let totalAmount = 0;
        if (formData.payment_method === '1') {
            totalAmount = calculateTotal();
        }

        const formDataSend = new FormData();
        formDataSend.append('amount_bz', formData.amount_bz);
        formDataSend.append('amount_mx', formData.amount_mx);
        formDataSend.append('amount_us', formData.amount_us);
        formDataSend.append('payment_method', formData.payment_method);
        formDataSend.append('status', 2);
        formDataSend.append('reason', formData.reason);
        formDataSend.append('id_location', formData.id_location);
        formDataSend.append('id_category', selectedCategory);
        formDataSend.append('amount', totalAmount); // Aquí se guarda el total calculado

        // Agrega el archivo si existe
        if (documentFile) {
            formDataSend.append('document', documentFile?.file || '');
        }

        try {
            const response = await axios.post('/financialRequest/create', formDataSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            toast({
                title: 'Financial Request Created Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a Financial Request",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating vacation:', error);
            setFormErrors(error.response.data.errors);
        }
    };


    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const removeFile = () => {
        setDocumentFile(null)
        setFormErrors({});
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setDocumentFile({
                file,
                name: file.name,
            });
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            marginTop: '2%',
            height: '40px',
            width: '75%',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const handleCategory = (selectedOption) => {
        setSelectCategory(selectedOption.value);
    };

    useEffect(() => {
        fetchFinanceBox();
    }, []);

    const formatCurrency = (value, currency) => {
        if (value === null || value === undefined) {
            return '----';
        }
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency
        }).format(value);
    };

    const calculateTotal = () => {
        const total =
            (financeBox.amount) - (formData.amount_mx || 0) - (formData.amount_bz || 0);

        return total.toFixed(2); // Redondea a 2 decimales
    };

    return (
        <Modal size='6xl' isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent mt={"5%"} background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Create Financial Request
                        </Text>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <Flex>
                                <FormControl isInvalid={!!formErrors.id_category}>Category
                                    <Select2
                                        options={optionCategories}
                                        styles={customStyles}
                                        value={optionCategories.find(option => option.value === selectedCategory)}
                                        onChange={handleCategory}
                                        key={`categorySelect-${resetKey}`}
                                        isSearchable
                                        filterOption={filterOptions}
                                    />
                                    {formErrors.id_category && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                                <FormControl isInvalid={!!formErrors.id_location}>
                                    Location
                                    <Select
                                        w="75%"
                                        fontSize={"md"}
                                        borderRadius={"10px"}
                                        name="id_location"
                                        textAlign={"center"}
                                        background="white"
                                        value={formData.id_location}
                                        onChange={handleChange}
                                        h="44px"
                                        maxh="44px"
                                        errorBorderColor="#fc7466"
                                    >
                                        <option value="">Select Location</option>
                                        <option value="1">MX</option>
                                        <option value="2">BZ</option>
                                    </Select>
                                    {formErrors.id_location && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>

                            </Flex>


                            <FormControl mt={4}>
                                Amount
                                {formData.id_location === "1" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_mx}
                                            onChange={handleChange}
                                            name="amount_mx"
                                            placeholder="Enter MX amount"
                                        />
                                        <Input
                                            ml={'10%'}
                                            mt={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_us}
                                            onChange={handleChange}
                                            name="amount_us"
                                            placeholder="Enter US amount"
                                        />
                                    </>
                                )}
                                {formData.id_location === "2" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_bz}
                                            onChange={handleChange}
                                            name="amount_bz"
                                            placeholder="Enter BZ amount"
                                        />
                                        <Input
                                            ml={'10%'}
                                            mt={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_us}
                                            onChange={handleChange}
                                            name="amount_us"
                                            placeholder="Enter US amount"
                                        />
                                    </>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.payment_method}>
                                Payment Method
                                <Select
                                    w="75%"
                                    fontSize={"md"}
                                    borderRadius={"10px"}
                                    name="payment_method"
                                    textAlign={"center"}
                                    background="white"
                                    value={formData.payment_method}
                                    onChange={handleChange}
                                    h="44px"
                                    maxh="44px"
                                    errorBorderColor="#fc7466"
                                >
                                    <option value="">Select Method</option>
                                    <option value="1">Effective</option>
                                    <option value="2">Transfer</option>
                                    <option value="3">Credit card</option>
                                </Select>
                                {formErrors.payment_method && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                            {formData.payment_method === "1" && formData.id_location === '1' && (
                                <>
                                    <Flex direction={'column'}>
                                        {financeBox ? (
                                            <>
                                                <Flex display={'flex'} justifyContent={'space-around'}>
                                                    <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                                                        Current Amount: {formatCurrency(financeBox?.amount, 'MXN')}
                                                    </Text>

                                                    <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'}>
                                                        Total Amount: {formatCurrency(calculateTotal(), 'MXN')}
                                                    </Text>
                                                </Flex>
                                            </>
                                        ) : (
                                            <Text fontWeight={'bold'} color={'#FF0000'} fontSize={'18px'} textAlign={'center'}>
                                                No cash fund assigned for this week.
                                            </Text>
                                        )}
                                    </Flex>

                                </>
                            )}

                            {formData.payment_method === "1" && formData.id_location === '2' && (
                                <>
                                    <Flex direction={'column'}>
                                        {financeBox ? (
                                            <>
                                                <Flex display={'flex'} justifyContent={'space-around'}>
                                                    <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                                                        Current Amount: {formatCurrency(financeBox?.amount, 'BZD')}
                                                    </Text>

                                                    <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'}>
                                                        Total Amount: {formatCurrency(calculateTotal(), 'BZD')}
                                                    </Text>
                                                </Flex>
                                            </>
                                        ) : (
                                            <Text fontWeight={'bold'} color={'#FF0000'} fontSize={'18px'} textAlign={'center'}>
                                                No cash fund assigned for this week.
                                            </Text>
                                        )}
                                    </Flex>

                                </>
                            )}


                            <FormControl mt={4}>
                                Attachment
                                <Input type="file" id="document" style={{ display: 'none' }} onChange={handleDocumentChange}
                                    accept="image/*, application/pdf" />
                                <Button as="label" htmlFor="document" cursor="pointer"
                                    bg={'linear-gradient(180deg, rgba(66, 78, 168, 1.0) 50%, rgba(101, 112, 199, 1.0) 50%)'}
                                    color={"white"} _hover={{ background: "#808080", color: 'white' }}
                                    borderRadius={'5%'}
                                    width={"52%"}
                                    ml='5%'
                                >
                                    <ImAttachment ml={2} size={'25px'} />
                                </Button>
                            </FormControl>

                            <FormControl isInvalid={!!formErrors.reason}>
                                Reason
                                <Box>
                                    <Textarea
                                        bg={'white'}
                                        mt={'2%'}
                                        width={'75%'}
                                        placeholder='Describe the reason for your request'
                                        value={formData.reason}
                                        onChange={handleChange}
                                        name='reason'
                                    />
                                </Box>
                                {formErrors.reason && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                        </SimpleGrid>

                        <Flex display={'flex'} justifyContent={'right'}>
                            {documentFile && (
                                <Flex display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}
                                    mr={'5%'} position={"relative"}
                                    mt={'2%'}
                                >
                                    <IoDocument ml={2} size={'45px'} color="#cccccc" />
                                    <Text>
                                        {documentFile.name.length > 20
                                            ? `${documentFile.name.substring(0, 20)}…`
                                            : documentFile.name
                                        }
                                    </Text>
                                    <Button borderRadius={"5px"} cursor={"pointer"} px={2} onClick={removeFile} _hover={{ background: "tr" }} position={"absolute"} top={-2} right={-2}><CloseIcon boxSize={"15px"} /></Button>
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Create <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default FinancialRequestCreate;
