// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Divider, Center } from '@chakra-ui/react';
import CardProfile from 'components/card/cardProfile';
import BonoInformation from './Bono';
import { FaCamera } from "react-icons/fa";

export default function Banner(props) {
  const {
    banner,
    avatar, name, job, dateStart,
    dateStartCalls, user, bonoEnable,
    updateImage, vacationsDay, vacationUse,
    vacationLeft, permissionAvaiable, supervisor, ...rest
  } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );


  return (
    <CardProfile alignItems="center" {...rest} >
      <Box
        bgGradient="linear(to-b, #1c2147, #141833)"
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Box
        position="relative"
        mx="auto"
        mt="-43px"
        cursor="pointer"
        onClick={updateImage}
        _hover={{ ".hoverText": { display: "block" } }}
      >
        <Avatar
          src={avatar}
          h="87px"
          w="87px"
          border="4px solid"
          borderColor={borderColor}
        />
        <Text
          className="hoverText"
          display="none"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="rgba(0, 0, 0, 0.7)"
          color="white"
          p="2px 8px"
          borderRadius="4px"
        >
          <FaCamera />
        </Text>
      </Box>
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="2%">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>

      <Flex w="100%" mt="3%" justifyContent="center">
        <Flex align="center" direction="column">
          <Text color={textColorPrimary} fontSize="120%" fontWeight="700">
            {dateStart}
          </Text>
          <Text color={textColorSecondary} fontSize="100%" fontWeight="400">
            Date start
          </Text>
        </Flex>

        {[22, 36, 43, 44, 52, 53].includes(user.type_user_id) && (
          <Flex mx="8%" align="center" direction="column">
            <Text color={textColorPrimary} fontSize="120%" fontWeight="700">
              {dateStartCalls}
            </Text>
            <Text color={textColorSecondary} fontSize="100%" fontWeight="400">
              Date start calls
            </Text>
          </Flex>
        )}

        {[22, 36, 43, 44, 52, 53].includes(user.type_user_id) && (
          <Flex align="center" direction="column">
            <Text color={textColorPrimary} fontSize="120%" fontWeight="700">
              {supervisor}
            </Text>
            <Text color={textColorSecondary} fontSize="100%" fontWeight="400">
              Supervisor
            </Text>
          </Flex>
        )}
      </Flex>



      <Flex width={'100%'}>
        <Flex direction={'column'} width={'100%'}>
          <Flex w={'100%'} mt="26px" border={'2px'} borderColor={'#f2f2f2'} justifyContent={'space-evenly'} p={'2%'}>
            <Flex direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {vacationLeft}d
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Avaiable
              </Text>
            </Flex>
            <Center height='50px' >
              <Divider orientation='vertical' />
            </Center>
            <Flex direction="column" >
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {vacationUse === 0 ? vacationsDay : vacationUse}d
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Enjoyed
              </Text>
            </Flex>
            <Center height='50px' >
              <Divider orientation='vertical' />
            </Center>
            <Flex direction="column" >
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {vacationLeft}d
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Pending
              </Text>
            </Flex>
            <Center height='50px' >
              <Divider orientation='vertical' />
            </Center>
            <Flex direction="column" >
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {vacationsDay}d
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Total
              </Text>
            </Flex>
          </Flex>
          <Flex display={'flex'} justifyContent={'center'}>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Vacations
            </Text>
          </Flex>
        </Flex>
        {/* <Flex direction={'column'} width={'100%'}>
          <Flex w={'100%'} mt="26px" border={'2px'} borderColor={'#f2f2f2'} justifyContent={'space-evenly'} p={'2%'}>
            <Flex direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {permissionAvaiable === null ? '---' : `${permissionAvaiable}d`}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Avaiable
              </Text>
            </Flex>
            <Center height='50px' >
              <Divider orientation='vertical' />
            </Center>
            <Flex direction="column" >
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                  {permissionAvaiable === null ? '---' : `2d`}
                </Text>
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                Total
              </Text>
            </Flex>
          </Flex>

          <Flex display={'flex'} justifyContent={'center'}>
            <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
              Permissions
            </Text>
          </Flex>
        </Flex> */}
      </Flex>
      {[22, 36, 43, 44, 52, 53].includes(user.type_user_id)
        && [1, 2, 3].includes(user.location_id)
        && [1, 2].includes(user.status_employee_id)
        && bonoEnable
        ? <BonoInformation />
        :
        null
      }
    </CardProfile>
  );
}
