import React, { useState, useEffect } from 'react';
import {
    TableContainer, Button, Flex, useToast
} from '@chakra-ui/react'
import axios from 'axios';
import QAsTable from '../items5_1/table';
import UpdateQAFormats from '../update';
import CreateQuestion from '../modals/create';
import { AddIcon } from '@chakra-ui/icons'
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import QAsTableEndicott from './table';
import Swal from 'sweetalert2'
import QAsTableAnswerPro from './tableAnswerPro';


function UseQAClients({ navigate, client }) {
    const [QA, setQA] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [selectQA, setSelectQA] = useState(null);
    const [flashing, setFlashing] = useState(false);
    const toast = useToast();

    const fetchData = async () => {
        try {
            const response = await axios.get(`/indexQAFormatsClient/${client}`);
            const responseData = response.data;
            setQA(responseData.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [flashing]);

    const handleUpdate = async (QA) => {
        setSelectQA(QA);
        setModalUpdateIsOpen(true);

    };

    const deleteQuestion = async (QA) => {

        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this question?',
            text: "The question will be permanently removed",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            setLoading(true);
            try {
                const response = await axios.delete(`/qaFormats/delete/${QA.id}`);
                toast({
                    title: "Question Delete it!",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();

            } catch (error) {
                console.error(error);

            } finally {
                setLoading(false);
            }
        }
    };


    const handleCreate = async (QA) => {
        setModalCreateIsOpen(true);

    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }
    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }
    const getRowBackground = (QA) => {
        if (selectQA && selectQA.id === QA.id) {
            if (flashing) {
                return '#fcd677';
            }
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return QA.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    const exportQA = () => {
        axios.get(
            `/exportQA/${client}`, {
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'QA.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error al descargar el archivo Excel:', error);
            });
    };


    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <Flex
                    mt={'1%'}
                    direction='column'
                    w='100%'
                    borderRadius="16px"
                >
                    <Flex display={'flex'}>
                        <Button class="buttonBack" onClick={() => navigate("QALayouts")} >
                            Back
                        </Button>
                        <Button class="buttonCreateQuestion" onClick={handleCreate} >
                            Add Question <AddIcon />
                        </Button>
                    </Flex>

                </Flex>
                {client === 38 ? (
                    <QAsTableAnswerPro
                        QA={QA}
                        getRowBackground={getRowBackground}
                        loading={loading}
                        fetchData={fetchData}
                        selectQA={selectQA}
                        setFlashing={setFlashing}
                        handleUpdate={handleUpdate}
                        ModalUpdateIsOpen={ModalUpdateIsOpen}
                        closeModalUpdate={closeModalUpdate}
                        navigate={navigate}
                        closeModalCreate={closeModalCreate}
                        ModalCreateIsOpen={ModalCreateIsOpen}
                        deleteQuestion={deleteQuestion}
                    />
                ) : client === 19 ? (
                    <QAsTableEndicott
                        QA={QA}
                        getRowBackground={getRowBackground}
                        loading={loading}
                        fetchData={fetchData}
                        selectQA={selectQA}
                        setFlashing={setFlashing}
                        handleUpdate={handleUpdate}
                        ModalUpdateIsOpen={ModalUpdateIsOpen}
                        closeModalUpdate={closeModalUpdate}
                        navigate={navigate}
                        closeModalCreate={closeModalCreate}
                        ModalCreateIsOpen={ModalCreateIsOpen}
                        deleteQuestion={deleteQuestion}
                    />
                ) : (
                    <QAsTable
                        QA={QA}
                        getRowBackground={getRowBackground}
                        loading={loading}
                        fetchData={fetchData}
                        selectQA={selectQA}
                        setFlashing={setFlashing}
                        handleUpdate={handleUpdate}
                        ModalUpdateIsOpen={ModalUpdateIsOpen}
                        closeModalUpdate={closeModalUpdate}
                        navigate={navigate}
                        closeModalCreate={closeModalCreate}
                        ModalCreateIsOpen={ModalCreateIsOpen}
                        deleteQuestion={deleteQuestion}
                    />
                )}


            </Flex>
            {ModalUpdateIsOpen && (
                <UpdateQAFormats isOpen={ModalUpdateIsOpen} onClose={closeModalUpdate} QA={selectQA} onQAFormatsUpdated={fetchData} setFlashing={setFlashing} />)}
            {ModalCreateIsOpen && (
                <CreateQuestion isOpen={ModalCreateIsOpen} onClose={closeModalCreate} client={client} data={fetchData} />
            )}
        </TableContainer>
    );
}
export default UseQAClients;