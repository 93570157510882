import React from 'react';
import {
    TableContainer, Table, Thead, Tbody, Tr, Th, Td,
    Text, Flex, FormControl,
    FormErrorMessage, Input
} from '@chakra-ui/react'

function TableCoins({ formErrors, formData, financeBox, formatCurrency, handleChange }) {

    return (
        <TableContainer w={'70%'} mr={'1%'}>
            <Table size={'sm'}>
                <Thead bg={"white"} >
                    <Tr>
                        <Th color='#808080' textAlign={'center'}>Denominations</Th>
                        <Th color='#808080' textAlign={'center'}>New</Th>
                        <Th color='#808080' textAlign={'center'}>Current</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'} >
                            <Flex>
                                <Text alignContent={'center'} width={'5%'} >
                                    10c:
                                </Text>
                                <FormControl isInvalid={!!formErrors.cent_10} >
                                    <Input
                                        type="number"
                                        name='cent_10'
                                        background="white"
                                        value={formData.cent_10}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.cent_10 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.cent_10 || 0) * 0.10}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_cent_10 ? formatCurrency(financeBox.total_cent_10, 'MXN') : '---'}
                        </Td>
                    </Tr>
                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    20c:
                                </Text>
                                <FormControl isInvalid={!!formErrors.cent_20} >
                                    <Input
                                        type="number"
                                        name='cent_20'
                                        background="white"
                                        value={formData.cent_20}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.cent_20 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.cent_20 || 0) * 0.20}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_cent_20 ? formatCurrency(financeBox.total_cent_20, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                            <Text alignContent={'center'} width={'5%'}>
                                    50c:
                                </Text>
                                <FormControl isInvalid={!!formErrors.cent_50} >
                                    <Input
                                        type="number"
                                        name='cent_50'
                                        background="white"
                                        value={formData.cent_50}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.cent_50 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.cent_50 || 0) * 0.50}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_cent_50 ? formatCurrency(financeBox.total_cent_50, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $1:
                                </Text>
                                <FormControl isInvalid={!!formErrors.coin_1} >
                                    <Input
                                        type="number"
                                        name='coin_1'
                                        background="white"
                                        value={formData.coin_1}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.coin_1 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.coin_1 || 0) * 1}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_coin_1 ? formatCurrency(financeBox.total_coin_1, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $2:
                                </Text>
                                <FormControl isInvalid={!!formErrors.coin_2} >
                                    <Input
                                        type="number"
                                        name='coin_2'
                                        background="white"
                                        value={formData.coin_2}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.coin_2 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.coin_2 || 0) * 2}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_coin_2 ? formatCurrency(financeBox.total_coin_2, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $5:
                                </Text>
                                <FormControl isInvalid={!!formErrors.coin_5} >
                                    <Input
                                        type="number"
                                        name='coin_5'
                                        background="white"
                                        value={formData.coin_5}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.coin_5 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.coin_5 || 0) * 5}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_coin_5 ? formatCurrency(financeBox.total_coin_5, 'MXN') : '---'}
                        </Td>
                    </Tr>

                    <Tr className={'gray-row'}>
                        <Td textAlign={'center'} w={'50%'}>
                            <Flex>
                                <Text alignContent={'center'} width={'5%'}>
                                    $10:
                                </Text>
                                <FormControl isInvalid={!!formErrors.coin_10} >
                                    <Input
                                        type="number"
                                        name='coin_10'
                                        background="white"
                                        value={formData.coin_10}
                                        onChange={handleChange}
                                        placeholder='0.00'
                                        errorBorderColor='#fc7466'
                                        w={'60%'}
                                    />

                                    {formErrors.coin_10 && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            </Flex>
                        </Td>
                        <Td textAlign={'center'}>
                            <Text color={'#7080fc'}>
                                ${(formData.coin_10 || 0) * 10}
                            </Text>
                        </Td>
                        <Td textAlign={'center'}>
                            {financeBox.total_coin_10 ? formatCurrency(financeBox.total_coin_10, 'MXN') : '---'}
                        </Td>
                    </Tr>


                </Tbody>
            </Table>
        </TableContainer>

    );
}
export default TableCoins; 