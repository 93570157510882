import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Flex, SimpleGrid,
    FormControl, FormErrorMessage,
    Text,
    useToast, Select,
    Textarea
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons'
const CalendarCreate = ({ isOpen, onClose, onSettingCreated }) => {
    const toast = useToast();
    const [settings, setSettings] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            title: '',
            description: '',
            start: '',
            end: '',
            id_location: '',
            //type: ''
        });
        setFormErrors({});
    };
    useEffect(() => {
        let isMounted = true;
        axios.get('/calendar')
            .then(response => {
                if (isMounted) {
                    setSettings(response.data);
                }
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
            });
        return () => {
            isMounted = false;
        };
    }, []);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        start: '',
        end: '',
        id_location: '',
        //type: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formSubmitData = {
            title: formData.title,
            description: formData.description,
            start: formData.start,
            end: formData.end,
            id_location: formData.id_location,
        };
        try {
            const response = await axios.post('/calendar/create', formSubmitData);
            onSettingCreated();
            toast({
                title: 'Event Created',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating Event",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Creating Event:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent background={'#f2f2f2'} borderRadius={'30px'}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Add Event
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>

                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.title}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Title:
                                </Text>
                                <Input
                                    type="text"
                                    placeholder="Enter Event Title"
                                    name='title'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.title}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.title && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.description}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Description:
                                </Text>
                                <Textarea
                                    type="text"
                                    placeholder="Enter Event Description"
                                    name='description'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.description}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.description && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.start}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Start Date:
                                </Text>
                                <Input
                                    type='date'
                                    bg={'#f2f2f2'}
                                    width={'100%'}
                                    color={'#808080'}
                                    textAlign={'center'}
                                    value={formData.start}
                                    onChange={handleChange}
                                    name='start'
                                    mr={'5%'}
                                />
                                {formErrors.start && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    End Date:
                                </Text>
                                <Input
                                    type='date'
                                    bg={'#f2f2f2'}
                                    width={'100%'}
                                    color={'#808080'}
                                    textAlign={'center'}
                                    value={formData.end}
                                    onChange={handleChange}
                                    name='end'
                                    mr={'5%'}
                                />
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.id_location}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Location:
                                </Text>
                                <Select
                                    w="100%"
                                    fontSize={"md"}
                                    borderRadius={"10px"}
                                    name="id_location"
                                    textAlign={"center"}
                                    background="white"
                                    value={formData.id_location}
                                    onChange={handleChange}
                                    h="44px"
                                    maxh="44px"
                                    errorBorderColor="#fc7466"
                                >
                                    <option value="">Select Location</option>
                                    <option value="1">MX</option>
                                    <option value="2">BZ</option>
                                    <option value="3">RD</option>
                                    <option value="4">COL</option>
                                    <option value="5">GTM</option>
                                </Select>
                                {formErrors.id_location && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Add Event <AddIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};
export default CalendarCreate; 