
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import {
  Flex, Text, Button, useToast
} from '@chakra-ui/react';

//Icons & Buttons
import { IoRibbonOutline } from "react-icons/io5";
import TicketsButtons from "./MenuButtons/ticketsButton";
import NotificationsMenu from "./MenuButtons/notifications";
import ShiftButton from "./MenuButtons/shiftButtons";
import UserMenu from "./MenuButtons/userButtons";

//Modals Finances Tickets
import FinancesMX from './Tickets/FinancesMX';
import FinancesBZ from './Tickets/FinancesBZ';
import FinancesInternational from './Tickets/FinancesInternational';
import FinancialRequest from "./Tickets/FinancesRequest";

//Modal HR & Schedule Ticket
import TicketMedicalNote from './Tickets/MedicalNote';
import StartScheduleModal from './searchBar/ModalStartSchedules';

//Modals Clock in, Clock out, break timer & confirmation schedules
import ModalClock from './searchBar/ModalClock';
import ModalOC from './searchBar/ModalOC';
import Modalbreaktime from './searchBar/Modalbreaktime';
import ModalConfirmation from './searchBar/ModalRead';

//Modal Start Ticket & Join chat
import StartModal from './searchBar/ModalStart';
import ModalChat from './searchBar/ModalChat';
import ModalChatOpen from './searchBar/ModalChatOpen';


//Other modals
import ModalWeeklyBonus from './searchBar/modalWeeklyBonus';
import UpdateModalInfo from './searchBar/ModalUpdate';
import ModalSurvey from './searchBar/ModalSurvey';
import SupervisorPermission from "./NotifyPermission/supervisorP";
import CallCenterManagerPermission from "./NotifyPermission/callCenterManagerP";
import ModalIT from "./Tickets/IT";
import ModalITMG from "./Tickets/ITMG";
import QAClarification from "./Tickets/QA";
import TicketsMGButtons from "./MenuButtons/ticketsMGButton";
import QAIncidence from "./Tickets/QAIncidence";


export default function HeaderLinks(props) {

  const { fetchData, setRead, dataShift, setIsOnBreak, shift, breakS, userAuth, numberNotify, notify, read, isOnBreak, notifyUser, bonoEnable, survey, setSurvey, supervisorPermission, setSupervisorPermission } = props;
  const BASE_URL = '/logout';
  const navigate = useNavigate();
  const toast = useToast();

  //Open Modals
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClock, setModalClock] = useState(false);
  const [modalBreakTime, setModalBreakTime] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalChat, setModalChat] = useState(false);
  const [modalOpenTicket, setModalOpenTicket] = useState(false);
  const [modalWeeklyBonus, setModalWeeklyBonus] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalFinance, setModalFinance] = useState(false);
  const [modalHR, setModalHR] = useState(false);
  const [modalOnCall, setModalOnCall] = useState(false);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [modalIT, setModalIT] = useState(false);
  const [modalFinancialRequest, setModalFinancialRequest] = useState(false);
  const [modalQA, setModalQA] = useState(false);
  const [modalQAIncidence, setModalQAIncidence] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [incidentId, setIncidentId] = useState('');
  const [error, setError] = useState(null);

  //Arrays
  const [openTicket, setOpenTicket] = useState([]);
  const [dataDailyBreak, setDataDailyBreak] = useState([]);
  const [activeModal, setActiveModal] = useState(null);


  const handleLogout = async () => {
    try {
      const response = await axios.post(BASE_URL);
      if (response.data === 'The Token has been deleted') {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };


  const startIncident = async (chat_room_id) => {
    try {
      const response = await axios.post('/start-incident', { chat_room_id });
      const { ticket, message, incident } = response.data;

      if (ticket) {
        setOpenTicket(ticket);
        setModalOpenTicket(true);
      } else {
        setModalContent(message);
        setIncidentId(incident);

        const modalActions = {
          1: setModalIT,
          5: setModalSchedule,
          6: setModalFinance,
          9: setModalQA,
        };

        (modalActions[chat_room_id] || setModalVisible)(true);

        setSelectedIncidentId(chat_room_id);
      }
    } catch (error) {
      setModalContent('Something went wrong while starting an incident.');
      setModalVisible(true);
    }
  };


  const openClockModal = () => {
    setModalClock(true);
  };

  const openModalUpdate = () => {
    setModalUpdate(true);
  };

  const openBreakTimeModal = () => {
    setModalBreakTime(true);
  };

  const openModalChat = () => {
    setModalChat(true);
  };

  const endBreak = async () => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to end your break?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, end it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await axios.get(`/endBreak`);
        fetchData();
        toast({
          title: "Your break has endend",
          description: "Now we're back in action.",
          status: "success",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
        setModalBreakTime(false);
        fetchDataBreaks();
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const ClockOut = async () => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to end your shift?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, end it'
    }).then((result) => {
      return result.isConfirmed
    });

    if (confirmDelete) {
      setLoading(true);
      try {
        const response = await axios.post(`/endShift/${dataShift.id_schedule}`);
        fetchData();
        toast({
          title: "Clocked out!",
          description: "Rest, take care.",
          status: "success",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;

        setModalOnCall(true);

        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleItemClick = (id) => {
    localStorage.setItem('selectedItemId', id);
  };

  const getColorIncident = (chatRoom) => {
    const colorMap = {
      1: '#ff6f94',
      2: '#fabb1c',
      3: '#633cbd',
      4: '#fb9539',
      5: '#a2bd34',
      6: '#6bc2ed',
      7: '#7080fc',
      8: '#fc7466',
    };
    return colorMap[chatRoom] || '#ff6f94';
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleHoverB = () => {
    setIsOnBreak(!isOnBreak);
  };

  const openWeeklyBonus = () => {
    setModalWeeklyBonus(true);
  };

  const buttonText = isHovered ? 'Clock out' : 'Clocked in';

  const breakText = isOnBreak ? 'End break' : 'On break';

  const financeComponents = {
    1: FinancesMX,
    2: FinancesBZ,
    3: FinancesBZ,
    4: FinancesInternational,
    5: FinancesInternational,
    6: FinancesInternational,
  };

  const FinanceComponent = financeComponents[userAuth.location_id];

  const fetchDataBreaks = async () => {
    try {
      const response = axios.get("/getDailyBreak").then((response) => {
        const data = response.data;
        setDataDailyBreak(data);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const startBreak = async (dataDailyBreak) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to start a lunch?',
      html: '<span style="color:#fcb06a; font-weight: bold;">30</span> ' +
        '<span style="font-weight: bold;">Minutes break lunch.</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, Start it!'
    }).then((result) => {
      return result.isConfirmed;
    });

    if (confirmDelete) {
      try {
        const response = await axios.get(`/startBreak/${dataDailyBreak.id}`);
        if (response.status === 200) {
          fetchData();
          toast({
            title: "On break!",
            description: "Take a moment to relax; you've earned it.",
            status: "success",
            position: 'top',
            containerStyle: {
              width: '800px',
              maxWidth: '100%',
            },
            duration: 8000,
            isClosable: true,
          });
          openBreakTimeModal();
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
        toast({
          title: "Attention!",
          description: "You already have an open break, please close the previous one and try again.",
          status: "error",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };

  const createBreak = async (dataShift) => {
    const confirmDelete = await Swal.fire({
      title: 'Do you want to start a break?',
      html: '<span style="color:#7080fc; font-weight: bold;">In addition, you are allowed 30 minutes for breaks throughout your shift.</span> ' +
        'Any extension beyond these allocated 30 minutes will impact your bonuses and overall compensation starting at 30:01. ' +
        '<span style="color:#fc7466; font-weight: bold;">Please consider call volume and align your break times with that of your assigned client.</span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b9ce67',
      cancelButtonColor: '#fc7466',
      confirmButtonText: 'Yes, Start it!'
    }).then((result) => {
      return result.isConfirmed;
    });
    if (confirmDelete) {
      try {
        const response = await axios.post(`/createBreak/${dataShift.id_schedule}`);
        if (response.status === 201) {
          fetchData();
          toast({
            title: "On break!",
            description: "Take a moment to relax; you've earned it.",
            status: "success",
            position: 'top',
            containerStyle: {
              width: '800px',
              maxWidth: '100%',
            },
            duration: 8000,
            isClosable: true,
          });
          openBreakTimeModal();
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errors?.erro;
        setError(errorMessage);
        toast({
          title: "Attention!",
          description: "You already have an open break, please close the previous one and try again.",
          status: "error",
          position: 'top',
          containerStyle: {
            width: '800px',
            maxWidth: '100%',
          },
          duration: 8000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchDataBreaks();
  }, []);


  useEffect(() => {
    // Verificamos las condiciones para abrir el modal correcto
    if (supervisorPermission) {
      if ([17, 18, 41, 42, 45].includes(userAuth.type_user_id)) {
        setActiveModal('supervisor');
      } else if ([13, 25].includes(userAuth.id)) {
        setActiveModal('callCenter');
      }
    }
  }, [supervisorPermission, userAuth]);

  const closeModal = () => {
    setActiveModal(null);
  };

  const openFinancialRequest = () => {
    setModalFinancialRequest(true);
  };

  const openQAIncidence = () => {
    setModalQAIncidence(true);
  };


  return (
    <Flex borderRadius={"25px"} height={"100px"}
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      p="15px"
    >
      {[22, 36, 43, 44, 52, 53].includes(userAuth.type_user_id)
        && [1, 2, 3].includes(userAuth.location_id)
        && [1, 2].includes(userAuth.status_employee_id)
        && bonoEnable
        ? <Button

          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textColor="#1c2147"
          backgroundColor="#f2f2f2"
          borderRadius={'5px'}
          _hover={{
            background: "#424ea8",
            color: "white",
          }}
          mr={3}
          onClick={openWeeklyBonus}
        >
          <IoRibbonOutline size={'20px'} />
          <Text flex={1} textAlign='center' width={"100%"} mr={1} ml={1}>
            Weekly Bonus
          </Text>
        </Button> :
        null
      }

      <ShiftButton
        buttonText={buttonText}
        handleHover={handleHover}
        ClockOut={ClockOut}
        openClockModal={openClockModal}
        shift={shift}
        breakS={breakS}
        dataDailyBreak={dataDailyBreak}
        dataShift={dataShift}
        createBreak={createBreak}
        startBreak={startBreak}
        handleHoverB={handleHoverB}
        endBreak={endBreak}
        breakText={breakText}
      />


      {[22, 36, 43, 44, 52, 53].includes(userAuth.type_user_id)
        ? <TicketsButtons
          startIncident={startIncident}
          userAuth={userAuth}
          openFinancialRequest={openFinancialRequest}
          openQAIncidence={openQAIncidence}
        /> :
        <TicketsMGButtons
          startIncident={startIncident}
          userAuth={userAuth}
          openFinancialRequest={openFinancialRequest}
          openQAIncidence={openQAIncidence}
        />
      }

      <NotificationsMenu
        numberNotify={numberNotify}
        userAuth={userAuth}
        notify={notify}
        handleItemClick={handleItemClick}
        getColorIncident={getColorIncident}
        fetchData={fetchData}
        notifyUser={notifyUser}
      />

      {modalVisible && (
        <StartModal isOpen={modalVisible} onClose={() => setModalVisible(false)} modalContent={modalContent} selectedIncidentId={selectedIncidentId} incidentID={incidentId} openModalChat={openModalChat} />
      )}
      {modalBreakTime && (
        <Modalbreaktime isOpen={modalBreakTime} onClose={() => setModalBreakTime(false)} data={fetchData} endBreak={endBreak} />
      )}
      {modalClock && (
        <ModalClock isOpen={modalClock} onClose={() => setModalClock(false)} data={fetchData} userAuth={userAuth} dataBreaks={fetchDataBreaks} />
      )}
      {read && (
        <ModalConfirmation isOpen={read} onClose={() => setRead(false)} />
      )}
      {survey && (
        <ModalSurvey isOpen={survey} onClose={() => setSurvey(false)} />
      )}
      {modalChat && (
        <ModalChat isOpen={modalChat} onClose={() => setModalChat(false)} selectedIncidentId={selectedIncidentId} incidentID={incidentId} />
      )}

      {modalSchedule && (
        <StartScheduleModal isOpen={modalSchedule} onClose={() => setModalSchedule(false)} modalContent={modalContent} selectedIncidentId={selectedIncidentId} incidentID={incidentId} openModalChat={openModalChat} />
      )}
      {modalOpenTicket && (
        <ModalChatOpen isOpen={(modalOpenTicket)} onClose={() => setModalOpenTicket(false)} ticket={openTicket} />
      )}
      {modalWeeklyBonus && (
        <ModalWeeklyBonus isOpen={openWeeklyBonus} onClose={() => setModalWeeklyBonus(false)} />
      )}
      {modalUpdate && (
        <UpdateModalInfo isOpen={openModalUpdate} onClose={() => setModalUpdate(false)} fetchData={fetchData} user_info={userAuth} />
      )}

      {activeModal === 'supervisor' && (
        <SupervisorPermission isOpen={true} onClose={closeModal} />
      )}

      {activeModal === 'callCenter' && (
        <CallCenterManagerPermission isOpen={true} onClose={closeModal} />
      )}


      {modalFinance && FinanceComponent && (
        <FinanceComponent
          isOpen={modalFinance}
          onClose={() => setModalFinance(false)}
          modalContent={modalContent}
          selectedIncidentId={selectedIncidentId}
          incidentID={incidentId}
          openModalChat={openModalChat}
        />
      )}
      {modalHR && (
        <TicketMedicalNote
          isOpen={modalHR}
          onClose={() => setModalHR(false)}
          selectedIncidentId={selectedIncidentId}
          incidentID={incidentId}
          openModalChat={openModalChat}
          modalContent={modalContent}
        />
      )}

      {modalQA && (
        <QAClarification
          isOpen={modalQA}
          onClose={() => setModalQA(false)}
          selectedIncidentId={selectedIncidentId}
          incidentID={incidentId}
        />
      )}

      {modalIT && (
        [22, 36, 43, 44, 52, 53].includes(userAuth.type_user_id) ? (
          <ModalIT
            isOpen={modalIT}
            onClose={() => setModalIT(false)}
            selectedIncidentId={selectedIncidentId}
            incidentID={incidentId}
            openModalChat={openModalChat}
            modalContent={modalContent}
          />
        ) : (
          <ModalITMG
            isOpen={modalIT}
            onClose={() => setModalIT(false)}
            selectedIncidentId={selectedIncidentId}
            incidentID={incidentId}
            openModalChat={openModalChat}
            modalContent={modalContent}
          />
        )
      )}


      {modalOnCall && (
        <ModalOC
          isOpen={modalOnCall}
          onClose={() => setModalOnCall(false)}
          error={error}
          startIncident={startIncident}
        />
      )}

      {modalFinancialRequest && (
        <FinancialRequest
          isOpen={modalFinancialRequest}
          onClose={() => setModalFinancialRequest(false)}
          userAuth={userAuth}
        />
      )}

      {modalQAIncidence && (
        <QAIncidence
          isOpen={modalQAIncidence}
          onClose={() => setModalQAIncidence(false)}
          userAuth={userAuth}
        />
      )}

      <UserMenu
        openModalUpdate={openModalUpdate}
        handleLogout={handleLogout}
      />

    </Flex>

  );
}
