import React from "react";
import {
    Flex, Button, Box, ButtonGroup, Text, SimpleGrid
} from '@chakra-ui/react';
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";

function FormatsTable({ setCurrentPage, currentPage, formats, total, totalPages, exportCalls }) {
    return (
        <Flex direction="column" width="100%">
            {formats && formats.length > 0 ? (
                <SimpleGrid columns={2} spacing={4} width="100%">
                    {formats.map((item, index) => (
                        <Box
                            key={item.user_id}
                            borderRadius="5px"
                            bg={index % 2 === 0 ? 'white' : '#f2f2f2'}
                            padding={4}
                        >
                            <Flex alignItems="center" justifyContent="space-between">
                                <Text
                                    color="#1c2147"
                                    fontWeight="bold"
                                    width="70%"
                                    isTruncated
                                >
                                    {item.date_formmated}
                                </Text>
                                <Button
                                    onClick={() => exportCalls(item)}
                                    bg="#b9ce67"
                                    borderRadius="5px"
                                    color="white"
                                    _hover={{ background: "#808080" }}
                                >
                                    Check
                                </Button>
                            </Flex>
                        </Box>
                    ))}
                </SimpleGrid>
            ) : (
                <Flex>
                    <Text
                        color="#1c2147"
                        fontSize="25px"
                        textAlign="center"
                        paddingTop="15px"
                        paddingBottom="15px"
                        bg="#f2f2f2"
                        fontFamily="DM Sans"
                        letterSpacing="-1px"
                        width="100%"
                    >
                        No data
                    </Text>
                </Flex>
            )}
            <Box width={"100%"} display={"flex"} mt={"30px"}>

                <ButtonGroup isAttached variant="outline" justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={2}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </Flex>
    );
}

export default FormatsTable;
