import React from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup, Text, Flex, Box, Badge,
} from '@chakra-ui/react'
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { MdEditDocument } from 'react-icons/md';

function QATotals({ total, currentPage, QATotals, setCurrentPage, totalPages, Weeks }) {

    return (
        <>
            <Table size={'sm'} mt={"2%"}>
                <Thead bg={"white"}>
                    <Tr>
                        <Th textAlign={'center'}>Agent</Th>
                        <Th textAlign={'center'}>Team</Th>
                        {Weeks && Weeks.length > 0 ? (
                            Weeks.map((item, index) => (
                                <Th key={index} textAlign={'center'}>
                                    {item.startFormat} - {item.endFormat}
                                </Th>
                            ))
                        ) : (
                            <Th textAlign={'center'}>No Weeks Selected</Th>
                        )}

                        {/* Si Weeks tiene 4 o menos, agregamos la columna vacía con --- */}
                        {Weeks && Weeks.length <= 4 ? (
                            <Th textAlign={'center'}>---</Th>
                        ) : null}

                        <Th textAlign={'center'}>Monthly AVG</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {QATotals && QATotals.length > 0 ? (
                        QATotals.map((item, index) => (
                            <Tr key={item.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'}>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.name.split(' ')[0]} {item.last_name.split(' ')[0]}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    <Badge color={"white"} bg={'#424ea8'} borderRadius={'5px'} width={'100px'} fontSize={'10px'} whiteSpace={'pre-wrap'}>
                                        {item.client_name ? item.client_name : item.client}
                                    </Badge>
                                </Td>

                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.Week_1}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.Week_2}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.Week_3}
                                </Td>
                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.Week_4}
                                </Td>

                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.Week_5}
                                </Td>

                                <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"}>
                                    {item.avg_percentWeeks}
                                </Td>


                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Th colSpan={'9'} >
                                <Text
                                    color='#1c2147'
                                    fontSize={"25px"}
                                    textAlign={"center"}
                                    paddingTop={"15px"}
                                    paddingBottom={"15px"}
                                    bg={"#f2f2f2"}
                                    textTransform={'none'}
                                    fontFamily={'DM Sans'}
                                    letterSpacing="-1px"
                                >
                                    No data
                                </Text>
                            </Th>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2}>
                                    <MdEditDocument color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No totals
                                </Text>


                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <MdEditDocument color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                                    {total === 1 ? 'Total' : 'Totals'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"26%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>

    );
}
export default QATotals; 