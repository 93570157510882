import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Box,
    FormControl, FormErrorMessage,
    Text, Flex, SimpleGrid, useToast, Textarea,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import TableCoins from './financeBox/tableCoins';
import TableBills from './financeBox/tableBills';


const ModalWeeklyCash = ({ isOpen, onClose, data, financeBox, location }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        amount: '',
        id_location: location,
        comment: '',
        cent_10: financeBox?.cent_10 || '',
        cent_20: financeBox?.cent_20 || '',
        cent_50: financeBox?.cent_50 || '',
        coin_1: financeBox?.coin_1 || '',
        coin_2: financeBox?.coin_2 || '',
        coin_5: financeBox?.coin_5 || '',
        coin_10: financeBox?.coin_10 || '',
        ticket_20: financeBox?.ticket_20 || '',
        ticket_50: financeBox?.ticket_50 || '',
        ticket_100: financeBox?.ticket_100 || '',
        ticket_200: financeBox?.ticket_200 || '',
        ticket_500: financeBox?.ticket_500 || '',
        ticket_1000: financeBox?.ticket_1000 || '',
    });

    const handleSubmit = async (id) => {
        const totalAmount = calculateTotal();

        const formSubmitData = {
            amount: totalAmount,
            id_location: formData.id_location,
            comment: formData.comment,
            cent_10: formData.cent_10,
            cent_20: formData.cent_20,
            cent_50: formData.cent_50,
            coin_1: formData.coin_1,
            coin_2: formData.coin_2,
            coin_5: formData.coin_5,
            coin_10: formData.coin_10,
            ticket_20: formData.ticket_20,
            ticket_50: formData.ticket_50,
            ticket_100: formData.ticket_100,
            ticket_200: formData.ticket_200,
            ticket_500: formData.ticket_500,
            ticket_1000: formData.ticket_1000,
        };

        try {
            const response = await axios.post(`/financeBox/close`, formSubmitData);
            toast({
                title: 'Weekly Cash Register Closing Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error closing cash register",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error updating the cash fund:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const formatCurrency = (value, currency) => {
        if (value === null || value === undefined) {
            return '----';
        }
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency
        }).format(value);
    };

    const calculateTotal = () => {
        const total =
            (formData.cent_10 || 0) * 0.10 +
            (formData.cent_20 || 0) * 0.20 +
            (formData.cent_50 || 0) * 0.50 +
            (formData.coin_1 || 0) * 1 +
            (formData.coin_2 || 0) * 2 +
            (formData.coin_5 || 0) * 5 +
            (formData.coin_10 || 0) * 10 +
            (formData.ticket_20 || 0) * 20 +
            (formData.ticket_50 || 0) * 50 +
            (formData.ticket_100 || 0) * 100 +
            (formData.ticket_200 || 0) * 200 +
            (formData.ticket_500 || 0) * 500 +
            (formData.ticket_1000 || 0) * 1000;

        return total.toFixed(2); // Redondea a 2 decimales
    };

    return (
        <Modal size={'5xl'} isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'1%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Weekly Cash Reconciliation
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>

                    <Flex display={'flex'} justifyContent={'space-around'}>
                        <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                            Current Amount: {location === 1 ? formatCurrency(financeBox.total_mx, 'MXN') : formatCurrency(financeBox.total_bz, 'BZD')}
                        </Text>

                        <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'}>
                            Total Amount: {formatCurrency(calculateTotal(), 'MXN')}
                        </Text>
                    </Flex>


                    <Flex mt={'2%'}>
                        <TableCoins
                            formErrors={formErrors}
                            formData={formData}
                            financeBox={financeBox}
                            formatCurrency={formatCurrency}
                            handleChange={handleChange}
                        />

                        <TableBills
                            formErrors={formErrors}
                            formData={formData}
                            financeBox={financeBox}
                            formatCurrency={formatCurrency}
                            handleChange={handleChange}
                        />
                    </Flex>


                    <Box>

                        <Flex display={'flex'} direction={'column'} p={'2%'}>
                            <Text fontWeight={'bold'} color={'#808080'} whiteSpace={'pre-wrap'}>
                                Last Comment: {financeBox.comment ? financeBox.comment : '---'}
                            </Text>

                            <Text fontWeight={'bold'} color={'#808080'}>
                                Last Updated: {financeBox.date ? financeBox.date : '---'}
                            </Text>
                        </Flex>

                        <SimpleGrid
                            mt={'2%'}
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.comment}>
                                <Text>Note:</Text>
                                <Textarea borderColor={"#b3b3b3"}
                                    placeholder="Add a description here..."
                                    mt={'2%'}
                                    name="comment"
                                    minHeight={"200px"}
                                    maxHeight={"510px"}
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                >
                                </Textarea>
                                {formErrors.comment && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" mb={'5%'} mt={'2%'}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Cash Closing
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default ModalWeeklyCash;