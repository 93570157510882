import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box, Flex, SimpleGrid, FormControl, FormErrorMessage, useToast, Text
} from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons'

const FinancialUserRequestUpdate = ({ isOpen, onClose, data, financialRequest }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});

    const [formData, setFormData] = useState({
        amount_mx: financialRequest?.amount_mx || '',
        amount_bz: financialRequest?.amount_bz || '',
        id_location: financialRequest?.id_location?.toString() || '',
    });

    const clearForm = () => {
        setFormData({
            amount_mx: '',
            amount_bz: '',
        });
        setFormErrors({});
    };

    const handleSubmit = async () => {

        const formDataSend = new FormData();
        formDataSend.append('amount_bz', formData.amount_bz);
        formDataSend.append('amount_mx', formData.amount_mx);

        try {
            const response = await axios.post(`/financialUserRequest/update/${financialRequest.id}`, formDataSend);
            toast({
                title: 'Financial Request Updated Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Creating a Financial Request",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error creating vacation:', error);
            setFormErrors(error.response.data.errors);
        }
    };
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size='2xl' isOpen={isOpen} onClose={() => {
            onClose();
            clearForm();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Update Financial Request
                        </Text>

                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                           

                            <FormControl
                                mt={4}
                                isInvalid={
                                    !!formErrors.amount_mx || !!formErrors.amount_bz
                                }
                            >
                                Amount {formData.id_location === "1" ? '$MX' : '$BZ'} & $US
                                {formData.id_location === "1" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_mx}
                                            onChange={handleChange}
                                            name="amount_mx"
                                            placeholder="Enter MX amount"
                                        />
                                        {formErrors.amount_mx && (
                                            <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                        )}
                                    </>
                                )}
                                {formData.id_location === "2" && (
                                    <>
                                        <Input
                                            ml={'5%'}
                                            type="number"
                                            background="white"
                                            width="58%"
                                            color="#808080"
                                            textAlign="center"
                                            value={formData.amount_bz}
                                            onChange={handleChange}
                                            name="amount_bz"
                                            placeholder="Enter BZ amount"
                                        />
                                        {formErrors.amount_bz && (
                                            <FormErrorMessage w={'75%'} justifyContent={'center'}>This field is required.</FormErrorMessage>
                                        )}
                                    </>
                                )}
                            </FormControl>
                        </SimpleGrid>
                    </Box>

                </ModalBody>
                <ModalFooter justifyContent="center" mt={"5%"} mb={'5%'}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Updated <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                        clearForm();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default FinancialUserRequestUpdate;
