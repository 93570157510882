import {
    useColorModeValue, Text, Flex, Box,
} from '@chakra-ui/react'
import React, { useEffect, useState } from "react";
import axios from 'axios';
import CardProfile from 'components/card/cardProfile';
import FormatsFilters from './formats/filters';
import FormatsTable from './formats/table';


export default function FormatsProfile(props) {

    const { openModalFormats, ...rest } = props;
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const [formats, setFormats] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [selectQA, setSelectQA] = useState(null);
    const [flashing, setFlashing] = useState(false);

    const clearFilters = () => {
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
    }

    const fetchDataFormats = async () => {

        try {
            const response = await axios.get(
                `/getCalls?page=${currentPage}`
                + `${filterDate ? `&startDate=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&endDate=${filterDateEnd.trim()}` : ''}`
            );

            const responseData = response.data;
            setFormats(responseData.data.data);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)

        } catch (error) {

            console.error('Error al obtener datos del usuario:', error);

        }
    };

    useEffect(() => {
        fetchDataFormats();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, filterDate, filterDateEnd, flashing]);

    const getRowBackground = (call) => {
        if (selectQA && selectQA.id === call.id) {
            if (flashing) {
                return '#fcd677';
            }
            return 'white';
        } else {
            return 'white';
        }
    };

    return (
        <CardProfile  {...rest}>
            <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                ml={'2%'}
            >
                QA Formats
            </Text>
            <Box p={4}>
                <Box >
                    <Flex
                        direction='column'
                        w='100%'
                    >
                        <FormatsFilters
                            filterDate={filterDate}
                            setFilterDate={setFilterDate}
                            setCurrentPage={setCurrentPage}
                            filterDateEnd={filterDateEnd}
                            setFilterDateEnd={setFilterDateEnd}
                            clearFilters={clearFilters}
                        />
                        <FormatsTable
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            formats={formats}
                            total={total}
                            totalPages={totalPages}
                            exportCalls={openModalFormats}
                            getRowBackground={getRowBackground}
                        />
                    </Flex >
                </Box>
            </Box>

        </CardProfile>
    );
}
