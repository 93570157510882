import React from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, 
    Flex, Text, Box, Image
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import logo from "assets/img/dashboards/Logo3.png";

function InfoMessage({ isOpen, onClose, message }) {

    const renderUserImage = (message) => {
        const imageSrc = message.base64Image && message.imageMimeType
            ? `data:${message.imageMimeType};base64,${message.base64Image}`
            : logo;

        return (
            <Image
                src={imageSrc}
                h="50%"
                w="50%"
                mb={'2%'}
                cursor={"pointer"}
            />
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'2xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent alignItems={"center"} alignContent={"center"}>
                <Flex flexDirection={"column"} width={'85%'} p={'2%'}>
                    <ModalHeader textColor='#1c2147' display={'flex'} fontSize={'200%'} mt={'5%'} justifyContent={'center'}>
                        Info Message
                    </ModalHeader>

                    <Box
                        borderRadius={"5px"}
                        display="flex" bg={"#f2f2f2"}
                        flexDirection="column"
                        width={'100%'}
                        alignSelf={'center'}
                        p={'2%'}
                    >
                        <Text color={'#1c2147'} fontWeight={"bold"}>
                            {message.user_id === 1 ? "Dedicated Portal System" : `${message.users?.name} ${message.users?.last_name}`}
                        </Text>
                        <Text color={'#424ea8'} fontWeight={"bold"} >
                            {message.message}
                        </Text>

                        {message.image_path && (
                          <>
                            {renderUserImage(message)}
                          </>
                        )}

                        <Text color={'#424ea8'} fontSize={'75%'}>
                            {message.created_at}
                        </Text>
                    </Box>

                    <Box
                        mt={'3%'}
                        borderRadius={"5px"}
                        display="flex" bg={"#edeffc"}
                    >
                        <Flex display={'flex'} direction={'column'} p={'2%'} width={'100%'} overflowY={'auto'} maxHeight={'600px'}>
                            {message.message_seen && message.message_seen.length > 0 ? (
                                message.message_seen.map((item, index) => {
                                    return (
                                        <Flex key={item.id} display={'flex'} direction={'column'} mt={'2%'}>
                                            <Text color={'#424ea8'} fontWeight={"bold"}>
                                                {item.name} {item.last_name}
                                            </Text>
                                            <Text color={'#1c2147'} fontWeight={"bold"}>
                                                {item.position}
                                            </Text>
                                            <Text color={'#1c2147'} fontSize={'75%'}>
                                                {item.seen_at}
                                            </Text>
                                        </Flex>
                                    );
                                })
                            ) : (
                                <Flex height={"max-content"} flexDirection={"row"} textAlign={'center'} mt={"2%"} >
                                    <Text fontWeight={"bold"} width={"100%"} textColor={"#666666"} >No data</Text>
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                </Flex>
                <ModalFooter justifyContent="center" mt={'5%'} mb={'5%'}  >
                    <Button
                        bg="#1c2147" color={'white'}
                        _hover={{ background: "#808080", color: 'white' }}
                        mr={3}
                        onClick={onClose}
                        borderRadius={'5px'}
                        size={'md'}
                    >
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default InfoMessage; 