import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Flex, TableContainer
} from '@chakra-ui/react'
import FinancialUserRequestFilters from './filters';
import FinancialUserRequest from './table';
import ModalNotesFinancialRequest from '../items/notes';
import FinancialUserRequestUpdate from './update';

function UseFinancialUserRequest() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState('');
    const [statusSearch, setStatusSearch] = useState(1);
    const [financialRequest, setFinancialRequest] = useState([]);
    const [resetKey, setResetKey] = useState(0);
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [selectFinancial, setSelectFinancial] = useState(null);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalNotes, setModalNotes] = useState(false);

    const clearFilters = () => {
        setFilterDate('');
        setFilterDateEnd('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `/financialUserRequest?page=${currentPage}`
                + `${statusSearch ? `&status=${encodeURIComponent(statusSearch)}` : ''}`
                + `${filterDate ? `&startDate=${encodeURIComponent(filterDate)}` : ''}`
                + `${filterDateEnd ? `&endDate=${encodeURIComponent(filterDateEnd)}` : ''}`
            );
            const responseData = response.data;
            setFinancialRequest(responseData.data.data);
            setTotal(responseData.data.total)
            setTotalPages(responseData.data.last_page);
        } catch (error) {
            console.error('Error fetching Vacations:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage, resetKey, statusSearch, filterDate, filterDateEnd]);


    const handleStatusChange = (status) => {
        setStatusSearch(status);
    };

    const handleNotes = (financialRequest) => {
        setModalNotes(true);
        setSelectFinancial(financialRequest);
    }

    const handleUpdate = async (financialRequest) => {
        setModalUpdate(true);
        setSelectFinancial(financialRequest);
    }

    return (
        <TableContainer>
            <Flex
                direction='column'
                w='100%'
            >
                <FinancialUserRequestFilters
                    setCurrentPage={setCurrentPage}
                    resetKey={resetKey}
                    clearFilters={clearFilters}
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    filterDateEnd={filterDateEnd}
                    setFilterDateEnd={setFilterDateEnd}
                />
                <FinancialUserRequest
                    total={total}
                    currentPage={currentPage}
                    financialRequest={financialRequest}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    loading={loading}
                    handleStatusChange={handleStatusChange}
                    statusSearch={statusSearch}
                    handleUpdate={handleUpdate}
                    handleNotes={handleNotes}
                />
            </Flex >
            {modalNotes && (
                <ModalNotesFinancialRequest
                    isOpen={modalNotes}
                    onClose={() => setModalNotes(false)}
                    financialRequest={selectFinancial}
                    data={fetchData}
                />
            )}
            {modalUpdate && (
                <FinancialUserRequestUpdate
                    isOpen={modalUpdate}
                    onClose={() => setModalUpdate(false)}
                    data={fetchData}
                    financialRequest={selectFinancial}
                />
            )}
        </TableContainer >

    );
}
export default UseFinancialUserRequest; 