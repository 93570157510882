import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Box,
    FormControl, FormErrorMessage,
    Text, Flex, SimpleGrid, useToast, Textarea
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'


const FinaceBoxBelize = ({ isOpen, onClose, data, financeBox, location }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        addAmount: '',
        subtractAmount: '',
        id_location: location,
        comment: '',
    });

    const handleSubmit = async (id) => {
        let totalAmount = 0;

        // Validar si se suma o se resta
        if (formData.addAmount > 0) {
            totalAmount = calculateTotal();
        } else if (formData.subtractAmount > 0) {
            totalAmount = calculateTotal();
        }

        // Construir el objeto formSubmitData
        const formSubmitData = {
            addAmount: formData.addAmount > 0 ? totalAmount : null, // Guardar solo si hay un valor positivo
            subtractAmount: formData.subtractAmount > 0 ? totalAmount : null, // Guardar solo si hay un valor positivo
            id_location: formData.id_location,
            comment: formData.comment,
        };

        try {
            const response = await axios.post(`/financeBox/store`, formSubmitData);
            toast({
                title: 'Cash fund updated successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            data();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Updating the cash fund",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error updating the cash fund:', error);
            setFormErrors(error.response.data.errors);
        }
    };


    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const formatCurrency = (value, currency) => {
        if (value === null || value === undefined) {
            return '----';
        }
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency
        }).format(value);
    };


    const calculateTotal = () => {
        const addAmount = parseFloat(formData?.addAmount) || 0; // Asegúrate de que formData tiene este campo
        const subtractAmount = parseFloat(formData?.subtractAmount) || 0;
        const total_bz = parseFloat(financeBox?.total_bz) || 0; // Validar total_mx también

        const total = total_bz + addAmount - subtractAmount;

        return Number.isFinite(total) ? total.toFixed(2) : "0.00"; // Asegúrate de que el total sea válido
    };




    return (
        <Modal size={'3xl'} isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent borderRadius={"30px"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'1%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Cash Fund
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>

                    <Flex display={'flex'} justifyContent={'space-around'}>
                        <Text fontWeight={'bold'} color={'#424ea8'} fontSize={'18px'}>
                            Current Amount: {formatCurrency(financeBox.total_bz, 'BZD')}
                        </Text>

                        <Text fontWeight={'bold'} color={formData.subtractAmount > 0 ? '#de5b5b' : '#8aad01'} fontSize={'20px'}>
                            Total Amount: {formatCurrency(calculateTotal(), 'BZD')}
                        </Text>
                    </Flex>

                    <Flex display={'flex'} mt={'2%'} ml={'8%'}>
                        <Text fontWeight={'bold'} color={'#8aad01'} fontSize={'20px'} mr={'8.5%'}>
                            Add money:
                        </Text>
                        <Input
                            type="number"
                            background="white"
                            width="50%"
                            color="#808080"
                            textAlign="center"
                            value={formData.addAmount || ''}
                            onChange={handleChange}
                            name="addAmount"
                            placeholder="Amount to Add"
                        />
                    </Flex>

                    <Flex display={'flex'} mt={'2%'} ml={'8%'}>
                        <Text fontWeight={'bold'} color={'#de5b5b'} fontSize={'20px'} mr={'5%'}>
                            Unless money:
                        </Text>
                        <Input
                            type="number"
                            background="white"
                            width="50%"
                            color="#808080"
                            textAlign="center"
                            value={formData.subtractAmount || ''}
                            onChange={handleChange}
                            name="subtractAmount"
                            placeholder="Amount to Subtract"
                        />
                    </Flex>


                    <Box>

                        <Flex display={'flex'} direction={'column'} p={'2%'}>
                            <Text fontWeight={'bold'} color={'#808080'} whiteSpace={'pre-wrap'}>
                                Last Comment: {financeBox.comment ? financeBox.comment : '---'}
                            </Text>

                            <Text fontWeight={'bold'} color={'#808080'}>
                                Last Updated: {financeBox.date ? financeBox.date : '---'}
                            </Text>
                        </Flex>

                        <SimpleGrid
                            mt={'2%'}
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.comment}>
                                <Text>Note:</Text>
                                <Textarea borderColor={"#b3b3b3"}
                                    placeholder="Add a description here..."
                                    mt={'2%'}
                                    name="comment"
                                    minHeight={"200px"}
                                    maxHeight={"510px"}
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                >
                                </Textarea>
                                {formErrors.comment && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" mb={'5%'} mt={'2%'}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update Cash Fund
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default FinaceBoxBelize;