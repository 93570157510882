import { Flex, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons'
import UseFinanceBoxMx from "./items/functions";
import UseFinanceBoxBZ from "./items/BZ/functions";

export default function FinanceBoxReportOverview() {

  const [showDailyList, setShowDailyList] = useState(false);
  const toggleView = () => {
    setShowDailyList(!showDailyList);
  };

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }} >
      <Card px='100px'>
        <Flex align="center" justifyContent='flex-start' mt={4}>
          <Button
            bg={showDailyList ? "#a2bd34" : "#424ea8"}
            color={'white'}
            mr={'2%'}
            borderRadius={'5px'}
            display={'flex'}
            justifyContent={'space-between'}
            onClick={toggleView}
            alignItems={'center'}
            _hover={{ background: "#808080", color: 'white' }}
          >
            {showDailyList ? "Go to the México Report" : "Go to the Belize Report"}
          </Button>
        </Flex>
        {showDailyList ? (
          <UseFinanceBoxBZ />
        ) : (
          <UseFinanceBoxMx />
        )}
      </Card>
    </Flex>
  );
} 