import React from "react";
import {
    Flex, Input, Button, Box, Text
} from '@chakra-ui/react';
import { AiOutlineClear } from "react-icons/ai";
import { RepeatIcon } from '@chakra-ui/icons'

function FormatsFilters({ filterDate, setFilterDate, setCurrentPage, filterDateEnd, setFilterDateEnd, clearFilters }) {

    return (
        <Flex
            direction='column' justify={"space-evenly"}
            w='100%'
            borderRadius="16px"
        >

            <Flex flexDirection={"column"} bg="gray.200" p={'2%'} borderRadius={"5px"} >
                <Flex align="center"    >

                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={'1%'} mr={'1%'}
                    >
                        <Input

                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDate}
                            onChange={(e) => {
                                setFilterDate(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDate('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>
                    <Flex
                        border={'1px solid #808080'}
                        display={'flex'}
                        borderRadius={'4px'}
                        ml={'1%'} mr={'1px'}
                    >
                        <Input
                            border={'0px'}
                            type="date"
                            fontSize={"sm"}
                            value={filterDateEnd}
                            onChange={(e) => {
                                setFilterDateEnd(e.target.value);
                                setCurrentPage(1);
                            }}
                            _focusVisible={'none'}
                            color={'#808080'}
                        />
                        <Button bg={'none'} color={'#808080'} onClick={() => setFilterDateEnd('')}>
                            <RepeatIcon />
                        </Button>
                    </Flex>

                    <Box height={"max-content"} width={"max-content"} >
                        <Button onClick={clearFilters} bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" >
                            <AiOutlineClear size={'65%'} />
                        </Button>
                    </Box>
                </Flex>


            </Flex>

            <Flex align="center"    >

                <Text color={"#1c2147"}  marginTop={'3%'} marginBottom={'5%'}>
                    "Here you can access the feedback provided by the QA department on your calls. Click the check button to review the details."
                </Text>
            </Flex>
        </Flex>
    );
}

export default FormatsFilters;