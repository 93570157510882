import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, Thead, Tbody, Tr, Th, Td, TableContainer,
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Flex, Text, Box, Badge
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons'
import { AiOutlineUser } from "react-icons/ai";

function RequestAudits({ isOpen, onClose, financialRequest }) {

    const [audit, setAudit] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`/financialRequest/auditdetail/${financialRequest.id}`);
            const responseData = response.data;
            setAudit(responseData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const getColorSchemeForStatus = (status) => {
        const colorMap = {
            "1": '#424ea8',
            "2": '#808080',
            "3": '#424ea8',
            "4": '#808080',
            "5": '#424ea8',
            "6": '#808080',
            "7": '#424ea8',
            "8": '#808080',
            "9": '#424ea8',
            "10": '#808080',
            "11": '#424ea8',
            "12": '#808080',
            "13": '#424ea8',
            "14": '#808080',
            "15": '#808080',
            1: '#424ea8',
            2: '#808080',
            3: '#424ea8',
            4: '#808080',
            5: '#424ea8',
            6: '#808080',
            7: '#424ea8',
            8: '#808080',
            9: '#424ea8',
            10: '#808080',
            11: '#424ea8',
            12: '#808080',
            13: '#424ea8',
            14: '#808080',
            15: '#808080',
        };
        return colorMap[status] || 'transparent';
    };

    const getColorSchemeForEvent = (event) => {
        const colorMap = {
            "Created": '#3644a9',
            "Updated": '#bc9ae9',
            "Deleted": '#fc7466',
        };
        return colorMap[event] || '#3644a9';
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} isCentered closeOnOverlayClick={false}
            closeOnEsc={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader mt={'70px'} display={'flex'} justifyContent={'center'}>
                    <Flex direction={'column'}>
                        <Text fontSize={'35px'} color={'#1c2147'} display={'flex'} justifyContent={'center'} ml={'40px'}>
                            <Box mr={2} mb={1}>
                                <TimeIcon />
                            </Box>
                            Audit History
                        </Text>
                        <Text color={'#808080'} display={'flex'} justifyContent={'center'} mb={'30px'}>
                            <Box mr={2}>
                                <AiOutlineUser size={'25px'} />
                            </Box>
                            <Text mr={'20px'}>
                                {financialRequest.users}
                            </Text>
                            |
                            <Text ml={'20px'}>
                                {financialRequest.date}
                            </Text>
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <TableContainer>
                        <Table>
                            <Thead bg={"white"}>
                                <Tr bg={'white'}>
                                    <Th textAlign={'center'}>User</Th>
                                    <Th textAlign={'center'}>Date Modified</Th>
                                    <Th textAlign={'center'}>Event</Th>
                                    <Th textAlign={'center'}>Original Values</Th>
                                    <Th textAlign={'center'}>Modified Values</Th>

                                </Tr>
                            </Thead>
                            <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"} overflowY="auto">
                                {audit && audit.length > 0 ? (
                                    audit.map((auditItem, index) => (
                                        <Tr key={auditItem.id} className={index % 2 === 0 ? 'white-row' : 'gray-row'} mb={'50px'}>
                                            <Td textAlign={'center'}>
                                                {auditItem.name}
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Flex direction={'column'} display={'flex'} alignItems={'center'}>
                                                    <Flex color={'#808080'}>
                                                        {auditItem.created_date}
                                                    </Flex>
                                                    <Flex color={'#808080'}>
                                                        {auditItem.created_time}
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Badge color={"white"} bg={getColorSchemeForEvent(auditItem.event)} borderRadius={"5px"}>
                                                    {auditItem.event}
                                                </Badge>
                                            </Td>
                                            <Td>
                                                <Flex display={'flex'} direction={'column'} alignItems={'center'}>
                                                    {auditItem.old.id_category && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Category:</span> {auditItem.old.id_category}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.date && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Date:</span> {auditItem.old.date}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.reason && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}> Reason:</span> {auditItem.old.reason}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.amount_mx && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount MX:</span> {auditItem.old.amount_mx}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.amount_bz && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount BZ:</span> {auditItem.old.amount_bz}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.amount_us && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount US:</span> {auditItem.old.amount_us}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.payment_method !== undefined && auditItem.old.payment_method !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.old.payment_method)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.old.payment_method) {
                                                                            case "1":
                                                                                return "Effective";
                                                                            case "2":
                                                                                return "Transfer";
                                                                            case "3":
                                                                                return "Credit Card";
                                                                            case 1:
                                                                                return "Effective";
                                                                            case 2:
                                                                                return "Transfer";
                                                                            case 3:
                                                                                return "Credit Card";
                                                                            default:
                                                                                return "Effective";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.old.status !== undefined && auditItem.old.status !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.old.status)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.old.status) {
                                                                            case "1":
                                                                                return "In Review";
                                                                            case "2":
                                                                                return "Approved";
                                                                            case "3":
                                                                                return "Denied";
                                                                            case 1:
                                                                                return "In Review";
                                                                            case 2:
                                                                                return "Approved";
                                                                            case 3:
                                                                                return "Denied";
                                                                            default:
                                                                                return "In Review";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Flex display={'flex'} direction={'column'} alignItems={'center'}>
                                                    {auditItem.new.id_category && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Category:</span> {auditItem.new.id_category}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.date && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Date:</span> {auditItem.new.date}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.reason && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}> Reason:</span> {auditItem.new.reason}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.amount_mx && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount MX:</span> {auditItem.new.amount_mx}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.amount_bz && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount BZ:</span> {auditItem.new.amount_bz}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.amount_us && (

                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <span style={{ color: '#999999', marginRight: '5px' }}>Amount US:</span> {auditItem.new.amount_us}
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.payment_method !== undefined && auditItem.new.payment_method !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.new.payment_method)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.payment_method) {
                                                                            case "1":
                                                                                return "Effective";
                                                                            case "2":
                                                                                return "Transfer";
                                                                            case "3":
                                                                                return "Credit Card";
                                                                            case 1:
                                                                                return "Effective";
                                                                            case 2:
                                                                                return "Transfer";
                                                                            case 3:
                                                                                return "Credit Card";
                                                                            default:
                                                                                return "Effective";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                    {auditItem.new.status !== undefined && auditItem.new.status !== null && (
                                                        <Flex mb={'5px'}>
                                                            <>
                                                                <Badge color={"white"} bg={getColorSchemeForStatus(auditItem.new.status)} borderRadius={"5px"}>
                                                                    {(() => {
                                                                        switch (auditItem.new.status) {
                                                                            case "1":
                                                                                return "In Review";
                                                                            case "2":
                                                                                return "Approved";
                                                                            case "3":
                                                                                return "Denied";
                                                                            case 1:
                                                                                return "In Review";
                                                                            case 2:
                                                                                return "Approved";
                                                                            case 3:
                                                                                return "Denied";
                                                                            default:
                                                                                return "In Review";
                                                                        }
                                                                    })()}
                                                                </Badge>
                                                            </>
                                                        </Flex>
                                                    )}
                                                </Flex>
                                            </Td>

                                        </Tr>
                                    ))
                                ) : (
                                    <Tr>
                                        <Th colSpan={'9'} >
                                            <Text
                                                color='#1c2147'
                                                fontSize={"25px"}
                                                textAlign={"center"}
                                                paddingTop={"15px"}
                                                paddingBottom={"15px"}
                                                bg={"#f2f2f2"}
                                                textTransform={'none'}
                                                fontFamily={'DM Sans'}
                                                letterSpacing="-1px"
                                            >
                                                No data
                                            </Text>
                                        </Th>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter justifyContent="center" mt={'40px'} mb={'20px'}>
                    <Button bg="#7080fc" color={'white'} _hover={{ background: "#808080", color: 'white' }} mr={3} onClick={onClose} borderRadius={'5px'} size={'md'}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
export default RequestAudits; 