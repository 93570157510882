
import React from "react";
import {
    Menu, MenuButton, MenuList, Text, Button, Box, MenuItem
} from '@chakra-ui/react';

//Icons & Buttons
import { BiLogOut } from "react-icons/bi";
import { FaUserCog } from "react-icons/fa";
import { EditIcon } from '@chakra-ui/icons'
export default function UserMenu(props) {

    const { openModalUpdate, handleLogout } = props;

    return (
        <>
            <Menu>
                <MenuButton
                    mr={4} borderRadius={"10%"}
                    backgroundColor="#f2f2f2"
                    as={Button}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                    position={"relative"}
                    _hover={{ background: "#cbd5e0" }}
                >
                    <Box ml={-1} width={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                        <FaUserCog color='#1c2147' size={"26px"} />
                    </Box>
                </MenuButton>
                <MenuList>
                    <MenuItem>
                        <Button
                            height={"40px"}
                            width={'100%'}
                            borderRadius={"5%"}
                            px={"11px"}
                            bg="#1c2147"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            cursor={"pointer"}
                            position={"relative"}
                            onClick={openModalUpdate}
                            _hover={{ background: "#cbd5e0", color: "#1c2147" }}
                            color="white"
                        >
                            <Text>
                                Update Information
                            </Text>
                            <EditIcon ml='5px' size={'22px'} />
                        </Button>
                    </MenuItem>
                    <MenuItem>
                        <Button
                            height={"40px"}
                            width={'100%'}
                            borderRadius={"5%"}
                            px={"11px"}
                            bg="#1c2147"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            cursor={"pointer"}
                            position={"relative"}
                            onClick={handleLogout}
                            _hover={{ background: "#cbd5e0", color: "#1c2147" }}
                            color="white"
                        >

                            <Text>
                                Log out
                            </Text>
                            <BiLogOut size={'22px'} />
                        </Button>
                    </MenuItem>
                </MenuList>

            </Menu>
        </>
    );
}
