// Chakra imports
import { Text, useColorModeValue, Flex } from "@chakra-ui/react";
// Custom components
import React from "react";
// Assets
import EventCalendar from "components/calendar/EventCalendar";

export default function Calendar({ events }) {
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");

    // Obtener la fecha actual en formato 'YYYY-MM-DD'
    const currentDate = new Date().toISOString().split('T')[0];

    const getColorType = (event) => {
        const colorMap = {
            1: '#b9ce67',
            2: '#fb9539',
            3: '#de5b5b',
            4: '#f4bb1c',
            5: '#7a86e6',
        };
        return colorMap[event] || '#7080fc';
    };

    const calendarData = events.map(event => ({
        title: event.title,
        borderColor: "transparent",
        start: event.start,
        end: event.end,
        backgroundColor: getColorType(event.id_location),
    }));

    return (
        <Flex w={'100%'} direction={'column'}>
            <Text fontSize='2xl' fontWeight='700' color={textColor} mb={'2%'}>
                DP Calendar
            </Text>
            <EventCalendar initialDate={currentDate} calendarData={calendarData} />
        </Flex>
    );
}
