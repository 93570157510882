import React, { useState } from 'react';
// Chakra Imports
import {
    Button,
    MenuItem,
    Text,
} from '@chakra-ui/react';

export default function CustomMenuItem({ backgroundColor, textColor, buttonColor, spanColor, onClick, label }) {
    const [isHovered, setIsHovered] = useState(false);

    const menuItemStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const buttonStyle = {
        backgroundColor: isHovered ? spanColor || '#ff6f94' : buttonColor || '#f2f2f2',
        color: isHovered ? 'white' : textColor || '#1c2147',
        width: '95%',
        borderRadius: '10px',
        transition: 'background-color 0.3s, color 0.3s',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const spanStyle = {
        width: '15px',
        height: '30px',
        backgroundColor: isHovered ? 'white' : spanColor || '#ff6f94',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
    };

    return (
        <MenuItem
            style={menuItemStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Button className="button" style={buttonStyle} onClick={onClick}>
                <Text
                flex={1}
                textAlign={'center'}
                >
                    {label}
                </Text>
                <span className="span" style={spanStyle} />
            </Button>

        </MenuItem>
    );
}