import axios from 'axios';
import React, { useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Box, FormControl, FormLabel, Select, Flex, SimpleGrid,
    useToast, Textarea, FormErrorMessage, Button, Text

} from '@chakra-ui/react';
import { BsFillTicketFill } from "react-icons/bs";
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import { FaCheckCircle } from "react-icons/fa";
function EndIncident({ isOpen, onClose, incident, setting, onIncidentUpdated, closeMessages }) {
    const toast = useToast();
    const [selectSettings, setSelectSettings] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        problematic: '',
        solution: '',
        id_setting: '',
        resolved: '',
        reason: '',
    });

    const clearForm = () => {
        setFormData({
            problematic: '',
            solution: '',
            id_setting: '',
            resolved: '',
            reason: ''
        });
    };


    const handleSettingChange = (event) => {
        const value = event.target.value;
        setSelectSettings(value);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (id) => {
        formData.id_setting = selectSettings;
        try {
            const response = await axios.put(`/end-incident/${incident.id}`, formData);
            if (response.status === 200) {
                onIncidentUpdated();
                toast({
                    title: 'Ticket Complete',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                clearForm();
                onClose();
                closeMessages();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error Creating a Ticket",
                status: "error",
                duration: 1000,
                isClosable: true,
            });
            console.error('Error creating employee:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}
            closeOnOverlayClick={false}
            closeOnEsc={false} isCentered>
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            End Ticket
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <BsFillTicketFill size={'18px'} />
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {(() => {
                                        switch (incident.chat_room_id) {
                                            case 1:
                                                return "IT request ";
                                            case 2:
                                                return "HR request ";
                                            case 3:
                                                return "Hiring request ";
                                            case 4:
                                                return "OnCall request ";
                                            case 5:
                                                return "Schedules request ";
                                            case 6:
                                                return "Finances request ";
                                            case 7:
                                                return "Calls request ";
                                            case 8:
                                                return "Supervisor task";
                                            case 9:
                                                return "QA-Clarification";
                                            default:
                                                return "IT request ";
                                        }
                                    })()}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody mt={2} ml={10} mr={10}>
                    <Box>
                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.id_setting}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}

                                >
                                    Reason
                                </FormLabel>
                                <Select

                                    w='100%'
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    name='id_option'
                                    color={'#cccccc'}
                                    background={"white"}
                                    textAlign={"center"}
                                    value={selectSettings}
                                    onChange={handleSettingChange}
                                    h='44px'
                                    maxh='44px'
                                    errorBorderColor='#fc7466'
                                >
                                    <option value=''>Please select a reason</option>
                                    {setting.map((sc) => (
                                        <option key={sc.id} value={sc.id}>
                                            {sc.name}
                                        </option>
                                    ))}

                                </Select>
                                {formErrors.id_setting && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}

                            </FormControl>
                            <FormControl isInvalid={!!formErrors.problematic}>
                                <Textarea
                                    placeholder="Explain the problematic. min. 80 characters"
                                    mt={5}
                                    name="problematic"
                                    className='custom-placeholderInputs'
                                    background="white"
                                    defaultValue={formData.problematic}
                                    onChange={handleInputChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.problematic && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.solution}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}
                                >
                                    Solution
                                </FormLabel>
                                <Textarea
                                    placeholder="Explain the solution. min. 80 characters"
                                    mt="4"
                                    defaultValue={formData.solution}
                                    name="solution"
                                    className='custom-placeholderInputs'
                                    background="white"
                                    onChange={handleInputChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.solution && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.resolved}>
                                <FormLabel
                                    ms='10px'
                                    fontSize='md'
                                    color="#808080"
                                    textAlign={"center"}
                                >
                                    The problematic is resolved?
                                </FormLabel>
                                <Select
                                    w='100%'
                                    fontSize={'md'}
                                    borderRadius={'5px'}
                                    name='resolved'
                                    defaultValue={formData.resolved}
                                    onChange={handleInputChange}
                                    color={'#cccccc'}
                                    background={"white"}
                                    textAlign={"center"}
                                    h='44px'
                                    errorBorderColor='#fc7466'
                                    maxh='44px'
                                    placeholder='Select'>
                                    <option value='Temporal solution'>Temporal solution</option>
                                    <option value='Definitive solution'>Definitive solution</option>
                                    <option value='Incomplete solution'>Incomplete solution</option>
                                </Select>
                                {formErrors.resolved && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                            {[1].includes(incident.chat_room_id) ? (
                                <FormControl isInvalid={!!formErrors.reason}>
                                    <FormLabel
                                        ms='10px'
                                        fontSize='md'
                                        color="#808080"
                                        textAlign={"center"}
                                    >
                                        The problem was a cause of:
                                    </FormLabel>
                                    <Select
                                        w='100%'
                                        fontSize={'md'}
                                        borderRadius={'5px'}
                                        name='reason'
                                        defaultValue={formData.reason}
                                        onChange={handleInputChange}
                                        color={'#cccccc'}
                                        background={"white"}
                                        textAlign={"center"}
                                        h='44px'
                                        errorBorderColor='#fc7466'
                                        maxh='44px'
                                        placeholder='Select'>
                                        <option value='DP office error'>DP office error</option>
                                        <option value='Remote agent error'>Remote agent error</option>
                                        <option value='Client error'>Client error</option>
                                        <option value='Unaffected'>Unaffected</option>
                                        <option value='Other'>Other</option>
                                    </Select>
                                    {formErrors.reason && (
                                        <FormErrorMessage>This field is required.</FormErrorMessage>
                                    )}
                                </FormControl>
                            ) : null}
                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"6%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Finish Ticket
                        <Box ml={2}>
                            <FaCheckCircle size={'20px'} />
                        </Box>
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}
export default EndIncident;