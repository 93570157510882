import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input, Select,
    Box, Flex, Heading, SimpleGrid,
    FormControl, FormErrorMessage,
    Image, Text,
    useToast,
    Textarea,
} from '@chakra-ui/react';
import avatar4 from 'assets/img/dashboards/grey_world.png';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'

const UpdateCalendar = ({ isOpen, onClose, onCalendarUpdated, calendar, setFlashing }) => {
    const toast = useToast();
    const [formErrors, setFormErrors] = useState({});

    const clearForm = () => {
        setFormData({
            title: '',
            description: '',
            start: '',
            end: '',
            id_location: '',
        });
        setFormErrors({});
    };

    const [formData, setFormData] = useState({
        title: calendar?.title || '',
        description: calendar?.description || '',
        start: calendar?.start || '',
        end: calendar?.end || '',
        id_location: calendar?.id_location || '',
    });

    const handleSubmit = async (id) => {
        try {
            const response = await axios.put(`/calendar/update/${calendar.id}`, formData);
            onCalendarUpdated();
            setFlashing(formData);
            toast({
                title: 'Calendar Edit Success',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            clearForm();
            onClose();
            setFormErrors({});
        } catch (error) {
            toast({
                title: "Error Editing Calendar",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Editing Calendar:', error);
            setFormErrors(error.response.data.errors);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Modal size="lg" isOpen={isOpen} onClose={() => {
            onClose();
        }}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent background={'#f2f2f2'} borderRadius={'30px'}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} mb={'5%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Edit Event
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {calendar.title}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Box>

                        <SimpleGrid
                            columns='1'
                            gap='40px'
                            maxW='100%'
                            w={{ base: "100%", md: "unset" }}
                        >
                            <FormControl isInvalid={!!formErrors.title}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Title:
                                </Text>
                                <Input
                                    type="text"
                                    placeholder="Enter Event Title"
                                    name='title'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.title}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.title && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.description}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Description:
                                </Text>
                                <Textarea
                                    type="text"
                                    placeholder="Enter Event Description"
                                    name='description'
                                    className='custom-placeholderInputs'
                                    background="white"
                                    value={formData.description}
                                    onChange={handleChange}
                                    errorBorderColor='#fc7466'
                                />
                                {formErrors.description && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    End start:
                                </Text>
                                <Input
                                    type='date'
                                    bg={'#f2f2f2'}
                                    width={'100%'}
                                    color={'#808080'}
                                    textAlign={'center'}
                                    value={formData.start}
                                    onChange={handleChange}
                                    name='start'
                                    mr={'5%'}
                                />
                            </FormControl>
                            <FormControl>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    End date:
                                </Text>
                                <Input
                                    type='date'
                                    bg={'#f2f2f2'}
                                    width={'100%'}
                                    color={'#808080'}
                                    textAlign={'center'}
                                    value={formData.end}
                                    onChange={handleChange}
                                    name='end'
                                    mr={'5%'}
                                />
                            </FormControl>
                            <FormControl isInvalid={!!formErrors.id_location}>
                                <Text mr={'20px'} color={'#1c2147'} >
                                    Location:
                                </Text>
                                <Select
                                    w="100%%"
                                    fontSize={"md"}
                                    borderRadius={"10px"}
                                    name="id_location"
                                    textAlign={"center"}
                                    background="white"
                                    value={formData.id_location}
                                    onChange={handleChange}
                                    h="44px"
                                    maxh="44px"
                                    errorBorderColor="#fc7466"
                                >
                                    <option value="">Select Location</option>
                                    <option value="1">MX</option>
                                    <option value="2">BZ</option>
                                    <option value="3">RD</option>
                                    <option value="4">COL</option>
                                    <option value="5">GTM</option>
                                </Select>
                                {formErrors.id_location && (
                                    <FormErrorMessage>This field is required.</FormErrorMessage>
                                )}
                            </FormControl>

                        </SimpleGrid>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Update Event
                    </Button>
                    <Button class="buttonCancel" onClick={() => {
                        onClose();
                    }}>
                        Cancel <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
export default UpdateCalendar; 