// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useUserData } from 'interceptors/userAuth';

import axios from 'axios';
import routes from 'routes';
import routesAccounts from 'routes/routesAccounts';
import routesHR from 'routes/routesHR';
import routesFinances from 'routes/routesFinances';
import routesCallCenterManager from 'routes/routesCallCenterManager';
import routesHiring from 'routes/routesHiring';
import routesIT from 'routes/routesIT';
import routesOperations from 'routes/routesOperations';
import routesSchedules from 'routes/routesSchedules';
import routesSupervisor from 'routes/routesSupervisor';
import routesAgents from 'routes/routesAgents';
import routesSupervisorClient from 'routes/routesSupervisorClient';
import routesQA from 'routes/routesQA';
import routesITCoordinator from 'routes/routesITCoordinator';
import routesOCSupervisor from 'routes/routesOCSupervisor';
import routesDefault from 'routes/routesDefault';
import routesFinanceManager from 'routes/routesFinanceManager';
import routesSocialMedia from 'routes/routesSocialMedia';
import routesQAManager from 'routes/routesQAManager';
import routesFinanceSup from 'routes/routesFinancesSup';
import routesQAOC from 'routes/routesQAOC';
import routesHiringManager from 'routes/routesHiringManager';
import routesDesigner from 'routes/routesDesigner';
import routesHRBZ from 'routes/routesHRBZ';

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { user, fetchData } = useUserData();

  const [sidebarVisible, setSidebarVisible] = useState(false);


  axios.defaults.withCredentials = true;

  useEffect(() => {
    fetchData();
  }, []);

  let userRoutes;

  if ([769, 756].includes(user.id)) {
    userRoutes = routesQA;
  } else if ([971].includes(user.id)) {
    userRoutes = routesHRBZ;
  } else {
    const routesMap = {
      1: routes,
      2: routesFinanceManager,
      3: routesCallCenterManager,
      4: routesCallCenterManager,
      5: routesFinanceSup,
      6: routesFinances,
      7: routesCallCenterManager,
      8: routesHR,
      9: routesHRBZ,
      10: routesHiringManager,
      11: routesHiring,
      12: routesHiring,
      13: routesSocialMedia,
      14: routesOperations,
      15: routesOperations,
      16: routesOperations,
      17: routesOperations,
      18: routesOCSupervisor,
      19: routesSupervisor,
      20: routesSupervisor,
      21: routesSupervisor,
      22: routesAgents,
      23: routesSchedules,
      24: routesSchedules,
      25: routesSchedules,
      26: routesQAManager,
      27: routesQAOC,
      28: routesSupervisor,
      29: routesQA,
      30: routesIT,
      31: routesIT,
      32: routesIT,
      33: routesIT,
      34: routesIT,
      36: routesAgents,
      37: routesCallCenterManager,
      38: routesIT,
      39: routesHiring,
      40: routesDesigner,
      41: routesSupervisorClient,
      42: routesSupervisorClient,
      43: routesAgents,
      44: routesAgents,
      45: routesSupervisor,
      46: routesITCoordinator,
      52: routesAgents,
      53: routesAgents
    };

    userRoutes = routesMap[user.type_user_id] || routesDefault;
  }



  // Lógica para determinar las rutas según el valor de user



  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (userRoutes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < userRoutes.length; i++) {
      if (userRoutes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(userRoutes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(userRoutes[i].layout + userRoutes[i].path) !== -1
        ) {
          return userRoutes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (userRoutes) => {
    let activeNavbar = false;
    for (let i = 0; i < userRoutes.length; i++) {
      if (userRoutes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(userRoutes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(userRoutes[i].layout + userRoutes[i].path) !== -1
        ) {
          return userRoutes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (userRoutes) => {
    return userRoutes.map((userRoutes, key) => {
      if (userRoutes.layout === '/admin') {
        return (
          <Route path={`${userRoutes.path}`} element={userRoutes.component} key={key} />
        );
      }
      if (userRoutes.collapse) {
        return getRoutes(userRoutes.items);
      } else {
        return null;
      }
    });
  };
  console.log(mini);
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <Box bg={bg} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}
          setHovered={setHovered}
          mini={mini}
          setMini={setMini}
          routes={userRoutes}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"

          width={mini === true ? '92%' : '85%'}
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                brandText={getActiveRoute(userRoutes)}
                secondary={getActiveNavbar(userRoutes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              minH="95vh"
              pe="20px"
              pt="50px"
            >
              <Routes>
                {getRoutes(userRoutes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/home" replace />}
                />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
