import axios from 'axios';
import React, { useState } from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    FormControl, Text, Flex, useToast, FormErrorMessage
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons'
import Select2 from 'react-select'
import { BsFillTicketFill } from "react-icons/bs";

function AssignQA({ isOpen, onClose, incident, onIncidentUpdated, closeMessages, users }) {

    const toast = useToast();
    const [resetKey, setResetKey] = useState(0);
    const [userSearch, setUserSearch] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const optionsUsers = users.map(users => ({
        value: users.id,
        label: `${users.name} ${users.last_name}`,
    }));


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: '#808080',
            fontSize: '16px',
            height: '40px',
            width: '375px',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',
        }),
    };

    const filterOptions = (candidate, input) => {
        const words = input.toLowerCase().split(' ');
        return words.every(word =>
            candidate.label.toLowerCase().includes(word)
        );
    };

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'user':
                setUserSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.put(`/assign-incident/${incident.id}`, { id_supervisor: userSearch });
            toast({
                title: 'QA Agent assigned',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onIncidentUpdated();
            closeMessages();
            setFormErrors({});
            onClose();
        } catch (error) {
            toast({
                title: "Error assigning QA Agent",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.error('Error Request Send:', error);
            setFormErrors(error.response?.data?.errors || {});
        }
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'Default'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} w={'25%'}>
                <ModalHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#1c2147'} mt={'3%'} fontWeight={'bold'} fontSize={'35px'}>
                    <Flex direction={'column'}>
                        <Text textAlign={'center'}>
                            Assign QA Agent
                        </Text>
                        <Flex style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} color={'#7080fc'} fontWeight={'bold'} fontSize={'22px'}>
                            <BsFillTicketFill size={'18px'} />
                            <span style={{ marginLeft: '8px' }}>
                                <span style={{ marginRight: '8px', color: '#7080fc' }}>
                                    {(() => {
                                        switch (incident.chat_room_id) {
                                            case 1:
                                                return "IT request ";
                                            case 2:
                                                return "HR request ";
                                            case 3:
                                                return "Hiring request ";
                                            case 4:
                                                return "OnCall request ";
                                            case 5:
                                                return "Schedules request ";
                                            case 6:
                                                return "Finances request ";
                                            case 7:
                                                return "Calls request ";
                                            case 8:
                                                return "Supervisor task";
                                            case 9:
                                                return "QA - Clarification";
                                            default:
                                                return "IT request ";
                                        }
                                    })()}
                                </span>
                            </span>
                        </Flex>
                    </Flex>
                </ModalHeader>
                <ModalBody mt={2} alignSelf={'center'}>
                    <Flex>
                        <FormControl isInvalid={!!formErrors.supervisor}>
                            <Select2
                                options={optionsUsers}
                                value={optionsUsers.find(option => option.value === userSearch)}
                                onChange={(selectedOption) => {
                                    handleSelectChangeF('user', selectedOption);
                                }}
                                styles={customStyles}
                                placeholder="Select QA Agent"
                                key={`agentSelect-${resetKey}`}
                                isSearchable
                                filterOption={filterOptions}
                            />
                            {formErrors.supervisor && (
                                <FormErrorMessage>This field is required.</FormErrorMessage>
                            )}

                        </FormControl>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="center" mb={'5%'} mt={'5%'}>
                    <Button class="buttonCreate" onClick={handleSubmit}>
                        Assign  <EditIcon ml={2} />
                    </Button>
                    <Button class="buttonCancel" onClick={onClose}>
                        Close <CloseIcon ml={2} />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
}
export default AssignQA;