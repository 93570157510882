import React, { useState, useEffect } from 'react';
import {
    Table, Thead, Tbody, Tr, Th, Td,
    Button, ButtonGroup, Text,
    Flex, Box, Badge,
    Menu, MenuItem, MenuList, MenuButton
} from '@chakra-ui/react'
import { FaUsers } from "react-icons/fa";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { InfoOutlineIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons'
import { RiFileExcel2Fill } from "react-icons/ri";
import { useUserData } from 'interceptors/userAuth';

function Qualification({ setCurrentPage, qualificationData, getRowBackground, total, currentPage, totalPages, handleUpdate, exportCalls, handleDelete }) {

    const { user, fetchData } = useUserData();

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <Flex
                direction='column'
                w='100%'
            >
                <br />
                <Table size={'sm'} >
                    <Thead bg={"white"}>
                        <Tr bg={'white'}>
                            <Th textAlign={'center'}>Agent</Th>
                            <Th textAlign={'center'}>Data</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {qualificationData && qualificationData.length > 0 ? (
                            qualificationData.map((item, index) => (
                                <Tr key={item.user_id} className={index % 2 === 0 ? 'white-row' : 'gray-row'} mb={'50px'}>
                                    <Td textAlign={'center'} color={"#1c2147"} fontWeight={"bold"} >
                                        <Flex direction={'column'}>
                                            <Text>
                                                {item.name.split(' ')[0]} {item.last_name.split(' ')[0]}
                                            </Text>
                                        </Flex>
                                    </Td>
                                    {item.calls_grouped_by_date.map((calls_dates) => (
                                        <Box key={calls_dates.id} borderRadius={'5px'} display={'flex'} justifyContent={'center'}>
                                            <Td textAlign={'center'} mb={2} marginLeft={'7%'} width={'100%'}>
                                                <Box flexDirection="column" >
                                                    {calls_dates.dates.map((dates, index) => (
                                                        <Box key={dates.id} borderRadius={'5px'} display={'flex'} justifyContent={'center'} bg={index % 2 === 0 ? 'white' : '#f2f2f2'}>
                                                            <Td textAlign={'center'} mb={2}>
                                                                <Box flexDirection="column">
                                                                    <Box fontWeight={'bold'} textAlign={'center'} display="flex" justifyContent='space-evenly' alignItems="center" bg={getRowBackground(dates)} width={'920px'} borderRadius={'5px'}>
                                                                        <Flex direction={'column'}>
                                                                            <Badge color={"white"} bg={'#424ea8'} width={'170px'} height={'22px'} fontSize='10px' ml={1} borderRadius={"5px"}>
                                                                                {dates.date_formatted}
                                                                            </Badge>
                                                                            <Text mt={'5%'}>
                                                                                {dates.percent}
                                                                            </Text>
                                                                            <Text>
                                                                                {dates.approved}
                                                                            </Text>
                                                                            <Flex justifyContent={'center'}>
                                                                                {index === 0 && (
                                                                                    <Button
                                                                                        mt={'5%'}
                                                                                        onClick={() => exportCalls(dates)}
                                                                                        bg={'#b9ce67'}
                                                                                        borderRadius={'5px'}
                                                                                        color={'white'}
                                                                                        _hover={{ background: "#808080" }}
                                                                                        w={'80%'}
                                                                                    >
                                                                                        <Text mr={'5%'}>
                                                                                            Export
                                                                                        </Text>
                                                                                        <RiFileExcel2Fill />
                                                                                    </Button>
                                                                                )}
                                                                            </Flex>
                                                                        </Flex>
                                                                        <Flex direction={'column'} w={'50%'}>
                                                                            {dates.calls.map((calls) => (
                                                                                <Flex key={calls.id} borderRadius={'5px'} display={'flex'} mb={'5%'} mt={'5%'} justifyContent={'space-evenly'}>
                                                                                    <Badge color={"white"} bg={'#424ea8'} width={'30%'} height={'22px'} fontSize='10px' borderRadius={"5px"} mr={'5%'}>
                                                                                        Call {calls.call}
                                                                                    </Badge>
                                                                                    <Text mr={'5%'}>
                                                                                        QA Agent: {calls.name.split(' ')[0]} {calls.last_name.split(' ')[0]}
                                                                                    </Text>
                                                                                    <Text mr={'5%'}>
                                                                                        Team: {calls.client}
                                                                                    </Text>
                                                                                    <Text mr={'5%'}>
                                                                                        Account: {calls.account ? calls.account : '---'}
                                                                                    </Text>
                                                                                    <Text mr={'5%'}>
                                                                                        {calls.percent}
                                                                                    </Text>
                                                                                    <Menu>
                                                                                        <MenuButton
                                                                                            as={Button}
                                                                                            _active={{ background: "#808080", color: 'white' }}
                                                                                            bg="#1c2147" color={"white"}
                                                                                            _hover={{ background: "#808080", color: 'white' }}
                                                                                            borderRadius={'5px'}
                                                                                            width={'15%'}
                                                                                            height={'25px'}
                                                                                        >
                                                                                            <SettingsIcon textAlign={'center'} boxSize={'4'} />
                                                                                        </MenuButton>
                                                                                        <MenuList>
                                                                                            <MenuItem isDisabled>
                                                                                                <div style={{ fontWeight: 'bold', textAlign: 'center', width: "100%" }}>Actions on this call</div>
                                                                                            </MenuItem>
                                                                                            <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                                                                <Button
                                                                                                    display="flex"
                                                                                                    justifyContent="space-between"
                                                                                                    alignItems="center"
                                                                                                    bg={"#f2f2f2"}
                                                                                                    color={"#1c2147"}
                                                                                                    _hover={{ background: "#7080fc", color: 'white' }}
                                                                                                    width="80%"
                                                                                                    borderRadius={'5px'}
                                                                                                    onClick={() => handleUpdate(calls, item, dates)}
                                                                                                >
                                                                                                    <span style={{ flex: 1, textAlign: 'center' }}>Edit </span>
                                                                                                    <InfoOutlineIcon ml={2} verticalAlign="middle" />
                                                                                                </Button>
                                                                                            </MenuItem>
                                                                                            {[1, 26, 37].includes(user.type_user_id) ? (
                                                                                                <MenuItem justifyContent={'center'} width={'300px'} mb={'2'}>
                                                                                                    <Button
                                                                                                        borderRadius={"5px"}
                                                                                                        display="flex"
                                                                                                        justifyContent="space-between"
                                                                                                        alignItems="center"
                                                                                                        color={"#1c2147"}
                                                                                                        _hover={{ background: '#de5b5b', color: 'white' }}
                                                                                                        bg={"#f2f2f2"}
                                                                                                        width="80%"
                                                                                                        onClick={() => handleDelete(calls)}
                                                                                                    >
                                                                                                        <Text flex={1} textAlign={'center'}>Delete</Text><DeleteIcon ml={2} />
                                                                                                    </Button>
                                                                                                </MenuItem>
                                                                                            ) : null}

                                                                                        </MenuList>
                                                                                    </Menu>
                                                                                </Flex>
                                                                            ))}
                                                                        </Flex>

                                                                    </Box>
                                                                </Box>
                                                            </Td>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Td>
                                        </Box>
                                    ))}
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Th colSpan={'9'} >
                                    <Text
                                        color='#1c2147'
                                        fontSize={"25px"}
                                        textAlign={"center"}
                                        paddingTop={"15px"}
                                        paddingBottom={"15px"}
                                        bg={"#f2f2f2"}
                                        textTransform={'none'}
                                        fontFamily={'DM Sans'}
                                        letterSpacing="-1px"
                                    >
                                        No data
                                    </Text>
                                </Th>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </Flex >
            <Box width={"100%"} display={"flex"} mt={"30px"}>
                <Flex display={'flex'} justifyContent={'flex-start'} width={"max-content"} pb={"10px"} mt={"8px"}  >
                    {total === 0 ? (
                        <>
                            <Flex bg={'#f2f2f2'} width={'250px'} display={'flex'} justifyContent={'center'}>
                                <Box mt={'5px'} mr={2}>
                                    <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'}>
                                    No users
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex bg={'#f2f2f2'} width={'auto'} display={'flex'} justifyContent={'center'}>
                                <Text fontSize={'14px'} mt={1} mr={3} color={"#1c2147"} fontWeight={'bold'} ml={'20px'}>
                                    {total}
                                </Text>
                                <Box mt={'5px'} mr={1}>
                                    <FaUsers color={"#1c2147"} fontWeight={'bold'} />
                                </Box>
                                <Text fontSize={'14px'} mt={1} color={"#1c2147"} fontWeight={'bold'} mr={3}>
                                    {total === 1 ? 'User' : 'Users'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Flex>
                <ButtonGroup isAttached variant="outline" ml={"36%"} justifyContent={'center'} display={'flex'} width={"max-content"} >
                    <Button
                        borderRadius={'0px'}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        isDisabled={currentPage === 1}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowLeftShort fontSize="20px" />
                    </Button>
                    <Text class="btnTextPages">{currentPage} of {totalPages}</Text>
                    <Button
                        borderRadius={'0px'} ml={4}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        isDisabled={currentPage === totalPages}
                        bg="#808080" color={"white"}
                        _hover={{ background: "#808080", }}
                        _active={{ background: "#808080", }}
                    ><BsArrowRightShort fontSize="20px" />
                    </Button>
                </ButtonGroup>
            </Box>
        </>
    );
}
export default Qualification; 