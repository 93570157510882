import React from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,
    Button, Flex, Image, Text
} from '@chakra-ui/react';
import A1_1 from 'assets/protocols/a1_attendance/a1_1.jpg';
import A2_2 from 'assets/protocols/a1_attendance/a1_2.jpg';
import A3_3 from 'assets/protocols/a1_attendance/a2_3.jpg';

function ModalAttendancePolicy({ isOpen, onClose }) {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} closeOnOverlayClick={false} closeOnEsc={false} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody marginTop={'4%'} marginBottom={'4%'}>
                    <Flex display={'flex'} justifyContent={'left'} direction={'column'}>
                        <Image
                            src={A1_1}
                        />
                        <Image
                            src={A2_2}
                        />
                        <Image
                            src={A3_3}
                        />

                        <Flex justifyContent={'center'}>
                            <Button
                                borderRadius={'5px'}
                                onClick={onClose}
                                w={'30%'}
                                bg={"#f2f2f2"}
                                color='#1c2147'
                                _hover={{ background: "#fc7466", color: 'white' }}
                            >
                                Close
                            </Button>
                        </Flex>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ModalAttendancePolicy;
