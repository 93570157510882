import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    Button, Input,
    Box, Heading, SimpleGrid,
    FormControl, Flex, Stack, Text, Spinner,
    Table, Thead, Tbody, Tr, Th, Td,
} from '@chakra-ui/react';
import template from "assets/exports/Template_bonos.xlsx";
import { RepeatIcon } from '@chakra-ui/icons'
import { AiOutlineClear } from "react-icons/ai";

const ImportUsersBonos = ({ isOpen, onClose }) => {

    const [file, setFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [dataUpdate, setDataUpdate] = useState([]);
    const [showDataUpdate, setShowDataUpdate] = useState(false);

    const [dataFail, setDataFail] = useState([]);
    const [showDataFail, setShowDataFail] = useState(false);

    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [displayDateRange, setDisplayDateRange] = useState("");


    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        const formData = new FormData();
        formData.append('file', file);

        try {

            const response = await axios.post(`/updateBonos?` +
                `${startDateFilter ? `&startDate=${encodeURIComponent(startDateFilter)}` : ''}` +
                `${endDateFilter ? `&endDate=${encodeURIComponent(endDateFilter)}` : ''}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });


            // Verificar si dataFail tiene elementos antes de mostrarlo
            if (response.data.updatedData && response.data.updatedData.length > 0) {
                setDataUpdate(response.data.updatedData);
                setShowDataUpdate(true);
                setSuccessMessage(response.data.success);
            } else {
                setShowDataUpdate(false); // Asegurarte de que no se muestre si está vacío
                setErrorMessage('Data not found');
            }


            // Verificar si dataFail tiene elementos antes de mostrarlo
            if (response.data.dataFail && response.data.dataFail.length > 0) {
                setDataFail(response.data.dataFail);
                setShowDataFail(true);
            } else {
                setShowDataFail(false); // Asegurarte de que no se muestre si está vacío
            }

        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage('There was an error importing the file.');
            }
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return "";

        // Descomponer el formato "YYYY-MM-DD"
        const [year, month, day] = dateString.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Crear fecha localmente

        const dayOfMonth = date.getDate();
        const monthName = date.toLocaleString("en-US", { month: "long" });
        const yearValue = date.getFullYear();

        // Agregar sufijo para el día
        const suffix =
            dayOfMonth % 10 === 1 && dayOfMonth !== 11
                ? "st"
                : dayOfMonth % 10 === 2 && dayOfMonth !== 12
                    ? "nd"
                    : dayOfMonth % 10 === 3 && dayOfMonth !== 13
                        ? "rd"
                        : "th";

        return `${dayOfMonth}${suffix} ${monthName}, ${yearValue}`;
    };


    useEffect(() => {
        const calculateDefaultDateRange = () => {
            const today = new Date();
            const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
            const lastSaturday = new Date(lastSunday);
            lastSaturday.setDate(lastSaturday.getDate() - 1);
            lastSunday.setDate(lastSunday.getDate() - 7);

            return {
                start: lastSunday.toISOString().split("T")[0], // Convertir a formato YYYY-MM-DD
                end: lastSaturday.toISOString().split("T")[0],
            };
        };

        const updateDateRangeText = () => {
            const start = startDateFilter || calculateDefaultDateRange().start;
            const end = endDateFilter || calculateDefaultDateRange().end;
            setDisplayDateRange(`Date range: ${formatDate(start)} to ${formatDate(end)}`);
        };

        updateDateRangeText();
    }, [startDateFilter, endDateFilter]);

    const clearFilters = () => {
        setStartDateFilter('');
        setEndDateFilter('');
    }


    return (
        <Modal isOpen={isOpen} size={'3xl'} onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false} isCentered
        >
            <ModalOverlay />
            <ModalContent background={"#f2f2f2"} borderRadius={"30px"} >
                <ModalHeader
                    textColor='white'
                    textAlign='center'
                    borderRadius='20px'
                    mx="14"
                    mt={6}
                >
                </ModalHeader>

                <ModalBody>
                    <Box mx="auto" textAlign="center" my={"auto"} justifyContent={"center"}>
                        <Heading as="h2" fontSize="200%" color={'#808080'} textAlign={'center'} fontFamily="DM Sans" position="relative" letterSpacing="3px" mb='10' mt={4}>
                            UPDATE BONOS
                        </Heading>
                        <Heading as="h3" fontSize="150%" color={'#424ea8'} textAlign={'center'} fontFamily="DM Sans" position="relative" mb='10' mt={4}>
                            {displayDateRange}
                        </Heading>
                        <Flex display={'flex'} justifyContent={'center'}>
                            <Flex
                                border={'1px solid #808080'}
                                display={'flex'}
                                borderRadius={'4px'}
                                ml={4} mr={4}
                            >
                                <Input
                                    width={'100%'}
                                    border={'0px'}
                                    type="date"
                                    fontSize={"sm"}
                                    value={startDateFilter}
                                    onChange={(e) => {
                                        setStartDateFilter(e.target.value);
                                    }}
                                    _focusVisible={'none'}
                                    color={'#808080'}
                                />
                                <Button bg={'none'} color={'#808080'} onClick={() => {
                                    setStartDateFilter('');
                                }}>
                                    <RepeatIcon />
                                </Button>
                            </Flex>
                            <Flex
                                border={'1px solid #808080'}
                                display={'flex'}
                                borderRadius={'4px'}
                                mr={4}
                            >
                                <Input
                                    width={'100%'}
                                    border={'0px'}
                                    type="date"
                                    fontSize={"sm"}
                                    value={endDateFilter}
                                    onChange={(e) => {
                                        setEndDateFilter(e.target.value);
                                    }}
                                    _focusVisible={'none'}
                                    color={'#808080'}
                                />
                                <Button bg={'none'} color={'#808080'} onClick={() => {
                                    setEndDateFilter('');
                                }}>
                                    <RepeatIcon />
                                </Button>
                            </Flex>

                            <Button bg={'transparent'} _hover={{ backgroundColor: 'transparent' }} color={'#808080'} padding={"0px"} title="Clear filters" onClick={clearFilters}>
                                <AiOutlineClear size={'26px'} />
                            </Button>
                        </Flex>
                        <Flex justifyContent="center" alignItems="center" height="100%" direction={'column'}>
                            <SimpleGrid
                                columns={1}
                                gap='40px'
                                width={'100%'}
                                justifyContent="center"
                                alignItems="center"
                                textAlign="center"
                            >
                                <form onSubmit={onSubmit}>
                                    <Stack spacing={3} p={4} borderRadius="md" boxShadow="md">
                                        <FormControl >
                                            <Input id="file" type="file" onChange={onFileChange} bg='white' required />
                                        </FormControl>
                                        {successMessage && <Box color="green.500">{successMessage}</Box>}
                                        {errorMessage && <Box color="red.500">{errorMessage}</Box>}
                                        <Button
                                            type="submit"
                                            bg={'#1c2147'}
                                            borderRadius={'5px'}
                                            _hover={{ background: "#808080", }}
                                            color={'white'}
                                        >
                                            Import
                                        </Button>
                                    </Stack>
                                </form>
                            </SimpleGrid>

                            {!showDataFail ? (
                                null
                            ) : (
                                <Flex mt={'5%'} width={'100%'} overflowY={'auto'} maxHeight={'500px'}>
                                    <Table>
                                        <Thead bg={"white"} >
                                            <Tr>
                                                <Th color='#808080' textAlign={'center'}>ID</Th>
                                                <Th color='#808080' textAlign={'center'}>Full Name</Th>
                                                <Th color='#808080' textAlign={'center'}>Message</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                                            {dataFail && dataFail.length > 0 ? (
                                                dataFail.map((dataFail, index) => (
                                                    <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                                        <Td textAlign={'center'}>
                                                            {dataFail.id ? (
                                                                dataFail.id
                                                            ) : (
                                                                "MATP"
                                                            )}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataFail.full_name}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataFail.message}
                                                        </Td>
                                                    </Tr>
                                                ))
                                            ) : (
                                                <Tr>
                                                    <Th colSpan={'20'}>
                                                        <Text
                                                            color='#1c2147'
                                                            fontSize={"25px"}
                                                            textAlign={"center"}
                                                            paddingTop={"15px"}
                                                            paddingBottom={"15px"}
                                                            bg={"#f2f2f2"}
                                                            textTransform={'none'}
                                                            fontFamily={'DM Sans'}
                                                            letterSpacing="-1px"
                                                        >
                                                            No data
                                                        </Text>
                                                    </Th>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                </Flex>
                            )}

                            {!showDataUpdate ? (
                                null
                            ) : (
                                <Flex mt={'5%'} width={'100%'} overflowY={'auto'} maxHeight={'500px'}>
                                    <Table>
                                        <Thead bg={"white"} >
                                            <Tr>
                                                <Th color='#808080' textAlign={'center'}>ID</Th>
                                                <Th color='#808080' textAlign={'center'}>Full Name</Th>
                                                <Th color='#808080' textAlign={'center'}>Previous Bono</Th>
                                                <Th color='#808080' textAlign={'center'}>New Bono</Th>
                                                <Th color='#808080' textAlign={'center'}>Penalized</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody fontSize={'14px'} color={"#1c2147"} fontWeight={"bold"}>
                                            {dataUpdate && dataUpdate.length > 0 ? (
                                                dataUpdate.map((dataUpdate, index) => (
                                                    <Tr className={index % 2 === 0 ? 'white-row' : 'gray-row'} key={index}>
                                                        <Td textAlign={'center'}>
                                                            {dataUpdate.id ? (
                                                                dataUpdate.id
                                                            ) : (
                                                                "MATP"
                                                            )}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataUpdate.full_name}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataUpdate.prev_bono}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataUpdate.new_bono}
                                                        </Td>
                                                        <Td textAlign={'center'}>
                                                            {dataUpdate.penalized === 1 ? 'Penalized' : '---'}
                                                        </Td>
                                                    </Tr>
                                                ))
                                            ) : (
                                                <Tr>
                                                    <Th colSpan={'20'}>
                                                        <Text
                                                            color='#1c2147'
                                                            fontSize={"25px"}
                                                            textAlign={"center"}
                                                            paddingTop={"15px"}
                                                            paddingBottom={"15px"}
                                                            bg={"#f2f2f2"}
                                                            textTransform={'none'}
                                                            fontFamily={'DM Sans'}
                                                            letterSpacing="-1px"
                                                        >
                                                            No data
                                                        </Text>
                                                    </Th>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="center" pt={"10%"} pb={"10%"}>
                    <Button
                        bg={'#b9ce67'}
                        borderRadius={'5px'}
                        _hover={{ background: "#8aad01", }}
                        color={'white'}
                        mr={'5%'}
                    >
                        <a href={template} download="Template_bonos.xlsx">
                            Download template
                        </a>
                    </Button>
                    <Button
                        onClick={onClose}
                        bg={'#7080fc'}
                        color={'white'}
                        borderRadius={'5px'}
                        _hover={{
                            backgroundColor: "#fc7466",
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ImportUsersBonos;
