import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import {
    useToast, TableContainer, Flex
} from '@chakra-ui/react'
import axios from 'axios';
import CalendarList from './table';

function UseCalendar() {
    const [calendar, setCalendar] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState('');
    const [totalPages, setTotalPages] = useState(1);
    const [selectCalendar, setSelectCalendar] = useState(null);
    const [flashing, setFlashing] = useState(false);
    const [locationSearch, setLocationSearch] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [filterDateEnd, setFilterDateEnd] = useState('');
    const [resetKey, setResetKey] = useState(0);

    const clearFilters = () => {
        setFilterDate('');
        setFilterDateEnd('');
        setLocationSearch('');
        setCurrentPage(1);
        setResetKey(resetKey + 1);
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`/calendar?page=${currentPage}`
                + `${filterDate ? `&start=${filterDate.trim()}` : ''}`
                + `${filterDateEnd ? `&end=${filterDateEnd.trim()}` : ''}`
                + `${locationSearch ? `&id_location=${locationSearch.trim()}` : ''}`
            );
            const responseData = response.data;
            setCalendar(responseData.data.data);
            setTotalPages(responseData.data.last_page);
            setTotal(responseData.data.total)
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        if (flashing) {
            const intervalId = setInterval(() => {
                setFlashing(false);
            }, 7000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [currentPage, flashing, filterDate, filterDateEnd, resetKey, locationSearch]);
    const handleSearch = () => {
        setCurrentPage(1);
    };
    const openModalCreate = () => {
        setModalCreateIsOpen(true);
    }
    const closeModalCreate = () => {
        setModalCreateIsOpen(false);
    }
    const handleUpdate = async (calendar) => {
        setSelectCalendar(calendar);
        setModalUpdateIsOpen(true);
        try {
            const response = await axios.get(`/calendar/update/${calendar.id}`);
            const calendar = response.data;

        } catch (error) {
            console.error('Error fetching event details:', error);
        }
    };
    const closeModalUpdate = () => {
        setModalUpdateIsOpen(false);
    }

    const handleDelete = async (calendar) => {
        const confirmDelete = await Swal.fire({
            title: 'Do you want to delete this Event?',
            text: 'The event will be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b9ce67',
            cancelButtonColor: '#fc7466',
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: false
        }).then((result) => {
            return result.isConfirmed
        });

        if (confirmDelete) {
            try {
                const response = await axios.delete(`/calendar/delete/${calendar.id}`);
                toast({
                    title: " Event deleted",
                    status: "success",
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
                fetchData();
            } catch (error) {
                console.error(error);
                toast({
                    title: "Error deleting event",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const getRowBackground = (calendar) => {
        if (selectCalendar && selectCalendar.id === calendar.id) {
            if (flashing) {
                return '#fcd677';
            }
            return calendar.id % 2 === 0 ? '#f2f2f2' : 'white';
        } else {
            return calendar.id % 2 === 0 ? '#f2f2f2' : 'white';
        }
    };

    return (
        <TableContainer mt={"2%"}  >
            <Flex
                direction='column'
                w='100%'
            >

            </Flex>

            <CalendarList
                openModalCreate={openModalCreate}
                handleSearch={handleSearch}
                setCurrentPage={setCurrentPage}
                calendar={calendar}
                getRowBackground={getRowBackground}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                loading={loading}
                total={total}
                currentPage={currentPage}
                totalPages={totalPages}
                modalCreateIsOpen={modalCreateIsOpen}
                closeModalCreate={closeModalCreate}
                fetchData={fetchData}
                ModalUpdateIsOpen={ModalUpdateIsOpen}
                closeModalUpdate={closeModalUpdate}
                selectCalendar={selectCalendar}
                setFlashing={setFlashing}
                setFilterDate={setFilterDate}
                setFilterDateEnd={setFilterDateEnd}
                clearFilters={clearFilters}
                setLocationSearch={setLocationSearch}
                locationSearch={locationSearch}
                filterDate={filterDate}
                filterDateEnd={filterDateEnd}
            />
        </TableContainer >
    );
}
export default UseCalendar; 