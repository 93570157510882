
import React from "react";
import {
    Flex, Text, Button,
} from '@chakra-ui/react';

//Icons & Buttons
import { GoClockFill } from "react-icons/go";
import { IoFastFood } from "react-icons/io5";

export default function ShiftButton(props) {

    const { buttonText, handleHover, ClockOut, openClockModal, shift, breakS, dataDailyBreak, dataShift, createBreak, startBreak, handleHoverB, endBreak, breakText } = props;

    return (
        <>
            {shift ? (
                <Button

                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textColor="white"
                    backgroundColor="#b9ce67"
                    borderRadius={'5px'}
                    _hover={{
                        background: "#fc7466",
                        color: "white",
                        content: "'Clock Out'"
                    }}
                    mr={3}
                    onClick={ClockOut}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                >
                    <GoClockFill color='white' size={'20px'} />
                    <Text flex={1} textAlign='center' width={"90px"} mr={1} ml={1}>
                        {buttonText}
                    </Text>
                </Button>

            ) : (
                <Button
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textColor="#1c2147"
                    backgroundColor="#f2f2f2"
                    borderRadius={'5px'}
                    mr={3}
                    onClick={openClockModal}
                >
                    <GoClockFill color='#fabb1c' size={'20px'} />
                    <Text flex={1} textAlign='center' mr={1} ml={1}>
                        Clock in
                    </Text>

                </Button>
            )}

            {shift && !breakS ? (
                <>
                    {dataDailyBreak.length > 0 ? (
                        dataDailyBreak.map(dataDailyBreak => (
                            <Flex key={dataDailyBreak.id} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Button
                                    textColor="white"
                                    bg={"#fcb06a"}
                                    _hover={{
                                        background: "#8aad01",
                                        color: "white"
                                    }}
                                    borderRadius={'5px'}
                                    mr={3}
                                    onClick={() => startBreak(dataDailyBreak)}
                                >
                                    <Flex mb={1}>
                                        <IoFastFood size={'20px'} />
                                    </Flex>
                                    <Text flex={1} textAlign='center' mr={1} ml={1}>
                                        Lunch
                                    </Text>

                                </Button>
                                <Button
                                    textColor="white"
                                    backgroundColor="#7a86e6"
                                    borderRadius={'5px'}
                                    _hover={{
                                        background: "#8aad01",
                                        color: "white"
                                    }}
                                    mr={3}
                                    onClick={() => createBreak(dataShift)}
                                >
                                    <Flex mb={1}>
                                        <IoFastFood size={'20px'} />
                                    </Flex>
                                    <Text flex={1} textAlign='center' mr={1} ml={1}>
                                        Break
                                    </Text>

                                </Button>
                            </Flex>
                        ))
                    ) : (
                        <Flex display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button
                                textColor="white"
                                backgroundColor="#7a86e6"
                                borderRadius={'5px'}
                                _hover={{
                                    background: "#8aad01",
                                    color: "white"
                                }}
                                mr={3}
                                onClick={() => createBreak(dataShift)}
                            >
                                <Flex mb={1}>
                                    <IoFastFood size={'20px'} />
                                </Flex>
                                <Text flex={1} textAlign='center' mr={1} ml={1}>
                                    Break
                                </Text>

                            </Button>
                        </Flex>
                    )}
                </>
            ) : null}


            {shift && breakS ? (
                <>
                    <Flex direction='column' >
                        <Button boxShadow={"0px 0px 8px 4px"}
                            textColor="white"
                            backgroundColor="#b9ce67"
                            borderRadius={'5px'}
                            mr={3}
                            onClick={endBreak}
                            _hover={{
                                background: "#fc7466",
                                color: "white"
                            }}
                            onMouseEnter={handleHoverB}
                            onMouseLeave={handleHoverB}
                        >
                            <Flex mb={1}>
                                <IoFastFood color='white' size={'20px'} />
                            </Flex>
                            <Text flex={1} textAlign='center' width={"90px"} mr={1} ml={1}>
                                {breakText}
                            </Text>

                        </Button>
                    </Flex>

                </>
            ) : null}
        </>
    );
}
