
import React from "react";
import { Link } from 'react-router-dom';
import {
    Flex, Menu, MenuButton, MenuList, Text, Button, Box, MenuItem, Badge, Alert, AlertIcon
} from '@chakra-ui/react';

//Icons & Buttons
import { FaRegBell } from "react-icons/fa6";

export default function NotificationsMenu(props) {

    const { numberNotify, userAuth, notify, handleItemClick, getColorIncident, fetchData, notifyUser } = props;

    return (
        <>
            <Menu>
                <MenuButton
                    width={"50px"}
                    height={"40px"}
                    mr={4} borderRadius={"10%"}
                    backgroundColor="#f2f2f2"
                    as={Button}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                    position={"relative"}
                    _hover={{ background: "#cbd5e0" }}
                >

                    {numberNotify !== 0 && (
                        <Box position={"absolute"} bg={"#f4bb1c"} top={"-25%"} borderRadius={"50%"} right={"-25%"} width={"25px"} height={'25px'}>
                            <Text textAlign={"center"} fontWeight={"bold"} color='#1c2147' mt={'2.5px'}>{numberNotify}</Text>
                        </Box>
                    )}
                    <Box ml={-1} width={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                        <FaRegBell color='#1c2147' size={"26px"} />
                    </Box>
                </MenuButton>
                <MenuList width={"670px"} height={"770px"} overflowY={"auto"} p={'5'} boxShadow={"1px 2.5px 10px 7px #cccccc"} borderRadius={"30px"} >
                    <Text fontSize={'36px'} fontWeight={'bold'} color={'#1c2147'} ml={'20px'} mt={'20px'}  >
                        Notifications
                    </Text>
                    {notify === null || notify.length === 0 ? (
                        <Flex
                            display={'flex'} bg={"#f2f2f2"}
                            justifyContent={'center'}
                            direction={'column'}
                            width={'600px'}
                            textAlign={'center'}
                            alignItems={'center'}
                            mt={'40px'}
                            p={'5'}
                        >
                            <Flex display={'flex'} justifyContent={'center'} textAlign={'center'} mt={'10px'} mb={'20px'} >
                                <FaRegBell color='#b3b3b3' size={"80px"} />
                            </Flex>
                            <Text
                                color={'#808080'}
                                fontWeight={'bold'}
                                fontSize={'30px'}
                            >
                                No Notifications Yet
                            </Text>
                            <Text
                                color={'#808080'}
                                fontWeight={'bold'}
                            >
                                When you get notifications, they'll show up here
                            </Text>
                            <Button
                                width={'200px'}
                                bg={'#424ea8'}
                                color={'white'}
                                borderRadius={'5px'}
                                mt={'10px'}
                                onClick={fetchData}
                                _hover={{
                                    background: "#808080",
                                    color: "white"
                                }}
                            >
                                Refresh
                            </Button>
                        </Flex>
                    ) : (
                        notify.map((item, index) => (
                            <Link to={`/admin/ticket/chat`} key={index} onClick={() => handleItemClick(item.incident_id)}>
                                <MenuItem>
                                    <Flex p={'5'} width={'600px'} direction={'row'} borderRadius={'10px'} bg={'#f2f2f2'} mt={'15px'} _hover={{ background: '#e6e6e6' }}
                                    >
                                        <Flex direction={'column'}>
                                            <Text fontWeight={'bold'} textAlign={"right"} color={'#424ea8'} width={"550px"}  >
                                                {item.formatted_date}
                                            </Text>
                                            <Flex display={'flex'} textAlign={'center'} height={'25px'} width={"max-content"}   >
                                                <Badge bg={getColorIncident(item.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} >
                                                    {(() => {
                                                        switch (item.chat_room_id) {
                                                            case 1:
                                                                return "IT";
                                                            case 2:
                                                                return "HR";
                                                            case 3:
                                                                return "Hiring";
                                                            case 4:
                                                                return "OnCall";
                                                            case 5:
                                                                return "Schedules";
                                                            case 6:
                                                                return "Finances";
                                                            case 7:
                                                                return "Calls";
                                                            case 8:
                                                                return "Supervisor";
                                                            default:
                                                                return "IT";
                                                        }
                                                    })()}
                                                </Badge>
                                                <Badge color={'#1c2147'} fontWeight='500' textAlign={'center'} bg={'white'} borderRadius={'5px'} ml={2}  >
                                                    {item.users.user_client.client.name}
                                                </Badge>
                                            </Flex>
                                            <Text mt={2} fontWeight={'bold'} color={'#1c2147'} fontSize={"18px"}  >
                                                {item.users.name} {item.users.last_name}
                                            </Text>
                                            <Text color={'#808080'} fontSize={'18px'} textOverflow={'ellipsis'} width={'max-content'} maxWidth={'550px'} overflow={'hidden'} whiteSpace={"nowrap"}  >
                                                {item.message}
                                            </Text>

                                        </Flex>

                                    </Flex>
                                </MenuItem>
                            </Link>
                        ))
                    )}
                    {notifyUser === null || notifyUser.length === 0 ? (
                        null
                    ) : (
                        notifyUser.map((item, index) => (
                            <Link to={`/admin/ticketU/chat`} key={index}>
                                <MenuItem>
                                    <Flex p={'5'} width={'600px'} direction={'column'} borderRadius={'10px'} bg={'#5868e0'} mt={'15px'} _hover={{ background: '#7a86e6' }}  >
                                        <Text fontWeight={'bold'} color={'white'} textAlign={"right"}>
                                            {item.formatted_date}
                                        </Text>
                                        <Flex direction={'column'} width={"540px"}>

                                            <Flex display={'flex'} justifyContent={'left'} flexDirection={"row"} textAlign={'left'} height={'25px'}  >
                                                <Badge bg={getColorIncident(item.chat_room_id)} fontWeight='500' textAlign={'center'} color={'white'} borderRadius={'5px'} >
                                                    {(() => {
                                                        switch (item.chat_room_id) {
                                                            case 1:
                                                                return "IT";
                                                            case 2:
                                                                return "HR";
                                                            case 3:
                                                                return "Hiring";
                                                            case 4:
                                                                return "OnCall";
                                                            case 5:
                                                                return "Schedules";
                                                            case 6:
                                                                return "Finances";
                                                            case 7:
                                                                return "Calls";
                                                            case 8:
                                                                return "Supervisor";
                                                            default:
                                                                return "IT";
                                                        }
                                                    })()}
                                                </Badge>
                                                <Badge color={'#1c2147'} fontWeight='500' textAlign={'center'} bg={'white'} borderRadius={'5px'} ml={2}  >
                                                    {item.users.user_client.client.name}
                                                </Badge>
                                            </Flex>
                                            <Text mt={2} fontWeight={'bold'} fontSize={'18px'} color={'#1d2147'}    >
                                                {item.users.name} {item.users.last_name}
                                            </Text>
                                            <Text color={'white'} fontSize={'18px'} textOverflow={'ellipsis'} width={'480px'} maxWidth={'550px'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                                {item.message}
                                            </Text>


                                        </Flex>

                                    </Flex>
                                </MenuItem>
                            </Link>
                        ))
                    )}

                    {[3].includes(userAuth.path_confirm) ? (
                        <Alert
                            status='error'
                            mt={'5%'}
                            width={'100%'}
                            fontWeight={'bold'}
                            color={'#1c2147'}
                            onClick={() => window.location.href = '/admin/profile'}
                            cursor='pointer'
                        >
                            <AlertIcon />
                            Your profile picture has been rejected. Remember to follow the required requeriments.
                        </Alert>
                    ) : null}
                </MenuList>

            </Menu>
        </>
    );
}
