import axios from 'axios';
import React, { useEffect, useState } from "react";
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    Text, Button, Input, Flex, useToast, Textarea, FormErrorMessage, FormControl
}
    from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons'
import { IoMdSend } from "react-icons/io";
import Select2 from 'react-select'

function QAClarification({ isOpen, onClose, incidentID }) {

    const [incident, setIncident] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const toast = useToast();
    const [clientS, setClientS] = useState([]);
    const [clientSearch, setClientSearch] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [resetKey, setResetKey] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => {
            const updatedFormData = {
                ...prevData,
                [name]: value,
            };
            return updatedFormData;
        });
    };


    const getDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
        const year = today.getFullYear();

        return `${year}-${month}-${day}`;
    };


    const [formData, setFormData] = useState({
        date_submitted: getDate(),
        client: '',
        call_date: '',
        account_number: '',
        description: '',
        ticked_id: incidentID,
    });


    const handleSubmit = async () => {


        const formDataSend = new FormData();
        formDataSend.append('date_submitted', formData.date_submitted);
        formDataSend.append('client', clientSearch);
        formDataSend.append('call_date', formData.call_date);
        formDataSend.append('account_number', formData.account_number);
        formDataSend.append('description', formData.description);
        formDataSend.append('ticked_id', formData.ticked_id);


        try {

            const response = await axios.post('/qaClarification', formDataSend);
            if (response.data.msg) {
                setErrorMsg(response.data.msg);
            } else {
                toast({
                    title: 'QA Clarification Send Success',
                    status: 'success',
                    duration: 3000,
                    position: 'top',
                    isClosable: true,
                });
                onClose();
                setFormErrors({});
            }
        } catch (error) {
            toast({
                title: "Error sending a QA clarification",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setFormErrors(error.response.data.errors);
            console.error('Error creating schedule:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`/showTicket/${incidentID}`);
            setIncident(response.data);
        } catch (error) {
            console.error('Error fetching incident:', error);
        }
    };


    useEffect(() => {
        if (incidentID) {
            fetchData();
        }
    }, [incidentID]);



    const cancelTicket = () => {
        axios.delete(`/incidents/delete/${incident.id}`)
            .then(response => {
                onClose();
            })
            .catch(error => {
                console.error(error);
            });
    };

    const fetchDataClient = async () => {
        try {
            const response = await axios.get(`/settings/options`);
            const responseData = response.data;
            setClientS(responseData.client);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            borderColor: '#808080',
            marginLeft: '5px',
            fontSize: '16px',
            height: 'max-content',
            width: '100%',
            '&:hover': {
                borderColor: '#808080',
            },
            '&:focus-visible': {
                outline: 'none',
                boxShadow: 'none'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: 'white',
            width: '265px'
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#808080' : state.isFocused ? 'white' : '#808080',
            backgroundColor: state.isSelected ? '#f2f2f2' : state.isFocused ? '#bcc3f3' : 'white',

        }),
    };

    const optionClients = clientS.map(clientS => ({
        value: clientS.id,
        label: clientS.name,
    }));


    useEffect(() => {
        fetchDataClient();
    }, []);

    const handleSelectChangeF = (componentName, selectedOption) => {
        switch (componentName) {
            case 'client':
                setClientSearch(selectedOption.value);
                break;
            default:
                break;
        }
    };


    return (
        <Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={'default'} isCentered closeOnOverlayClick={false}
                closeOnEsc={false}>
                <ModalOverlay />
                <ModalContent borderRadius={'20px'} w={'25%'}>
                    <ModalHeader textAlign="center" color={'#424ea8'} mt={'5%'} fontSize={'40px'}>
                        QA - Clarification
                        <Text fontSize={'18px'} color={'#808080'}>
                            Do you have questions/concerns on a score you received? Let us know.
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton onClick={cancelTicket} />
                    <ModalBody>

                        <Flex direction={'column'}>
                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                Team:
                            </Text>
                            <FormControl isInvalid={!!formErrors.client} mt={'3%'}>
                                <Select2
                                    options={optionClients}
                                    value={optionClients.find(option => option.value === clientSearch)}
                                    onChange={(selectedOption) => {
                                        handleSelectChangeF('client', selectedOption);
                                    }}
                                    styles={customStyles}
                                    placeholder="Select Team"
                                    key={`clientSelect-${resetKey}`}
                                />
                                {formErrors.client && (
                                    <FormErrorMessage mb={"2%"}>{formErrors.client}</FormErrorMessage>
                                )}
                            </FormControl>

                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                Call Date :
                            </Text>
                            <Text mt={'2%'} color={'#808080'} fontWeight={'bold'}>
                                When was the call taken?
                            </Text>
                            <Input
                                type='date'
                                bg={'#f2f2f2'}
                                color={'#808080'}
                                textAlign={'center'}
                                value={formData.call_date}
                                onChange={handleChange}
                                name='call_date'
                                mt={'5%'}
                            />



                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                Account Number:
                            </Text>
                            <Input
                                type='text'
                                bg={'#f2f2f2'}
                                color={'#808080'}
                                textAlign={'center'}
                                value={formData.account_number}
                                onChange={handleChange}
                                name='account_number'
                                mt={'5%'}
                            />

                            <Text mt={'5%'} color={'#1c2147'} fontWeight={'bold'}>
                                Description:
                            </Text>
                            <Text mt={'2%'} color={'#808080'} fontWeight={'bold'}>
                                Please give us more details about your inquiry.
                            </Text>
                            <Textarea
                                bg={'#f2f2f2'}
                                color={'#808080'}
                                value={formData.description}
                                onChange={handleChange}
                                name='description'
                                mt={'5%'}
                            />


                            <Flex display={'flex'} justifyContent={'center'} mt={'10%'} mb={'5%'}>
                                <Button
                                    borderRadius={"5px"}
                                    bg={"#b9ce67"}
                                    color={"white"}
                                    _hover={{ background: "#8aad01", color: 'white' }}
                                    onClick={handleSubmit}
                                    mr={'5%'}
                                >
                                    <Text mr={'5%'}>
                                        Send
                                    </Text>
                                    <IoMdSend />
                                </Button>

                                <Button bg={'#424ea8'} _hover={{ background: "#fc7466", color: 'white' }} color={'white'} borderRadius='5px' fontWeight='bold' onClick={cancelTicket}>
                                    Cancel <CloseIcon ml={2} />
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal >
        </Flex>
    );
}


export default QAClarification;
