import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import UseQAIncidences from "./components/itemsIncidence/functions";

export default function IncidenceOverview() { 
  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='100px'>
        <UseQAIncidences/>
      </Card>
    </Flex>
  );
} 